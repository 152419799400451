import { connect } from 'react-redux';
import { getUserTransaction } from '../actions/reduxActions';
import TransactionParent from '../../components/layout/profile/TransactionParent';

const mapStateToProps = (state, ownProps) => {
  return {
    userTransactions: state.userInfos.userTransactions,
    gettingTransactions: state.userInfos.gettingTransactions,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getUserTransaction: () => {
      dispatch(getUserTransaction());
    },
  };
};

const TransactionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionParent);

export default TransactionContainer;
