import {
  makeStyles,
  Theme,
  createStyles,
  AppBar,
  Grid,
  Badge,
  IconButton,
  Fab,
} from '@material-ui/core';
import MaterialButton from '../../helpers/MaterialButton';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useAppSelector } from '../../../redux/setup/hooks';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getOrderPriceAction,
  userNavigationHistorySaver,
} from '../../../redux/actions/reduxActions';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import RedirectPages from '../../helpers/RedirectPages';

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      top: 'auto',
      bottom: 0,
      padding: 10,
    },
  })
);

export default function GoToPayment() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);
  const { cartFromServer } = products;
  const history = useHistory();
  const orderStates = useSelector((state) => state.orderStates);
  const { requestingPrice } = orderStates;
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectValue, setRedirectValue] = useState('');
  const location = useLocation();

  useEffect(() => {}, [requestingPrice, cartFromServer.length]);

  const goToPayment = () => {
    dispatch(userNavigationHistorySaver(location.pathname));
    // setRedirectValue('/confirm-basket');
    // setShouldRedirect(true);
    // setTimeout(() => {
    //   setRedirectValue('');
    //   setShouldRedirect(false);
    // }, 100);
    history.push(`/confirm-basket`);
  };

  return (
    <>
      {window.location.pathname.match('/cart') ? (
        <div style={{ position: 'fixed', bottom: 70, right: 5 }}>
          <Fab variant='extended' color='primary' onClick={goToPayment}>
            {`تکمیل خرید (${cartFromServer.length})`}
          </Fab>
        </div>
      ) : (
        <div style={{ position: 'fixed', bottom: 16, left: 16 }}>
          <Fab variant='extended' color='secondary' onClick={goToPayment}>
            {`تکمیل خرید (${cartFromServer.length})`}
          </Fab>
        </div>
      )}
      {shouldRedirect === true && <RedirectPages redirect={redirectValue} />}
    </>
  );
}
