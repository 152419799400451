import { useState, useEffect } from 'react'
import { Button, Grid, makeStyles, LinearProgress, Typography, IconButton } from "@material-ui/core"
import { useDispatch, useSelector } from 'react-redux'
import { compressImageAction, uploadImageAction, cancelImageUploadAction } from "../../../redux/actions/reduxActions"
import midStringRegex from '../../../utils/midStringRegex';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  uploadContainer: {
    margin: theme.spacing(2, 2, 0, 2)
  },
  linearPrg: {
    width: "100%"
  },
  cancelBtn: {
    position: "absolute",
    backgroundColor: "white",
    margin: "10px",
    borderRadius: "50%"
  }
}));

export default function FileUploadComponent(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const orderStates = useSelector((state) => state.orderStates);
  const { imageCompressionLoading } = orderStates;
  const [selectedFile, setSelectedFile] = useState('');
  const [isFilePicked, setIsFilePicked] = useState(false);
  const hasUploadedImage = props.hasUploadedImage

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
    dispatch(compressImageAction(event))
  };

  const handleSubmission = () => {
    dispatch(uploadImageAction())
  };

  const removeHandler = () => {
    setSelectedFile('');
    setIsFilePicked(false);
    dispatch(cancelImageUploadAction());
  }

  return (
    <div className={classes.uploadContainer}>
      {isFilePicked ? (
        <div>
          {imageCompressionLoading === true ? (
            <div className={classes.linearPrg}>
              <LinearProgress color="primary" />
            </div>
          ) : (
            <div>
              <div
                className={classes.cancelBtn}
              >
                <IconButton
                  disabled={imageCompressionLoading}
                  size="small"
                  color="primary"
                  onClick={removeHandler}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <img
                width="100%"
                height="250"
                style={{ borderRadius: 5 }}
                src={URL.createObjectURL(selectedFile)}
                alt="preview"
              />
            </div>
          )}

          <Typography variant='subtitle2' align="center" color="textPrimary" gutterBottom>نام فایل: {midStringRegex(selectedFile.name)}</Typography>
          {hasUploadedImage === false ||
            !sessionStorage.getItem('UPLOADED_IMAGE_ID') ? (
            <Grid container justifyContent="center" alignItems="center">
              <Button
                color="primary"
                variant="contained"
                fullWidth
                disabled={imageCompressionLoading}
                onClick={handleSubmission}>آپلود</Button>
            </Grid>
          ) : (
            <Typography variant="subtitle2" color="textSecondary" align="center">با تشکر از شما</Typography>
          )}

        </div>
      ) : (
        <Grid container justifyContent="center" alignItems="center">
          <label htmlFor="btn-upload">
            <input
              id="btn-upload"
              name="btn-upload"
              style={{ display: 'none' }}
              type="file"
              multiple
              onChange={changeHandler} />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              component="span" >
              انتخاب عکس
            </Button>
          </label>
        </Grid>
      )}



    </div>
  )
}
