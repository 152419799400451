import AppBarContainer from '../navigation/AppBarContainer';
import OrdersCart from './OrdersCart';
import FinalOrderStep from './FinalOrderStep';
import { Paper } from '@material-ui/core';
import { useEffect, useState } from 'react';
import LoadingSkeleton from '../../helpers/LoadingSkeleton';
import OrderAddAddressMsg from './OrderAddAddressMsg';
import RedirectPages from '../../helpers/RedirectPages';
import withAuth from '../../../utils/withAuth';
import { useLocation } from 'react-router-dom';

const CompleteOrderParent = (props) => {
  const cartItems = props.cartItems;
  const removeFromCartAction = props.removeFromCartAction;
  const increaseUnitsAction = props.increaseUnitsAction;
  const decreaseUnitsAction = props.decreaseUnitsAction;
  const drawerHandler = props.drawerHandler;
  const priceInvoice = props.priceInvoice;
  const addressId = props.addressId;
  const showAlertDialogAction = props.showAlertDialogAction;
  const alertDialogContentAction = props.alertDialogContentAction;
  const getOrderPriceAction = props.getOrderPriceAction;
  const requestingPrice = props.requestingPrice;
  const skeletonLoadingStatusAction = props.skeletonLoadingStatusAction;
  const isCheckingCoupon = props.isCheckingCoupon;
  const isCartReceived = props.isCartReceived;
  const cartFromServer = props.cartFromServer;
  const getCartAction = props.getCartAction;
  const addCartAction = props.addCartAction;
  const removeCartAction = props.removeCartAction;
  const deleteCartAction = props.deleteCartAction;
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectValue, setRedirectValue] = useState('');
  const location = useLocation();
  const userNavigationHistorySaver = props.userNavigationHistorySaver;
  const hasUploadedImage = props.hasUploadedImage;
  const shouldUploadImage = props.shouldUploadImage;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getCartAction();
    skeletonLoadingStatusAction('listSkeleton');
    // getOrderPriceAction();
  }, []);

  useEffect(() => {
    if (cartFromServer.length === 0) {
      setRedirectValue('/');
      setShouldRedirect(true);
      setTimeout(() => {
        setRedirectValue('');
        setShouldRedirect(false);
      }, 100);
    }
  }, [cartFromServer]);

  return (
    <div>
      <>
        {shouldRedirect === true && <RedirectPages redirect={redirectValue} />}
        {requestingPrice === false && isCartReceived === false ? (
          <>
            <AppBarContainer />
            <>
              <Paper style={{ paddingBottom: 70 }} elevation={0}>
                <OrdersCart
                  addCartAction={addCartAction}
                  removeCartAction={removeCartAction}
                  deleteCartAction={deleteCartAction}
                  cartFromServer={cartFromServer}
                  removeFromCartAction={removeFromCartAction}
                  increaseUnitsAction={increaseUnitsAction}
                  decreaseUnitsAction={decreaseUnitsAction}
                  priceInvoice={priceInvoice}
                  drawerHandler={drawerHandler}
                  hasUploadedImage={hasUploadedImage}
                  shouldUploadImage={shouldUploadImage}
                />
              </Paper>
              <FinalOrderStep
                addressId={addressId}
                showAlertDialogAction={showAlertDialogAction}
                alertDialogContentAction={alertDialogContentAction}
                priceInvoice={priceInvoice}
                drawerHandler={drawerHandler}
                userNavigationHistorySaver={userNavigationHistorySaver}
                hasUploadedImage={hasUploadedImage}
                shouldUploadImage={shouldUploadImage}
              />
            </>
          </>
        ) : (
          <LoadingSkeleton />
        )}
      </>
    </div>
  );
};

export default CompleteOrderParent;
