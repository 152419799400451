import {
  makeStyles,
  Typography,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Theme,
  Grow,
  Fade,
  Badge,
  IconButton,
  ListItemSecondaryAction
} from "@material-ui/core";
import TypoGraphy from "../../../helpers/TypoGraphy";
import MaterialButton from "../../../helpers/MaterialButton";
import CommentOutlinedIcon from "@material-ui/icons/CommentOutlined";
import emptyComment from "../../../../static/image/emptyComment.svg";
import RateDefaultStars from "../../../helpers/RateDefaultStars";
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { useDispatch } from "react-redux";
import { userTypingAction } from "../../../../redux/actions/reduxActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(0.8),
  },
  commentsFrame: {
    [theme.breakpoints.up("lg")]: {
      width: "80%",
      marginBottom: 80,
    },
  },
  emptyCartImage: {
    width: 100,
    height: 100,
    margin: theme.spacing(0, 1, 1, 1),
  },
  divider: {
    margin: theme.spacing(1),
    height: theme.spacing(1),
  },
}));

export default function CommentsList(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const comments = props.comments;
  const actionDialogHandler = props.actionDialogHandler;
  const role = props.role;
  const alertDialogContentAction = props.alertDialogContentAction;
  const showAlertDialogAction = props.showAlertDialogAction;

  const openCommentDialog = () => {
    if (role === "guest") {
      alertDialogContentAction(
        "دسترسی ندارید",
        "برای دسترسی به این بخش باید ثبت نام کنید",
        "برو به صفحه ثبت نام"
      );
      showAlertDialogAction(true);
    } else {
      actionDialogHandler(true, "addComment");
    }
  };

  const openReportCommentDialog = (id) => {
    if (role === "guest") {
      alertDialogContentAction(
        "دسترسی ندارید",
        "برای دسترسی به این بخش باید ثبت نام کنید",
        "برو به صفحه ثبت نام"
      );
      showAlertDialogAction(true);
    } else {
      dispatch(userTypingAction(id,"setReportCommentId"))
      actionDialogHandler(true, "reportComment");
    }
  }

  return (
    <Fade in timeout={1000}>
      <div className={classes.commentsFrame}>
        {comments?.length === 0 ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                alt="emptyComment"
                src={emptyComment}
                className={classes.emptyCartImage}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: 20,
              }}
            >
              <MaterialButton
                text={"اولین دیدگاه را ثبت کنید"}
                variant={"contained"}
                color={"inherit"}
                icon={<CommentOutlinedIcon />}
                btnType={"button"}
                size={"small"}
                onClick={openCommentDialog}
              />
            </div>
          </>
        ) : (
          <List className={classes.root}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                padding: "1px 15px 5px 15px",
              }}
            >
              <MaterialButton
                text={"ثبت دیدگاه"}
                variant={"contained"}
                color={"inherit"}
                btnType={"button"}
                size={"small"}
                onClick={openCommentDialog}
              />
            </Grid>
            {comments?.map((comment, index) => (
              <div key={index}>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={
                        <>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              align="right"
                              variant="subtitle2"
                              color="textSecondary"
                              id="commentNumbers"
                            >
                              {comment?.number}

                              <Typography
                                align="right"
                                variant="subtitle2"
                                color="textSecondary"
                              >
                                {comment?.customer?.name}
                              </Typography>
                            </Typography>
                          <RateDefaultStars stars={comment.star} />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column"
                          }}
                          >
                            <IconButton
                              color="default"
                              edge="end"
                              size="small"
                              onClick={() => openReportCommentDialog(comment.id)}
                            >
                              <ReportProblemIcon
                                fontSize="small"
                              />
                            </IconButton>
                            <Typography
                              align="left"
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              {comment?.created_at?.slice(0, 10)}
                            </Typography>
                    
                          </div>
                            
                          </Grid>
                        </>
                      }
                      secondary={
                        <Typography
                          style={{ marginTop: 5, fontWeight: "bold" }}
                          variant="body2"
                          align="right"
                          color="textPrimary"
                        >
                          {comment?.comment}
                        </Typography>
                      }
                  />
           
                  </ListItem>
                <Divider />
              </div>
            ))}
          </List>
        )}
      </div>
    </Fade>
  );
}
