import { useEffect } from 'react';
import InputTextField from '../../helpers/InputTextField';
import {
  Grid,
  IconButton,
  Card,
  CardContent,
  CardActionArea,
  List,
  ListItem,
  ListItemText,
  ImageList,
  CircularProgress
} from '@material-ui/core';
import RemoveCircleTwoToneIcon from '@material-ui/icons/RemoveCircleTwoTone';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import priceRegex from '../../../utils/priceRegex';
import TypoGraphy from '../../helpers/TypoGraphy';
import MaterialButton from '../../helpers/MaterialButton';
import { useAppSelector } from '../../../redux/setup/hooks';
import * as storageHandler from '../../../utils/storageHandler';
import AppBarContainer from '../navigation/AppBarContainer';
import GuestLoginPage from '../../helpers/GuestLoginPage';

export default function ChargeAccount(props) {
  const userTypingAction = props.userTypingAction;
  const userTypings = props.userTypings;
  const priceInvoice = props.priceInvoice;
  const userInfos = props.userInfos;
  const drawerHandler = props.drawerHandler;
  const getUserDetailsAction = props.getUserDetailsAction;
  const requestPaymentAction = props.requestPaymentAction;
  const payBtnLoading = props.paymentStatus.payBtnLoading
  const paymentUrl = props.paymentStatus.paymentUrl
  const urlReceived = props.paymentStatus.urlReceived
  const pathname = window.location.pathname
  const loading = props.loading
  let role = 'guest';
  if (props.auth.authData.user) {
    role = props.auth.authData.user.role;
  }

  useEffect(() => {
    if (pathname.match('/charge-account')) {
      if (role !== 'guest') {
        getUserDetailsAction()
      }
    }
  }, [paymentUrl, payBtnLoading, urlReceived]);

  const handleInputChange = (value, inputType) => {
    userTypingAction(value, inputType);
  };

  const handleCloseDrawer = () => {
    drawerHandler(false, '');
    getUserDetailsAction();
  };

  const handlePaymentRequest = () => {
    if (pathname.match('/charge-account')) {
      requestPaymentAction(true);
    } else {
      requestPaymentAction();

    }
  };



  return (
    <div>
      {pathname.match('/charge-account') ? (

        <>
          {role !== 'guest' ? (
            <>
              {loading === false ? (
                <>
                  <AppBarContainer />

                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      width: '100%',
                    }}
                  >
                    <Grid container justifyContent="flex-start" alignItems="baseline">
                      <ListItemText
                        primary={<TypoGraphy
                          text={'اعتبار شما'}
                          align={"center"}
                          variant={"h6"}
                        />}
                        secondary={<TypoGraphy
                          text={`${userInfos.userProfile.credit && (userInfos.userProfile.credit).toLocaleString("fa-IR")} تومان`}
                          align={"center"}
                          variant={"subtitle1"}
                          color="secondary"
                        />}

                      />

                    </Grid>
                    <br />
                    <div
                      style={{
                        overflow: 'auto',
                        whiteSpace: 'nowrap',
                        paddingBottom: 10
                      }}
                    >

                      {chargeCards.map((charge, index) => (
                        <Card
                          key={index}
                          style={{
                            margin: 5,
                            boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.2)',
                            height: "max-content",
                            width: "max-content",
                            display: "inline-block"
                          }}
                        >
                          <CardActionArea
                            onClick={() => {
                              userTypingAction(Math.abs(userTypings.priceToPay + charge.value), 'setPriceToPay');
                            }}
                          >
                            <CardContent>
                              <TypoGraphy
                                text={charge.name}
                                variant={'body2'}
                                align={'center'}
                              />
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      ))}
                    </div>
                    <br />
                    <Grid container justifyContent='center' alignItems='baseline'>
                      <IconButton
                        size='small'
                        color='primary'
                        onClick={() => {
                          userTypingAction(
                            Math.abs(userTypings.priceToPay + 10000),
                            'setPriceToPay'
                          );
                        }}
                      >
                        <AddCircleIcon fontSize='small' />
                      </IconButton>
                      <InputTextField
                        value={userTypings.priceToPay}
                        label={'پرداختی (تومان)'}
                        style={{ margin: 10 }}
                        inputType={'setPriceToPay'}
                        onChange={handleInputChange}
                        type={'number'}
                      />
                      <IconButton
                        size='small'
                        color='inherit'
                        onClick={() => {
                          userTypingAction(
                            Math.abs(userTypings.priceToPay - 10000),
                            'setPriceToPay'
                          );
                        }}
                      >
                        <RemoveCircleTwoToneIcon fontSize='small' />
                      </IconButton>
                    </Grid>
                    <br />
                    <br />

                    <Grid
                      container
                      justifyContent='center'
                      alignItems='baseline'
                      style={{ margin: '10px 0 10px 0' }}
                    >

                      <MaterialButton
                        btnType={'button'}
                        text={'پرداخت'}
                        variant={'contained'}
                        color={'primary'}
                        fullWidth={true}
                        style={{ margin: "0px 5px 0px 5px" }}
                        onClick={handlePaymentRequest}
                        disabled={payBtnLoading}
                      />
                    </Grid>
                  </div>




                </>
              ) : (
                <Grid
                  container
                  justifyContent='center'
                  alignItems='center'
                  style={{ marginTop: 200 }}
                >
                  <CircularProgress color='primary' size={80} />
                </Grid>
              )}
            </>) : (

            <>
              <AppBarContainer />
              <div className='guestLoginPage'>
                <GuestLoginPage />
              </div>
            </>

          )}

        </>
      ) : (
        <>
          <Grid container justifyContent="space-around" alignItems="baseline">
            <ListItemText
              primary={<TypoGraphy
                text={'اعتبار شما'}
                align={"center"}
                variant={"subtitle2"}
              />}
              secondary={<TypoGraphy
                text={`${(priceInvoice.customer_credit).toLocaleString("fa-IR")} تومان`}
                align={"center"}
                variant={"subtitle2"}
                color="secondary"
              />}

            />
            <ListItemText
              primary={<TypoGraphy
                text={'هزینه سفارش'}
                align={"center"}
                variant={"subtitle2"}
              />}
              secondary={<TypoGraphy
                text={`${(priceInvoice.invoice.price).toLocaleString("fa-IR")} تومان`}
                align={"center"}
                variant={"subtitle2"}
                color="primary"
              />}

            />
          </Grid>
          <div
            style={{
              overflow: 'auto',
              whiteSpace: 'nowrap',
              paddingBottom: 10
            }}
          >

            {chargeCards.map((charge, index) => (
              <Card
                key={index}
                style={{
                  margin: 5,
                  boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.2)',
                  height: "max-content",
                  width: "max-content",
                  display: "inline-block"
                }}
              >
                <CardActionArea
                  onClick={() => {
                    userTypingAction(Math.abs(userTypings.priceToPay + charge.value), 'setPriceToPay');
                  }}
                >
                  <CardContent>
                    <TypoGraphy
                      text={charge.name}
                      variant={'body2'}
                      align={'center'}
                    />
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
          </div>
          <Grid container justifyContent='center' alignItems='baseline'>
            <IconButton
              size='small'
              color='primary'
              onClick={() => {
                userTypingAction(
                  Math.abs(userTypings.priceToPay + 10000),
                  'setPriceToPay'
                );
              }}
            >
              <AddCircleIcon fontSize='small' />
            </IconButton>
            <InputTextField
              value={userTypings.priceToPay}
              label={'پرداختی (تومان)'}
              style={{ margin: 10 }}
              inputType={'setPriceToPay'}
              onChange={handleInputChange}
              type={'number'}
            />
            <IconButton
              size='small'
              color='inherit'
              onClick={() => {
                userTypingAction(
                  Math.abs(userTypings.priceToPay - 10000),
                  'setPriceToPay'
                );
              }}
            >
              <RemoveCircleTwoToneIcon fontSize='small' />
            </IconButton>
          </Grid>
          <Grid
            container
            justifyContent='space-around'
            alignItems='baseline'
            style={{ margin: '10px 0 10px 0' }}
          >
            <MaterialButton
              btnType={'button'}
              text={'انصراف'}
              variant={'outlined'}
              color={'secondary'}
              onClick={handleCloseDrawer}
            />
            <MaterialButton
              btnType={'button'}
              text={'پرداخت'}
              variant={'contained'}
              color={'primary'}
              onClick={handlePaymentRequest}
              disabled={payBtnLoading}
            />
          </Grid>
        </>
      )}

    </div>
  );
}

const chargeCards = [
  { name: priceRegex(50000, 'withCurrency'), value: 50000 },
  { name: priceRegex(100000, 'withCurrency'), value: 100000 },
  { name: priceRegex(150000, 'withCurrency'), value: 150000 },
  { name: priceRegex(200000, 'withCurrency'), value: 200000 },
];
