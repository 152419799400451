import React from 'react';
// import Lottie from 'react-lottie';
import notFoundLottie from '../../static/lottie/pageNotFound.json';
import { Typography, Button, Grid } from '@material-ui/core';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: notFoundLottie,
};

export default function Page404({ location }) {
  return (
    <div className='page-not-found-container'>
      {/* <Lottie
        options={defaultOptions}
        height={'60%'}
        width={'100%'}
        isStopped={false}
        isPaused={false}
      /> */}
      <Typography align='center' variant='h6' color='textPrimary'>
        ای وای! 😨 صفحه مورد نظر شما یافت نشد.
      </Typography>
      <br />
      <br />
      <Grid container justifyContent='center' alignItems='center'>
        <Button
          onClick={() => {
            window.location.replace('/');
          }}
          variant='contained'
          color='primary'
          size='small'
        >
          برو به صفحه اصلی
        </Button>
      </Grid>
    </div>
  );
}
