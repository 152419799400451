import {
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Divider,
  Card,
  CardMedia,
  CardActionArea,
  IconButton,
  Paper,
  Tabs,
  Tab,
  CardHeader,
  CardActions,
  CircularProgress,
} from '@material-ui/core';
import TypoGraphy from '../../helpers/TypoGraphy';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import stringLengthRegex from '../../../utils/stringLengthRegex';
import priceRegex from '../../../utils/priceRegex';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import GoToPayment from '../orders/GoToPayment';
import { ChangeEvent } from 'react';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import emptyFavorites from '../../../static/image/emptyFavorite.svg';
import emptyImage from '../../../static/image/emptyImage.svg';
import emptyCart from '../../../static/image/emptyCart.svg';
import GuestLoginPage from '../../helpers/GuestLoginPage';
import PriceInfoFab from './PriceInfoFab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    divider: {
      margin: theme.spacing(1),
      height: theme.spacing(1),
    },
    emptyCartImage: {
      width: 100,
      height: 100,
      margin: theme.spacing(2),
    },

    productGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2,1fr)',
      padding: theme.spacing(1),
    },
    container: {
      paddingBottom: theme.spacing(10),
    },
    cardMedia: {
      width: '100%',
      objectFit: 'cover',
      backgroundRepeat: 'no-repeat',
      height: theme.spacing(15),
      borderRadius: theme.spacing(2),
    },
    card: {
      width: '100%',
      padding: theme.spacing(1),
    },
    cartCard: {
      display: 'flex',
      margin: theme.spacing(1),
      width: '100%',
      justifyContent: 'space-between',
      borderRadius: theme.spacing(3),
      boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.2)',
      // border: '1px solid rgba(254,114,76,0.5)',
    },
    cardDetails: {
      display: 'flex',
      flexDirection: 'column',
    },
    // cardContent: {
    //   flex: '1 0 auto',
    // },
    cardControls: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    cardCount: {
      color: theme.palette.text.secondary,
    },

    deleteIcon: {
      color: '#eb1111',
    },
    cardCover: {
      width: 120,
      display: 'flex',
    },
    cartRoot: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(15),
    },
    goToPayment: {
      marginBottom: theme.spacing(10),
    },
    paper: {
      flexGrow: 1,
    },
    avatar: {
      objectFit: 'cover',
      width: 100,
      height: 100,
      marginLeft: theme.spacing(1)
    },
    iconFlex: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: theme.spacing(0, 0, 0, 1),
      border: '1px solid #cccccc',
      borderRadius: theme.spacing(1),
      padding: theme.spacing(0.5),
    },
    cardHeader: {
      padding: '5px 0 5px 5px',
      width: '100%',
    },
    cardContent: {
      padding: '0 5px 0 0',
    },
    cardAction: {
      padding: '0 10px 0 10px',
    },
    productCardRoot: {
      borderRadius: theme.spacing(1),
      boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.2)',
      margin: theme.spacing(1),
      // border: '1px solid rgba(254,114,76,0.2)',
      width: '100%',
    },
    favImg: {
      width: '100%',
      height: theme.spacing(10),
      borderRadius: theme.spacing(1),
      objectFit: 'cover',
    },
    favCards: {
      borderRadius: theme.spacing(2),
      boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.2)',
      margin: theme.spacing(1, 0, 1, 0),
      // border: '1px solid rgba(254,114,76,0.4)',
      width: '100%',
      // border: '1px solid rgba(0,0,0,0.2)',
    },
  })
);

const CartParent = (props) => {
  const popularProducts = props.products;
  const classes = useStyles();
  const [tabValues, setTabValues] = useState('cart');
  const history = useHistory();
  const getUserFavoritesAction = props.getUserFavoritesAction;
  const favorites = props.favorites;
  const deleteFromFavoritesAction = props.deleteFromFavoritesAction;
  const userTypingAction = props.userTypingAction;
  const favoritesLoading = props.favoritesLoading;
  const cartFromServer = props.cartFromServer;
  const isCartReceived = props.isCartReceived;
  const getCartAction = props.getCartAction;
  const addCartAction = props.addCartAction;
  const removeCartAction = props.removeCartAction;
  const deleteCartAction = props.deleteCartAction;
  const priceInvoice = props.priceInvoice;
  const requestingPrice = props.requestingPrice;
  let role = 'guest';
  if (props.auth.authData.user) {
    role = props.auth.authData.user.role;
  }
  const userNavigationHistorySaver = props.userNavigationHistorySaver;
  const location = useLocation();

  const handleTabsChange = (event, newValue) => {
    setTabValues(newValue);

    if (newValue === 'favorites') {
      getUserFavoritesAction();
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    userNavigationHistorySaver(location.pathname);
    if (role !== 'guest') {
      getCartAction();
    }
  }, []);

  return (
    <div className={classes.container}>
      {role !== 'guest' ? (
        <>
          <Paper className={classes.paper}>
            <Tabs
              value={tabValues}
              onChange={handleTabsChange}
              indicatorColor='primary'
              textColor='primary'
              centered
            >
              {statusTabs.map((tab, i) => (
                <Tab label={tab.name} value={tab.value} key={i} />
              ))}
            </Tabs>
          </Paper>
          {tabValues === 'cart' ? (
            <>
              {isCartReceived === false ? (
                <>
                  {cartFromServer.length === 0 ? (
                    <>
                      <Grid container justifyContent='center'>
                        <img
                          alt='emptyCart'
                          src={emptyCart}
                          className={classes.emptyCartImage}
                        />
                      </Grid>
                      <TypoGraphy
                        text={'سبد خرید شما خالی است'}
                        variant={'h6'}
                        align={'center'}
                        color={'textPrimary'}
                      />
                      <Divider className={classes.divider} />
                      <TypoGraphy
                        text={'پیشنهاد برای شما'}
                        variant={'subtitle1'}
                        align={'right'}
                        color={'textPrimary'}
                        style={{ marginRight: 10 }}
                      />
                      <Divider />
                      <br />
                      <div className={classes.productGrid}>
                        {popularProducts.map((product, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              history.push(`/product/${product.id}`);
                            }}
                            className={classes.card}
                          >
                            <img
                              src={product.image}
                              alt='productImage'
                              className={classes.cardMedia}
                              onError={(event) => {
                                event.target.src = emptyImage;
                              }}
                            />
                            <TypoGraphy
                              text={stringLengthRegex(product.name)}
                              variant={'body2'}
                              align={'right'}
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <div className={classes.cartRoot}>
                      {cartFromServer.map((item, index) => (
                        <Card key={index} className={classes.productCardRoot}>
                          <div
                            style={{
                              padding: 0,
                              margin: 0,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}

                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <img
                                alt='cartPic'
                                onError={(event) => {
                                  event.target.src = emptyImage;
                                }}
                                src={item.product.image}
                                className={classes.avatar}
                                onClick={() => {
                                  history.push(`/product/${item.product.id}`);
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "flex-start"
                                }}
                              >
                                <TypoGraphy
                                  text={stringLengthRegex(item.product.name)}
                                  color={'textPrimary'}
                                  align={'right'}
                                  variant={"subtitle2"}
                                />
                                <TypoGraphy
                                  text={
                                    item.product.price <= 0
                                      ? 'توافقی'
                                      : priceRegex(
                                        item.product.price,
                                        'withCurrency'
                                      )
                                  }
                                  color={'textSecondary'}
                                  align={'right'}
                                  variant="subtitle2"
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center"
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  margin: "0px 0px 10px 20px"
                                }}
                              >
                                <IconButton
                                  color={'default'}
                                  size={'small'}
                                  edge={'end'}
                                  onClick={() => {
                                    deleteCartAction(item.product.id, item.count);
                                  }}
                                >
                                  <DeleteOutlineIcon />
                                </IconButton>
                              </div>

                              <div className={classes.iconFlex}>
                                <IconButton
                                  size={'small'}
                                  onClick={() => {
                                    addCartAction(item.product.id);
                                  }}
                                  edge="start"
                                  color={'default'}
                                >
                                  <AddIcon />
                                </IconButton>
                                &nbsp;
                                <TypoGraphy
                                  text={item.count}
                                  align={'center'}
                                  variant="subtitle2"
                                  color={'textPrimary'}
                                />
                                &nbsp;
                                <IconButton
                                  color="default"
                                  edge="start"
                                  size={'small'}
                                  onClick={() => {
                                    removeCartAction(item.product.id);
                                  }}
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </div>
                            </div>
                          </div>
                        </Card>
                      ))}

                      <GoToPayment />
                      {requestingPrice === false && (
                        <PriceInfoFab priceInvoice={priceInvoice} />
                      )}
                    </div>
                  )}
                </>
              ) : (
                <Grid
                  container
                  justifyContent='center'
                  alignItems='center'
                  style={{ marginTop: 200 }}
                >
                  <CircularProgress color='primary' size={80} />
                </Grid>
              )}
            </>
          ) : (
            <>
              {favoritesLoading === false ? (
                <>
                  {favorites.length === 0 ? (
                    <>
                      <Grid container justifyContent='center'>
                        <img
                          alt='emptyFavorite'
                          src={emptyFavorites}
                          className={classes.emptyCartImage}
                        />
                      </Grid>
                      <TypoGraphy
                        text={'لیست علاقه مندی شما خالی است'}
                        variant={'h6'}
                        align={'center'}
                        color={'textPrimary'}
                      />
                      <Divider className={classes.divider} />
                      <TypoGraphy
                        text={'پیشنهاد برای شما'}
                        variant={'subtitle1'}
                        align={'right'}
                        color={'textPrimary'}
                        style={{ marginRight: 10 }}
                      />
                      <Divider />
                      <br />
                      <div className={classes.productGrid}>
                        {popularProducts.map((product, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              history.push(`/product/${product.id}`);
                            }}
                            className={classes.card}
                          >
                            <img
                              src={product.image}
                              alt='productImage'
                              className={classes.cardMedia}
                              onError={(event) => {
                                event.target.src = emptyImage;
                              }}
                            />

                            <TypoGraphy
                              text={stringLengthRegex(product.name)}
                              variant={'body2'}
                              align={'right'}
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2,1fr)',
                        padding: 10,
                      }}
                    >
                      {favorites.map((favorite, index) => (
                        <Card key={index} className={classes.favCards}>
                          <CardActionArea
                            onClick={() => {
                              history.push(`/product/${favorite.id}`);
                            }}
                          >
                            <CardMedia
                              src={favorite.image}
                              className={classes.favImg}
                              component='img'
                              onError={(event) => {
                                event.target.src = emptyImage;
                              }}
                            />
                            <div>
                              <TypoGraphy
                                text={stringLengthRegex(favorite.name)}
                                variant={'subtitle2'}
                                align={'center'}
                                color={'textPrimary'}
                              />
                            </div>
                          </CardActionArea>
                          <div style={{ float: 'left', marginLeft: 10 }}>
                            <IconButton
                              size='small'
                              color='secondary'
                              edge='start'
                              onClick={() => {
                                userTypingAction(
                                  favorite.id,
                                  'singleProductId'
                                );
                                deleteFromFavoritesAction();
                              }}
                            >
                              <DeleteSweepIcon />
                            </IconButton>
                          </div>
                        </Card>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <Grid
                  container
                  justifyContent='center'
                  alignItems='center'
                  style={{ marginTop: 200 }}
                >
                  <CircularProgress color='primary' size={80} />
                </Grid>
              )}
            </>
          )}
        </>
      ) : (
        <div className='guestLoginPage'>
          <GuestLoginPage />
        </div>
      )}
    </div>
  );
};

const statusTabs = [
  { name: 'سبدخرید', value: 'cart' },
  { name: 'علاقه مندی ها', value: 'favorites' },
];

export default CartParent;
