import { connect } from 'react-redux';
import {
  drawerHandler,
  skeletonLoadingStatusAction,
  getOrderPriceAction,
  removeFromCartAction,
  increaseUnitsAction,
  decreaseUnitsAction,
  showAlertDialogAction,
  alertDialogContentAction,
  getCartAction,
  addCartAction,
  removeCartAction,
  deleteCartAction,
  userNavigationHistorySaver,
} from '../actions/reduxActions';
import CompleteOrderParent from '../../components/layout/orders/CompleteOrderParent';

const mapStateToProps = (state, ownProps) => {
  return {
    infoDrawer: state.appConfigs.infoDrawer,
    userInfos: state.userInfos,
    priceInvoice: state.orderStates.priceInvoice,
    cartItems: state.cart.cartItems,
    addressId: state.mapStates.addressId,
    requestingPrice: state.orderStates.requestingPrice,
    isCheckingCoupon: state.userInfos.isCheckingCoupon,
    cartFromServer: state.products.cartFromServer,
    isCartReceived: state.products.isCartReceived,
    hasUploadedImage: state.orderStates.hasUploadedImage,
    shouldUploadImage: state.orderStates.shouldUploadImage
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    drawerHandler: (open, type) => {
      dispatch(drawerHandler(open, type));
    },
    skeletonLoadingStatusAction: (type) => {
      dispatch(skeletonLoadingStatusAction(type));
    },
    getOrderPriceAction: () => {
      dispatch(getOrderPriceAction());
    },
    removeFromCartAction: (id) => {
      dispatch(removeFromCartAction(id));
    },
    increaseUnitsAction: (id) => {
      dispatch(increaseUnitsAction(id));
    },
    decreaseUnitsAction: (id) => {
      dispatch(decreaseUnitsAction(id));
    },
    showAlertDialogAction: (open) => {
      dispatch(showAlertDialogAction(open));
    },
    alertDialogContentAction: (title, content, textButton) => {
      dispatch(alertDialogContentAction(title, content, textButton));
    },
    getCartAction: () => {
      dispatch(getCartAction());
    },
    addCartAction: (id) => {
      dispatch(addCartAction(id));
    },
    removeCartAction: (id) => {
      dispatch(removeCartAction(id));
    },
    deleteCartAction: (id, count) => {
      dispatch(deleteCartAction(id, count));
    },
    userNavigationHistorySaver: (history) => {
      dispatch(userNavigationHistorySaver(history));
    },
  };
};

const CompleteOrderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompleteOrderParent);

export default CompleteOrderContainer;
