import { ACTION_CONSTANTS } from "../constants/actionConstants";

export default function userTypingsReducer(state = {}, action) {
  const payloadValue = action.payload;

  switch (action.type) {
    case ACTION_CONSTANTS.TYPE_USERNAME:
      return {
        ...state,
        username: payloadValue,
      };
    case ACTION_CONSTANTS.ORDER_COMMENT_REQUEST:
      return {
        ...state,
        submitCommentLoading: true,
      };
    case ACTION_CONSTANTS.ORDER_COMMENT_SUCCESS:
      return {
        ...state,
        submitCommentLoading: false,
        addCommentResponse: payloadValue.msg,
      };
    case ACTION_CONSTANTS.TYPE_PASSWORD:
      return {
        ...state,
        password: payloadValue,
      };
    case ACTION_CONSTANTS.PROFILE_MODE:
      return {
        ...state,
        profileMode: payloadValue,
      };
    case ACTION_CONSTANTS.USER_FIRST_NAME:
      return {
        ...state,
        firstName: payloadValue,
      };
    case ACTION_CONSTANTS.USER_LAST_NAME:
      return {
        ...state,
        lastName: payloadValue,
      };
    case ACTION_CONSTANTS.USER_REFERRER:
      return {
        ...state,
        referrer: payloadValue,
      };
    case ACTION_CONSTANTS.USER_GENDER:
      return {
        ...state,
        gender: payloadValue,
      };
    case ACTION_CONSTANTS.USER_BIRTHDAY:
      return {
        ...state,
        birthday: payloadValue,
      };
    case ACTION_CONSTANTS.EDIT_PROFILE_LOADING:
      return {
        ...state,
        editProfileLoading: payloadValue,
      };
    case ACTION_CONSTANTS.ORDER_TABS_STATUS:
      return {
        ...state,
        orderTabsStatus: payloadValue,
      };
    case ACTION_CONSTANTS.SET_SINGLE_ORDER_ID:
      return {
        ...state,
        singleOrderId: payloadValue,
      };
    case ACTION_CONSTANTS.COPY_COUPON_CODE:
      return {
        ...state,
        couponCode: payloadValue,
      };
    case ACTION_CONSTANTS.SET_SINGLE_PRODUCT_ID:
      return {
        ...state,
        singleProductId: payloadValue,
      };
    case ACTION_CONSTANTS.COMMENT_STAR:
      return {
        ...state,
        commentStar: payloadValue,
      };
    case ACTION_CONSTANTS.COMMENT_TEXT:
      return {
        ...state,
        commentText: payloadValue,
      };
    case ACTION_CONSTANTS.TYPE_ORDER_MSG:
      return {
        ...state,
        orderMsg: payloadValue,
      };
    case ACTION_CONSTANTS.USE_COUPON:
      return {
        ...state,
        useCoupon: payloadValue,
      };
    case ACTION_CONSTANTS.SET_ORDER_TIME:
      return {
        ...state,
        orderTime: payloadValue,
      };
    case ACTION_CONSTANTS.SET_ORDER_DATE:
      return {
        ...state,
        orderDate: payloadValue,
      };
    case ACTION_CONSTANTS.SELECT_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: payloadValue,
      };
    case ACTION_CONSTANTS.SET_PRICE_TO_PAY:
      return {
        ...state,
        priceToPay: payloadValue,
      };
    case ACTION_CONSTANTS.SET_CATEGORY_TO_SEARCH:
      return {
        ...state,
        categoryToSearch: payloadValue,
      };
    case ACTION_CONSTANTS.SET_FILTER_TO_SEARCH:
      return {
        ...state,
        filterToSearch: payloadValue,
      };
    case ACTION_CONSTANTS.PRODUCT_NAME_SEARCH:
      return {
        ...state,
        typeProductSearch: payloadValue,
      };
    case ACTION_CONSTANTS.IS_WEB_VIEWED:
      return {
        ...state,
        isWebViewed: payloadValue,
      };
    case ACTION_CONSTANTS.OTP_RESEND_DISABLE:
      return {
        ...state,
        otpResendBtnDisable: payloadValue,
      };
    case ACTION_CONSTANTS.HAS_DELIVERY_TYPE:
      return {
        ...state,
        submitDelivery: payloadValue,
      };
    case ACTION_CONSTANTS.SET_USER_BIRTHDAY:
      return {
        ...state,
        userBirthdayValue: payloadValue,
      };
    case ACTION_CONSTANTS.REPORT_COMMENT_REASON:
      return {
        ...state,
        reportCommentReason: payloadValue,
      }
    case ACTION_CONSTANTS.SET_REPORT_COMMENT_ID:
      return {
        ...state,
        reportCommentId: payloadValue,
      }
    default:
      return state;
  }
}
