import { forwardRef } from 'react';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grow
} from '@material-ui/core';
import MaterialButton from './MaterialButton';
import CancelIcon from '@material-ui/icons/Cancel';
import TypoGraphy from './TypoGraphy';
import ReactStars from 'react-rating-stars-component';
import InputTextField from './InputTextField';
import ReceiptFactor from '../layout/orders/ReceiptFactor';
import Rating from '@material-ui/lab/Rating';
import { useDispatch } from 'react-redux';
import { reportCommentAction } from '../../redux/actions/reduxActions';

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} timeout={1000} />;
});

export default function ActionDialogHandler(props) {
  const status = props.actionDialogStatus;
  const actionDialogHandler = props.actionDialogHandler;
  const userTypingAction = props.userTypingAction
  const userTypings = props.userTypings
  const addCommentsAction = props.addCommentsAction
  const addCommentDisabled = props.addCommentDisabled
  const showAlertDialogAction = props.showAlertDialogAction
  const alertDialogContentAction = props.alertDialogContentAction
  const singleOrder = props.singleOrder
  const dispatch = useDispatch()

  const handleCloseClick = () => {
    actionDialogHandler(false, status.type);
  };

  const handleSubmitClick = () => {
    if (status.type === 'addComment') {
      if (userTypings.commentText.length === 0) {
        showAlertDialogAction(true)
        alertDialogContentAction('دیدگاه خود را بنویسید', 'برای ثبت نظر باید دیدگاه خود را در باکس بنویسید', 'متوجه شدم')
      } else {
        addCommentsAction()

      }
    } else if (status.type === 'reportComment') {
      if (userTypings.reportCommentReason?.length === 0) {
        showAlertDialogAction(true)
        alertDialogContentAction('همه موارد پر نشده', 'دلیل تخلف دیدگاه موردنظر را در باکس زیر بنویسید', 'متوجه شدم')
      } else {
        dispatch(reportCommentAction())
      }
    }
  }

  const handleInputChange = (value, inputType) => {
    userTypingAction(value, inputType);
  };

  const handlePrintFactor = () => {
    window.print()
  }

  return (
    <div>
      <Dialog
        style={{ zIndex: 300000 }}
        fullWidth
        fullScreen={status.type === 'orderFactor' ? true : false}
        maxWidth={'xl'}
        open={status.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseClick}
      >
        <DialogTitle>
          <Grid container justifyContent='space-between' alignItems='center'>
            <TypoGraphy
              text={
                status.type === 'addComment' ? (
                  'ثبت نظر'
                ) : status.type === 'orderFactor' ? ("فاکتور سفارش") :
                  status.type === 'reportComment' ? 'گزارش تخلف' : null
              }
              align={'right'}
              color={'textPrimary'}
              variant={'subtitle1'}
            />
            <MaterialButton
              btnType={'iconBtn'}
              icon={<CancelIcon />}
              color={'primary'}
              size={'small'}
              edge={'end'}
              onClick={handleCloseClick}
            />
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {status.type === 'addComment' ? (
            <>
              <Rating
                name="simple-controlled"
                value={userTypings.commentStar}
                onChange={(event, newValue) => {
                  userTypingAction(newValue, 'commentStar');
                }}
              />
              <InputTextField
                variant={"outlined"}
                placeholder={"دیدگاه خود را بنویسید"}
                value={userTypings.commentText}
                onChange={handleInputChange}
                inputType={"commentText"}
                size={"small"}
                multiline={true}
                fullWidth={true}
                style={{ margin: "10px 0 10px 0" }}
              />
            </>
          ) : (
            status.type === 'orderFactor' ? (
              <>
                <br />
                <ReceiptFactor factorFullObject={singleOrder} />
              </>
            ) : status.type === 'reportComment' ? (
              <>
                <InputTextField
                  variant={"outlined"}
                  placeholder={"دلیل گزارش دیدگاه را بنویسید"}
                  value={userTypings.reportCommentReason}
                  onChange={handleInputChange}
                  inputType={"reportComment"}
                  size={"medium"}
                  multiline={true}
                  fullWidth={true}
                  style={{ margin: "10px 0 10px 0" }}
                />
              </>
            ) : null
          )}
        </DialogContent>
        <Divider />
        <DialogActions>

          <Grid container justifyContent={"space-between"} alignItems={"center"}>
            <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
              <MaterialButton
                fullWidth={true}
                onClick={handleCloseClick}
                btnType={"button"}
                color={"primary"}
                text={"خروج"}
                variant={status.type === 'orderFactor' ? "contained" : "outlined"}

              />
            </Grid>
            {status.type === 'orderFactor' ? null :
              <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                <MaterialButton
                  onClick={handleSubmitClick}
                  text={"ثبت"}
                  fullWidth={true}
                  color={"secondary"}
                  variant={"contained"}
                  btnType={"button"}
                  disabled={addCommentDisabled}
                />
              </Grid>
            }
            {status.type === 'orderFactor' && (
              <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                <MaterialButton
                  fullWidth={true}
                  onClick={handlePrintFactor}
                  text={"ذخیره فاکتور"}
                  color={"secondary"}
                  variant={"contained"}
                  btnType={"button"}

                />
              </Grid>
            )}
          </Grid>


        </DialogActions>
      </Dialog>
    </div>
  );
}
