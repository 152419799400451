import {
  makeStyles,
  createStyles,
  Theme,
  Button,
  ButtonGroup,
  Grid,
  Badge,
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import MaterialButton from '../../helpers/MaterialButton';
import { useEffect } from 'react';
import TypoGraphy from '../../helpers/TypoGraphy';
import priceRegex from '../../../utils/priceRegex';
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles((theme) =>
  createStyles({
    btnRoot: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: theme.spacing(3),
      justifyContent: 'center',
    },
    root: {
      margin: theme.spacing(1, 1, 0, 1),
    },
  })
);

export default function SelectPayment(props) {
  const userTypingAction = props.userTypingAction;
  const userTypings = props.userTypings;
  const userProfile = props.userProfile;
  const priceInvoice = props.priceInvoice;
  const getUserDetailsAction = props.getUserDetailsAction;
  const drawerHandler = props.drawerHandler;
  const getProfileLoading = props.getProfileLoading;
  const classes = useStyles();
  const requestingPrice = props.requestingPrice;
  const getOrderPriceAction = props.getOrderPriceAction;

  const selectCreditMethod = () => {
    if (priceInvoice.customer_credit < priceInvoice.invoice.price) {
      drawerHandler(true, 'chargeAccount');
    } else {
      userTypingAction('credit', 'paymentMethod');
    }
  };

  const selectCashMethod = () => {
    userTypingAction('cash', 'paymentMethod');
  };

  const getUserDetails = () => {
    getOrderPriceAction();
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent='space-around'
        alignItems='center'
        style={{ marginTop: 20 }}
      >
        {requestingPrice === false ? (
          <>
            <TypoGraphy
              text={`اعتبار شما: ${priceInvoice.customer_credit.toLocaleString(
                'fa-IR'
              )} تومان`}
              style={{ fontWeight: 'bold', color: '#eb1111' }}
              align={'center'}
            />
            <MaterialButton
              btnType={'button'}
              text={'بازنشانی'}
              icon={<RefreshIcon />}
              color={'inherit'}
              style={{ margin: 0, padding: 5 }}
              onClick={getUserDetails}
              size={'small'}
              variant={'outlined'}
            />
          </>
        ) : (
          <CircularProgress size={20} color='primary' />
        )}
      </Grid>
      <Grid container justifyContent='space-around' alignItems='baseline'>
        <TypoGraphy
          text={'انتخاب روش پرداخت'}
          style={{ marginLeft: 5 }}
          variant={'subtitle1'}
          color={'textPrimary'}
        />
        <div className={classes.btnRoot}>
          <MaterialButton
            btnType={'button'}
            text={'اعتباری'}
            variant={
              userTypings.paymentMethod === 'credit' ? 'contained' : 'outlined'
            }
            color={'secondary'}
            style={{ margin: 0, padding: 0, width: 40, height: 40 }}
            onClick={selectCreditMethod}
            size={'large'}
          />
          &nbsp;&nbsp;
          <MaterialButton
            btnType={'button'}
            text={'نقدی'}
            size={'large'}
            variant={
              userTypings.paymentMethod === 'cash' ? 'contained' : 'outlined'
            }
            color={'secondary'}
            style={{ margin: 0, padding: 0, width: 40, height: 40 }}
            onClick={selectCashMethod}
          />
        </div>
      </Grid>
    </div>
  );
}
