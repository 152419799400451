import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { userTypingAction } from '../../../redux/actions/reduxActions';
import { makeStyles, createStyles, Theme, Button } from '@material-ui/core';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    btnRoot: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: theme.spacing(3),
      justifyContent: 'space-around',
    },
    root: {
      margin: theme.spacing(1, 1, 0, 1),
      borderTop: '1px solid #ccc',
    },
  })
);

export default function SelectDeliveryType({ userTypings }) {
  const deliveryType = userTypings.submitDelivery;
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(userTypingAction(true, 'setDeliveryType'));
  }, [dispatch]);

  return (
    // <div
    //   style={{
    //     marginTop: deliveryType === false ? '15em' : '0',
    //   }}
    //   className={classes.btnRoot}
    // >
    //   <Typography variant='subtitle1' color='textPrimary' align='right'>
    //     انتخاب نوع تحویل
    //   </Typography>
    //   <div>
    //     <Button
    //       onClick={() => {
    //         dispatch(userTypingAction(false, 'setDeliveryType'));
    //       }}
    //       variant={deliveryType === false ? 'contained' : 'outlined'}
    //       color='primary'
    //       size='small'
    //     >
    //       حضوری
    //     </Button>
    //     <Button
    //       variant={deliveryType === true ? 'contained' : 'outlined'}
    //       color='primary'
    //       size='small'
    //       onClick={() => {
    //         dispatch(userTypingAction(true, 'setDeliveryType'));
    //       }}
    //     >
    //       پیک
    //     </Button>
    //   </div>
    // </div>
    null
  );
}
