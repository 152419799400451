import { ACTION_CONSTANTS } from "../constants/actionConstants";
import AXIOS from "../../utils/baseAxios";
import * as storageHandler from "../../utils/storageHandler";
import regexHandler from "../../utils/regexHandler";
import { successToast } from "../../utils/toastHandler";
import { handleAxiosErrors } from "../../utils/httpErrorHandler";
import imageCompression from "browser-image-compression";

export const userTypingAction = (value, type) => async (dispatch) => {
  try {
    dispatch({
      type:
        type === "username"
          ? ACTION_CONSTANTS.TYPE_USERNAME
          : type === "password"
            ? ACTION_CONSTANTS.TYPE_PASSWORD
            : type === "profileMode"
              ? ACTION_CONSTANTS.PROFILE_MODE
              : type === "firstName"
                ? ACTION_CONSTANTS.USER_FIRST_NAME
                : type === "lastName"
                  ? ACTION_CONSTANTS.USER_LAST_NAME
                  : type === "referrer"
                    ? ACTION_CONSTANTS.USER_REFERRER
                    : type === "gender"
                      ? ACTION_CONSTANTS.USER_GENDER
                      : type === "birthday"
                        ? ACTION_CONSTANTS.USER_BIRTHDAY
                        : type === "orderTabsStatus"
                          ? ACTION_CONSTANTS.ORDER_TABS_STATUS
                          : type === "singleOrderId"
                            ? ACTION_CONSTANTS.SET_SINGLE_ORDER_ID
                            : type === "copyCoupon"
                              ? ACTION_CONSTANTS.COPY_COUPON_CODE
                              : type === "singleProductId"
                                ? ACTION_CONSTANTS.SET_SINGLE_PRODUCT_ID
                                : type === "commentStar"
                                  ? ACTION_CONSTANTS.COMMENT_STAR
                                  : type === "commentText"
                                    ? ACTION_CONSTANTS.COMMENT_TEXT
                                    : type === "orderMsg"
                                      ? ACTION_CONSTANTS.TYPE_ORDER_MSG
                                      : type === "useCoupon"
                                        ? ACTION_CONSTANTS.USE_COUPON
                                        : type === "orderTime"
                                          ? ACTION_CONSTANTS.SET_ORDER_TIME
                                          : type === "orderDate"
                                            ? ACTION_CONSTANTS.SET_ORDER_DATE
                                            : type === "paymentMethod"
                                              ? ACTION_CONSTANTS.SELECT_PAYMENT_METHOD
                                              : type === "setPriceToPay"
                                                ? ACTION_CONSTANTS.SET_PRICE_TO_PAY
                                                : type === "categoryToSearch"
                                                  ? ACTION_CONSTANTS.SET_CATEGORY_TO_SEARCH
                                                  : type === "filterToSearch"
                                                    ? ACTION_CONSTANTS.SET_FILTER_TO_SEARCH
                                                    : type === "typeProductSearch"
                                                      ? ACTION_CONSTANTS.PRODUCT_NAME_SEARCH
                                                      : type === "isWebViewed"
                                                        ? ACTION_CONSTANTS.IS_WEB_VIEWED
                                                        : type === "setResendDis"
                                                          ? ACTION_CONSTANTS.OTP_RESEND_DISABLE
                                                          : type === "setDeliveryType"
                                                            ? ACTION_CONSTANTS.HAS_DELIVERY_TYPE
                                                            : type === "userBirthday"
                                                              ? ACTION_CONSTANTS.SET_USER_BIRTHDAY
                                                              : type === "editProfileLoading"
                                                                ? ACTION_CONSTANTS.EDIT_PROFILE_LOADING
                                                                : type === 'reportComment'
                                                                  ? ACTION_CONSTANTS.REPORT_COMMENT_REASON
                                                                  : type === 'setReportCommentId'
                                                                    ? ACTION_CONSTANTS.SET_REPORT_COMMENT_ID
                                                                    : null,
      payload: value,
    });
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const resetHomeItems = () => async (dispatch) => {
  dispatch({ type: ACTION_CONSTANTS.GET_HOME_ITEMS_REQUEST });
};

export const receiveOtpSmsAction = () => async (dispatch, getState) => {
  const state = getState();
  const username = state.userTypings.username;
  const shop_id = storageHandler.getLocalStorage("QR_SHOP")
    ? storageHandler.getLocalStorage("QR_SHOP")
    : null;
  try {
    if (username.length > 11) {
      dispatch(showAlertDialogAction(true));
      dispatch(
        alertDialogContentAction(
          "شماره اشتباه",
          "شماره تلفن همراه باید 11 رقم باشد",
          "اصلاح شماره"
        )
      );
    } else if (regexHandler(username) === false) {
      dispatch(showAlertDialogAction(true));
      dispatch(
        alertDialogContentAction(
          "شماره اشتباه",
          "فرمت شماره تلفن وارد شده اشتباه است",
          "اصلاح شماره"
        )
      );
    } else {
      dispatch({ type: ACTION_CONSTANTS.RECEIVE_OTP_SMS_REQUEST });
      const { data } = await AXIOS.post(`/api/v1/user/login`, {
        username,
        shop_id,
      });
      dispatch({ type: ACTION_CONSTANTS.RECEIVE_OTP_SMS, payload: data });
      dispatch(resetHomeItems());
    }
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const validateOtpSmsAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.VALIDATE_OTP_SMS_REQUEST });

    const state = getState();
    const username = state.userTypings.username;
    const code = state.userTypings.password;
    const shop_id = storageHandler.getLocalStorage("QR_SHOP")
      ? storageHandler.getLocalStorage("QR_SHOP")
      : null;
    const { data } = await AXIOS.post(`/api/v1/user/validate`, {
      username,
      code,
      shop_id,
    });
    dispatch({ type: ACTION_CONSTANTS.VALIDATE_OTP_SMS, payload: data });
    storageHandler.setLocalStorage("USER_INFORMATION", data.data);
    storageHandler.setLocalStorage("AUTH_JWT", data.data.token.access_token);
    if (data.success === true) {
      // dispatch(sendFCMToken());
      if (state.auth.isCustomerNew === true) {
        setTimeout(() => {
          window.location.replace("/profile");
        }, 4000);
      } else {
        setTimeout(() => {
          window.location.replace("/");
        }, 4000);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const resendOtpSmsAction = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const username = state.userTypings.username;
    const { data } = await AXIOS.post(`/api/v1/user/resend`, { username });
    dispatch({ type: ACTION_CONSTANTS.RESEND_OTP_SMS, payload: data });
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const reportCommentAction = () => async (dispatch, getState) => {
  try {
    dispatch(actionDialogHandler(false, "reportComment"))
    const state = getState();
    const prdId = state.userTypings.singleProductId;
    const cmntId = state.userTypings.reportCommentId
    const user_id = state.auth.authData.user.id;
    const customer_id = state.auth.authData.customer.id;
    const comment = state.userTypings.reportCommentReason;
    const { data } = await AXIOS.post(`/api/v1/product/${prdId}/comments/${cmntId}/report`
      ,
      {
        user_id,
        customer_id,
        comment
      }
    );
    dispatch({ type: ACTION_CONSTANTS.REPORT_COMMENT_REQUEST, payload: data });
    dispatch(userTypingAction("", "setReportCommentId"));
    successToast(data.msg)
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const logoutAction = () => (dispatch) => {
  storageHandler.removeLocalStorage("AUTH_JWT");
  storageHandler.removeLocalStorage("APP_CONFIGS");
  storageHandler.removeLocalStorage("USER_INFORMATION");
  storageHandler.removeLocalStorage("POPULAR_PRODUCTS");
  dispatch({ type: ACTION_CONSTANTS.USER_LOGOUT });
  document.location.href = "/login";
};

export const getAppConfigsAction = () => async (dispatch, getState) => {
  try {
    const { data } = await AXIOS.get(`/api/v1/app/config`);
    dispatch({ type: ACTION_CONSTANTS.GET_APP_CONFIGS, payload: data });
    storageHandler.setLocalStorage("APP_CONFIGS", data.data);
    // dispatch(userTypingAction(data.data.order_time_zone[1].key, 'orderTime'));
    // dispatch(userTypingAction(data.data.order_time_day[1].key, 'orderDate'));
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const getHomeItemsAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.GET_HOME_ITEMS_REQUEST });
    const params = new URLSearchParams(window.location.search);
    let catId = params.get("category");
    if (catId == null) {
      catId = "";
    }
    const { data } = await AXIOS.get(`/api/v1/home?category=${catId}`);
    dispatch({ type: ACTION_CONSTANTS.GET_HOME_ITEMS, payload: data });
    data.data.map((x) => {
      if (x.type_name === "favorite") {
        storageHandler.setLocalStorage("POPULAR_PRODUCTS", x.items);
      } else if (x.type_name === "category") {
        storageHandler.setLocalStorage("CATEGORIES", x.items);
        if (x.type_name === "category" && x.base_category === true) {
          const fakeCategories = [{ name: "همه", id: "" }];
          storageHandler.setLocalStorage("PARENT_CATEGORY", fakeCategories);
        }
      } else if (x.type_name === "category_steps") {
        storageHandler.setLocalStorage("PARENT_CATEGORY", x.items);
      }
    });
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const getGuestToken = () => async (dispatch) => {
  try {
    const { data } = await AXIOS.post(`/api/v1/user/login/guest`);
    dispatch({ type: ACTION_CONSTANTS.REQUEST_GUEST_TOKEN, payload: data });
    storageHandler.setLocalStorage("USER_INFORMATION", data.data);
    storageHandler.setLocalStorage("AUTH_JWT", data.data.token.access_token);
    setTimeout(() => {
      window.location.replace("/");
    }, 2000);
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const getQRToken = (id) => async (dispatch) => {
  try {
    const { data } = await AXIOS.get(`/api/v1/qrcode/${id}/get`);
    dispatch({ type: ACTION_CONSTANTS.GET_QR_CODE_TOKEN, payload: data });
    storageHandler.setLocalStorage("USER_INFORMATION", data.data);
    storageHandler.setLocalStorage("AUTH_JWT", data.data.token.access_token);
    storageHandler.setLocalStorage("QR_SHOP", data.data.user.login_to_id);
    setTimeout(() => {
      window.location.replace("/");
    }, 2000);
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const getCategoriesAction = () => async (dispatch) => {
  try {
    const { data } = await AXIOS.get(`/api/v1/category`);
    dispatch({ type: ACTION_CONSTANTS.GET_CATEGORIES, payload: data });
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const getProductDetailsAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.GET_PRODUCT_DETAILS_REQUEST });
    const state = getState();
    const id = state.userTypings.singleProductId;
    let customer = "";
    if (storageHandler.getLocalStorage("AUTH_JWT")) {
      customer = state.auth.authData.customer.id;
    }
    const { data } = await AXIOS.get(
      `/api/v1/app/product/${id}?customer=${customer}`
    );
    dispatch({
      type: ACTION_CONSTANTS.GET_PRODUCT_DETAILS_SUCCESS,
      payload: data,
    });
    dispatch(checkIfInFavoritesAction(data.data.is_favorite));
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const getProductsByCategoryAction =
  (id) => async (dispatch, getState) => {
    try {
      const state = getState();
      const page = state.userTypings.page;
      const limit = state.userTypings.limit;

      const { data } = await AXIOS.get(
        `/api/v1/products?type=category&page=${page}&limit=${limit}&category=${id}`
      );
      dispatch({
        type: ACTION_CONSTANTS.GET_PRODUCTS_BY_CATEGORY,
        payload: data,
      });
    } catch (error) {
      handleAxiosErrors(error);
    }
  };

export const getAllProductsByTypeAction =
  (type) => async (dispatch, getState) => {
    try {
      const state = getState();
      const page = state.userTypings.page;
      const limit = state.userTypings.limit;
      const { data } = await AXIOS.get(
        `/api/v1/products?type=${type}&page=${page}&limit=${limit}`
      );
      dispatch({
        type: ACTION_CONSTANTS.GET_ALL_PRODUCTS_BY_TYPE,
        payload: data,
      });
    } catch (error) {
      handleAxiosErrors(error);
    }
  };

export const showAlertDialogAction = (open) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.SHOW_ALERT_DIALOG, payload: open });
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const alertDialogContentAction =
  (title, content, textButton) => async (dispatch) => {
    try {
      const data = {
        title: title,
        content: content,
        textButton: textButton,
      };
      dispatch({ type: ACTION_CONSTANTS.ALERT_DIALOG_CONTENT, payload: data });
    } catch (error) {
      handleAxiosErrors(error);
    }
  };

export const skeletonLoadingStatusAction = (type) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.SKELETON_LOADING_STATUS, payload: type });
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const addToCartAction =
  (id, name, price, qty, image) => async (dispatch, getState) => {
    dispatch({
      type: ACTION_CONSTANTS.CART_ADD_ITEM,
      payload: {
        id: id,
        name: name,
        price: price,
        qty: qty,
        image: image,
      },
    });
    localStorage.setItem(
      "CART_ITEMS",
      JSON.stringify(getState().cart.cartItems)
    );
    dispatch(getOrderPriceAction());
  };
export const removeFromCartAction = (id) => (dispatch, getState) => {
  dispatch({
    type: ACTION_CONSTANTS.CART_REMOVE_ITEM,
    payload: id,
  });
  localStorage.setItem("CART_ITEMS", JSON.stringify(getState().cart.cartItems));
  dispatch(getOrderPriceAction());
};

export const increaseUnitsAction = (id) => (dispatch, getState) => {
  dispatch({
    type: ACTION_CONSTANTS.CART_INCREASE_ITEMS,
    payload: id,
  });
  localStorage.setItem("CART_ITEMS", JSON.stringify(getState().cart.cartItems));

  dispatch(getOrderPriceAction());
};

export const decreaseUnitsAction = (id) => (dispatch, getState) => {
  dispatch({
    type: ACTION_CONSTANTS.CART_DECREASE_ITEMS,
    payload: id,
  });
  localStorage.setItem("CART_ITEMS", JSON.stringify(getState().cart.cartItems));

  dispatch(getOrderPriceAction());
};

export const clearCartAction = () => async (dispatch, getState) => {
  const state = getState();

  const customer_id = state.auth.authData.customer.id;
  const products = [];
  await AXIOS.post(`/api/v1/app/customer/${customer_id}/cart/update`, {
    products,
  });
};

export const getUserDetailsAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.GET_USER_DETAILS_REQUEST });
    const state = getState();
    const id = state.auth.authData.customer.id;
    const { data } = await AXIOS.get(`/api/v1/app/customers/${id}`);
    dispatch({ type: ACTION_CONSTANTS.GET_USER_DETAILS, payload: data });
    dispatch(userTypingAction(data.data.mobile, "username"));
    dispatch(userTypingAction(data.data.first_name, "firstName"));
    dispatch(userTypingAction(data.data.last_name, "lastName"));
    dispatch(userTypingAction(data.data.referrer, "referrer"));
    dispatch(userTypingAction(data.data.birthday, "email"));
    if (data.data.gender === "آقا") {
      dispatch(userTypingAction("man", "gender"));
    } else {
      dispatch(userTypingAction("woman", "gender"));
    }
    dispatch(userTypingAction(data.data.birthday, "userBirthday"));
    if (window.location.pathname.match("/checkout")) {
      dispatch(
        userTypingAction(
          Math.abs(
            data.data.credit - state.orderStates.priceInvoice.invoice.price
          ),
          "setPriceToPay"
        )
      );
    } else {
      dispatch(userTypingAction(10000, "setPriceToPay"));
    }
  } catch (error) {
    if (error.response !== undefined) {
      handleAxiosErrors(error);
    }
  }
};

export const getUserAddressesAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.GET_USER_ADDRESS_REQUEST });
    const state = getState();
    const id = state.auth.authData.customer.id;
    const { data } = await AXIOS.get(`/api/v1/app/customer/${id}/addresses`);
    dispatch({ type: ACTION_CONSTANTS.GET_USER_ADDRESSES, payload: data });
    // dispatch(setAddressIdAction(data.data[0].id));
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const editProfileAction = () => async (dispatch, getState) => {
  try {
    const state = getState();

    const id = state.auth.authData.customer.id;
    const mobile = state.auth.authData.customer.mobile;
    const first_name = state.userTypings.firstName;
    const last_name = state.userTypings.lastName;
    const referrer = state.userTypings.referrer;
    const gender = state.userTypings.gender;
    const birthday = state.userTypings.userBirthdayValue;
    const { data } = await AXIOS.post(`/api/v1/app/customers/${id}/edit`, {
      mobile,
      first_name,
      last_name,
      referrer,
      gender,
      birthday,
    });
    dispatch({ type: ACTION_CONSTANTS.EDIT_USER, payload: data });
    dispatch({ type: ACTION_CONSTANTS.EDIT_PROFILE_LOADING, payload: false });
    successToast(data.msg);
    dispatch(userTypingAction("whole", "profileMode"));
    dispatch(getUserDetailsAction());
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const getUserCouponsAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.GET_COUPONS_REQUEST });
    const state = getState();
    const id = state.auth.authData.customer.id;
    const { data } = await AXIOS.get(`/api/v1/app/customer/${id}/coupons`);
    dispatch({ type: ACTION_CONSTANTS.GET_COUPONS, payload: data });
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const getUserOrdersAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.GET_USER_ORDERS_REQUEST });
    const state = getState();
    const id = state.auth.authData.customer.id;
    const page = state.userTypings.page;
    const limit = state.userTypings.limit;
    const status = state.userTypings.orderTabsStatus;
    const { data } = await AXIOS.get(
      `/api/v1/app/customer/${id}/orders?page=${page}&limit=${limit}&status=${status}`
    );
    dispatch({ type: ACTION_CONSTANTS.GET_USER_ORDERS, payload: data });
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const getUserFavoritesAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.GET_FAVORITES_REQUEST });
    const state = getState();
    const id = state.auth.authData.customer.id;
    const { data } = await AXIOS.get(`/api/v1/app/customer/${id}/favorites`);
    dispatch({ type: ACTION_CONSTANTS.GET_FAVORITES, payload: data });
    dispatch(checkIfInFavoritesAction());
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const mapCenterAction = (center) => async (dispatch) => {
  dispatch({ type: ACTION_CONSTANTS.SET_MAP_CENTER, payload: center });
};

export const markerLatLngAction = (lat, lng) => async (dispatch) => {
  const markerPosition = {
    lat: lat,
    lng: lng,
  };
  dispatch({
    type: ACTION_CONSTANTS.SET_MARKER_POSITION,
    payload: markerPosition,
  });
};

export const setAddressIdAction = (id) => async (dispatch) => {
  dispatch({ type: ACTION_CONSTANTS.SET_ADDRESS_ID, payload: id });
};

export const setShippingPriceAction = (value) => async (dispatch) => {
  dispatch({ type: ACTION_CONSTANTS.SET_SHIPPING_PRICE, payload: value });
  dispatch(getOrderPriceAction());
};

export const setAddressTitleAction = (value) => async (dispatch) => {
  dispatch({ type: ACTION_CONSTANTS.SET_ADDRESS_TITLE, payload: value });
};

export const setAddressAction = (value) => async (dispatch) => {
  dispatch({ type: ACTION_CONSTANTS.SET_FULL_ADDRESS, payload: value });
};

export const setAddressNumber = (value) => async (dispatch) => {
  dispatch({ type: ACTION_CONSTANTS.SET_ADDRESS_NUMBER, payload: value });
};

export const addAddressAction = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const id = state.auth.authData.customer.id;
    const title = state.mapStates.addressTitle;
    const address = state.mapStates.fullAddress;
    const number = state.mapStates.addressNumber;
    const lat = state.mapStates.lat;
    const lng = state.mapStates.lng;
    const { data } = await AXIOS.post(
      `/api/v1/app/customer/${id}/addresses/add`,
      {
        title,
        address,
        number,
        lat,
        lng,
      }
    );
    dispatch({ type: ACTION_CONSTANTS.ADD_ADDRESS, payload: data });
    successToast(data.msg);

    dispatch(getUserAddressesAction());
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const checkLocationZoneAction = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const lat = state.mapStates.lat;
    const lng = state.mapStates.lng;
    await AXIOS.post(`/api/v1/location/check`, {
      lat,
      lng,
    }).then((res) => {
      if (res.data.data.is_in_zone === false) {
        // successToast(res.data.msg);
        dispatch({
          type: ACTION_CONSTANTS.CHECK_LOCATION_ZONE,
          payload: {
            msg: res.data.msg,
            isInZone: res.data.data.is_in_zone,
          },
        });
      } else {
        dispatch({
          type: ACTION_CONSTANTS.CHECK_LOCATION_ZONE,
          payload: {
            msg: res.data.msg,
            isInZone: res.data.data.is_in_zone,
          },
        });
      }
    });
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const actionDialogHandler = (open, type) => async (dispatch) => {
  try {
    const data = {
      open: open,
      type: type,
    };
    dispatch({ type: ACTION_CONSTANTS.ACTION_DIALOG_HANDLER, payload: data });
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const getSingleOrderAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.GET_ORDER_DETAILS_REQUEST });
    const state = getState();
    const id = state.userTypings.singleOrderId;
    const { data } = await AXIOS.get(`/api/v1/app/order/${id}`);
    dispatch({ type: ACTION_CONSTANTS.GET_SINGLE_ORDER, payload: data });
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const drawerHandler = (open, type) => async (dispatch) => {
  try {
    const data = {
      open: open,
      type: type,
    };
    dispatch({ type: ACTION_CONSTANTS.DRAWER_HANDLER, payload: data });
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const getCommentsAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.GET_COMMENTS_REQUEST });
    const state = getState();
    const id = state.userTypings.singleProductId;
    const { data } = await AXIOS.get(`/api/v1/product/${id}/comments`);
    dispatch({
      type: ACTION_CONSTANTS.GET_COMMENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const addCommentsAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.ADD_COMMENTS_REQUEST });
    const state = getState();
    const productId = state.userTypings.singleProductId;
    const customer_id = state.auth.authData.customer.id;
    const star = state.userTypings.commentStar;
    const comment = state.userTypings.commentText;

    const { data } = await AXIOS.post(
      `/api/v1/product/${productId}/add/comment`,
      {
        customer_id,
        star,
        comment,
      }
    );
    dispatch({
      type: ACTION_CONSTANTS.ADD_COMMENTS_SUCCESS,
      payload: data,
    });
    dispatch(actionDialogHandler(false, "addComment"));
    dispatch(getCommentsAction());
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const addOrderCommentAction =
  (orderId) => async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_CONSTANTS.ORDER_COMMENT_REQUEST });
      const state = getState();
      const star = state.userTypings.commentStar;
      const comment = state.userTypings.commentText;

      const { data } = await AXIOS.post(
        `/api/v1/order/${orderId}/rate/create`,
        {
          star,
          comment,
        }
      );
      dispatch({
        type: ACTION_CONSTANTS.ORDER_COMMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      handleAxiosErrors(error);
    }
  };

export const getOrderPriceAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.GET_PRICE_REQUEST });
    const state = getState();
    const cartItems = state.products.cartFromServer;
    const products = [];
    for (let key in cartItems) {
      products[key] = {
        product_id: cartItems[key].product.id,
        count: cartItems[key].count,
      };
    }
    const customer = state.auth.authData.customer.mobile;
    const coupon = state.userTypings.couponCode;
    const shipping_price = state.mapStates.shippingPrice;
    // const use_coupon = state.userTypings.useCoupon;
    const use_coupon = true;

    const { data } = await AXIOS.post(`/api/v1/order/price/`, {
      products,
      customer,
      coupon,
      shipping_price,
      use_coupon,
    });
    dispatch({ type: ACTION_CONSTANTS.GET_PRICE_SUCCESS, payload: data });
    // dispatch(getUserDetailsAction());
    dispatch(
      userTypingAction(
        Math.abs(data.data.customer_credit - data.data.invoice.price),
        "setPriceToPay"
      )
    );
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const checkCouponAction = () => async (dispatch, getState) => {
  const state = getState();
  const code = state.userTypings.couponCode;
  try {
    if (code.length === 0) {
      dispatch(showAlertDialogAction(true));
      dispatch(
        alertDialogContentAction(
          "تعداد خالی",
          "باید کد تخفیف را وارد کنید",
          "متوجه شدم"
        )
      );
    } else {
      dispatch({ type: ACTION_CONSTANTS.VALIDATE_COUPON_REQUEST });

      const { data } = await AXIOS.post(`/api/v1/app/coupons/check`, {
        code,
      });
      dispatch({
        type: ACTION_CONSTANTS.VALIDATE_COUPON_SUCCESS,
        payload: data,
      });
      successToast(data.msg);
      dispatch(userTypingAction(true, "useCoupon"));
      dispatch(getOrderPriceAction());
    }
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const requestOnlinePayment = (orderId) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.REQUEST_ONLINE_PAYMENT_REQUEST });
    const state = getState();
    const success_url = `https://rosegol.app/paid-order?fromOrder=${orderId}`;
    const fail_url = `https://rosegol.app/failed-payment?fromOrder=${orderId}`;
    const { data } = await AXIOS.post(
      `/api/v1/order/${orderId}/payment/create`,
      {
        success_url,
        fail_url,
      }
    );
    dispatch({
      type: ACTION_CONSTANTS.REQUEST_ONLINE_PAYMENT_SUCCESS,
    });
    if (data.success === true) {
      if (state.userTypings.isWebViewed === true) {
        window.Android.openPayment(data.data.payment_url);
      } else {
        window.open(data.data.payment_url);
      }
    }
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const requestPaymentAction =
  (isFromChargeAccount) => async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_CONSTANTS.REQUEST_PAYMENT_REQUEST });
      const state = getState();
      const orderBy = state.userInfos.singleOrder.submit_by;
      let user = state.auth.authData.user.id;
      let customer = state.auth.authData.customer.id;
      let price = state.userTypings.priceToPay;
      let success_url = "";
      let fail_url = "";
      if (isFromChargeAccount) {
        success_url = "https://rosegol.app/paid-order?isCharging=true";
        fail_url = "https://rosegol.app/failed-payment?isCharging=true";
      } else {
        success_url = "https://rosegol.app/paid-order";
        fail_url = "https://rosegol.app/failed-payment";
      }

      if (orderBy === "panel") {
        var orderId = state.userInfos.singleOrder.order_id;
        success_url = `https://rosegol.app/order/${orderId}`;
        fail_url = `https://rosegol.app/order/${orderId}`;
        user = state.userInfos.singleOrder.created_by.id;
        customer = state.userInfos.singleOrder.customer.id;
        price = state.userInfos.singleOrder.invoice.price;
      }
      const { data } = await AXIOS.post(`/api/v1/payment/create`, {
        user,
        price,
        success_url,
        fail_url,
        customer,
        orderId,
      });
      dispatch({
        type: ACTION_CONSTANTS.REQUEST_PAYMENT_SUCCESS,
        payload: data,
      });
      if (state.userTypings.isWebViewed === true) {
        window.Android.openPayment(data.data.payment_url);
      } else {
        window.open(data.data.payment_url);
      }
      // if (orderBy === 'app') {
      //   successToast(data.msg);
      // }
    } catch (error) {
      handleAxiosErrors(error);
    }
  };

export const submitOrderAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.SUBMIT_ORDER_REQUEST });
    const state = getState();
    // const use_coupon = state.userTypings.useCoupon;
    const use_coupon = true;

    const coupon = state.userTypings.couponCode;
    const customer = state.auth.authData.customer.mobile;
    const user = state.auth.authData.user.id;
    const delivery = state.userTypings.submitDelivery;
    const address = state.mapStates.addressId;
    const payment_type = state.userTypings.paymentMethod;
    const msg = state.userTypings.orderMsg;
    const cartItems = state.products.cartFromServer;
    const shipping_price =
      state.userTypings.submitDelivery === true
        ? state.mapStates.shippingPrice
        : 0;
    // const files = storageHandler.getSessionStorage("UPLOADED_IMAGE_ID")
    //   ? [storageHandler.getSessionStorage("UPLOADED_IMAGE_ID")]
    //   : [];
    const products = [];
    for (let key in cartItems) {
      products[key] = {
        product_id: cartItems[key].product.id,
        count: cartItems[key].count,
      };
    }

    const { data } = await AXIOS.post(`/api/v1/order/submit/`, {
      use_coupon,
      coupon,
      customer,
      user,
      address,
      payment_type,
      msg,
      products,
      delivery,
      shipping_price,
    });
    dispatch({ type: ACTION_CONSTANTS.SUBMIT_ORDER_SUCCESS, payload: data });

    // successToast(data.msg);
    if (data.success === false) {
      successToast(data.msg);
    } else {
      dispatch(clearCartAction());
    }
    storageHandler.removeSessionStorage("UPLOADED_IMAGE_ID");
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const addToFavoritesAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.ADD_TO_FAVORITE_REQUEST });
    const state = getState();
    const customer_id = state.auth.authData.customer.id;
    const product_id = state.userTypings.singleProductId;
    const { data } = await AXIOS.post(
      `/api/v1/app/customer/${customer_id}/add/favorite`,
      { product_id }
    );
    dispatch({
      type: ACTION_CONSTANTS.ADD_TO_FAVORITE_SUCCESS,
      payload: data,
    });
    // successToast(data.msg);
    if (!window.location.pathname.match("/product/")) {
      dispatch(getUserFavoritesAction());
    }
    if (data.success === true) {
      dispatch(checkIfInFavoritesAction(true));
    }
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const deleteFromFavoritesAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.REMOVE_FROM_FAVORITE_REQUEST });
    const state = getState();

    const customer_id = state.auth.authData.customer.id;
    const product_id = state.userTypings.singleProductId;
    const { data } = await AXIOS.post(
      `/api/v1/app/customer/${customer_id}/delete/favorite`,
      { product_id }
    );
    dispatch({
      type: ACTION_CONSTANTS.REMOVE_FROM_FAVORITE_SUCCESS,
      payload: data,
    });
    // successToast(data.msg);
    if (!window.location.pathname.match("/product/")) {
      dispatch(getUserFavoritesAction());
    }
    if (data.success === true) {
      dispatch(checkIfInFavoritesAction(false));
    }
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const handleMsgChipsBtn = (key) => async (dispatch, getState) => {
  const state = getState();
  const prevMsg = state.userTypings.orderMsg;
  if (key === 0) {
    dispatch(
      userTypingAction(
        prevMsg + "هنگام تحویل با این شماره تماس بگیرید: \n",
        "orderMsg"
      )
    );
  } else if (key === 1) {
    dispatch(
      userTypingAction(
        prevMsg + "به محض حرکت پیک ارسال با من تماس بگیرید \n",
        "orderMsg"
      )
    );
  } else {
    dispatch(
      userTypingAction(
        prevMsg + "تحویل گیرنده این فرد می باشد:  \n",
        "orderMsg"
      )
    );
  }
};

export const clearOrderAction = () => async (dispatch) => {
  window.location.replace("/");
};

export const handleErrorPage = (isShow, type) => async (dispatch) => {
  const payload = {
    show: isShow,
    type: type,
  };
  dispatch({ type: ACTION_CONSTANTS.ERROR_PAGE, payload: payload });
};

export const searchProductsAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.SEARCH_PRODUCTS_REQUEST });
    const state = getState();
    const sort = state.userTypings.filterToSearch;
    const category = state.userTypings.categoryToSearch;
    const name = state.userTypings.typeProductSearch;
    const { data } = await AXIOS.post(`/api/v1/app/products/search`, {
      category,
      sort,
      name,
    });
    dispatch({ type: ACTION_CONSTANTS.SEARCH_PRODUCTS, payload: data });
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const checkIfInFavoritesAction =
  (bool) => async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_CONSTANTS.CHECK_IF_IN_FAVORITES, payload: bool });
    } catch (error) {
      handleAxiosErrors(error);
    }
  };

export const checkQtyInCart = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const cartItems = state.products.cartFromServer;
    const productId = state.userTypings.singleProductId;
    const itemInCart = cartItems.find((item) => item?.product?.id == productId);
    dispatch({
      type: ACTION_CONSTANTS.CHECK_HOW_MUCH_IN_CART,
      payload: itemInCart ? itemInCart.count : 0,
    });
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const addCartAction = (product_id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.UPDATE_CART_REQUEST });
    const state = getState();
    const customerId = state.auth.authData.customer.id;
    const count = 1;
    const { data } = await AXIOS.post(
      `/api/v1/app/customer/${customerId}/cart/add`,
      { product_id, count }
    );
    if (data.success === true) {
      // dispatch({ type: ACTION_CONSTANTS.UPDATE_CART_SUCCESS, payload: data });
      dispatch({ type: ACTION_CONSTANTS.GET_CART_SUCCESS, payload: data });
      if (!window.location.pathname.match("/product/")) {
        dispatch(getOrderPriceAction());
      }
      data.data.map((item) => {
        if (item.product.options.length > 0) {
          item.product.options.map((option) => {
            if (option.type === "upload_photo" && option.active === true) {
              dispatch({
                type: ACTION_CONSTANTS.SHOULD_UPLOAD_PHOTO,
                payload: true,
              });
            } else {
              dispatch({
                type: ACTION_CONSTANTS.SHOULD_UPLOAD_PHOTO,
                payload: false,
              });
            }
          });
        } else {
          dispatch({
            type: ACTION_CONSTANTS.SHOULD_UPLOAD_PHOTO,
            payload: false,
          });
        }
      });
      dispatch(checkQtyInCart());
    } else {
      successToast(data.msg);
      dispatch({ type: ACTION_CONSTANTS.CHECK_HOW_MUCH_IN_CART });
    }
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const removeCartAction = (product_id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.GET_CART_REQUEST });
    dispatch({ type: ACTION_CONSTANTS.UPDATE_CART_REQUEST });
    const state = getState();
    const customerId = state.auth.authData.customer.id;
    const count = 1;
    const { data } = await AXIOS.post(
      `/api/v1/app/customer/${customerId}/cart/remove`,
      { product_id, count }
    );
    dispatch({ type: ACTION_CONSTANTS.UPDATE_CART_SUCCESS, payload: data });
    // localStorage.setItem('CART', JSON.stringify(data.data));
    // dispatch(getCartAction());
    dispatch({ type: ACTION_CONSTANTS.GET_CART_SUCCESS, payload: data });
    data.data.map((item) => {
      if (item.product.options.length > 0) {
        item.product.options.map((option) => {
          if (option.type === "upload_photo" && option.active === true) {
            dispatch({
              type: ACTION_CONSTANTS.SHOULD_UPLOAD_PHOTO,
              payload: true,
            });
          } else {
            dispatch({
              type: ACTION_CONSTANTS.SHOULD_UPLOAD_PHOTO,
              payload: false,
            });
          }
        });
      } else {
        dispatch({
          type: ACTION_CONSTANTS.SHOULD_UPLOAD_PHOTO,
          payload: false,
        });
      }
    });
    if (!window.location.pathname.match("/product/")) {
      dispatch(getOrderPriceAction());
    }
    dispatch(checkQtyInCart());
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const deleteCartAction =
  (product_id, count) => async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_CONSTANTS.UPDATE_CART_REQUEST });
      const state = getState();
      const customerId = state.auth.authData.customer.id;
      const { data } = await AXIOS.post(
        `/api/v1/app/customer/${customerId}/cart/remove`,
        { product_id, count }
      );

      dispatch({ type: ACTION_CONSTANTS.UPDATE_CART_SUCCESS, payload: data });
      dispatch({ type: ACTION_CONSTANTS.GET_CART_SUCCESS, payload: data });
      dispatch(getOrderPriceAction());
      data.data.map((item) => {
        if (item.product.options.length > 0) {
          item.product.options.map((option) => {
            if (option.type === "upload_photo" && option.active === true) {
              dispatch({
                type: ACTION_CONSTANTS.SHOULD_UPLOAD_PHOTO,
                payload: true,
              });
            } else {
              dispatch({
                type: ACTION_CONSTANTS.SHOULD_UPLOAD_PHOTO,
                payload: false,
              });
            }
          });
        } else {
          dispatch({
            type: ACTION_CONSTANTS.SHOULD_UPLOAD_PHOTO,
            payload: false,
          });
        }
      });
      dispatch(checkQtyInCart());
    } catch (error) {
      handleAxiosErrors(error);
    }
  };

export const getCartAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.GET_CART_REQUEST });
    const state = getState();
    const customerId = state.auth.authData.customer.id;

    const { data } = await AXIOS.get(`/api/v1/app/customer/${customerId}/cart`);
    dispatch({ type: ACTION_CONSTANTS.GET_CART_SUCCESS, payload: data });
    data.data.map((item) => {
      if (item.product.options.length > 0) {
        item.product.options.map((option) => {
          if (option.type === "upload_photo" && option.active === true) {
            dispatch({
              type: ACTION_CONSTANTS.SHOULD_UPLOAD_PHOTO,
              payload: true,
            });
          } else {
            dispatch({
              type: ACTION_CONSTANTS.SHOULD_UPLOAD_PHOTO,
              payload: false,
            });
          }
        });
      } else {
        dispatch({
          type: ACTION_CONSTANTS.SHOULD_UPLOAD_PHOTO,
          payload: false,
        });
      }
    });
    if (!window.location.pathname.match("/product/")) {
      dispatch(getOrderPriceAction());
    }
    dispatch(checkQtyInCart());
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const userNavigationHistorySaver = (history) => async (dispatch) => {
  dispatch({
    type: ACTION_CONSTANTS.USER_NAVIGATION_HISTORY,
    payload: history,
  });
};

export const getUserTransaction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.GET_TRANSACTIONS_REQUEST });
    const state = getState();
    const userId = state.auth.authData.user.id;
    const { data } = await AXIOS.get(`/api/v1/user/${userId}/transactions`);
    dispatch({
      type: ACTION_CONSTANTS.GET_TRANSACTIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const sendFCMToken = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const userId = state.auth.authData.user.id;
    const token = storageHandler.getSessionStorage("FCM-TOKEN");
    const { data } = await AXIOS.post(`/api/v1/user/${userId}/fcm/token`, {
      token,
    });
    dispatch({
      type: ACTION_CONSTANTS.SEND_FCM_TOKEN,
      payload: data,
    });
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const compressImageAction = (event) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_CONSTANTS.IMAGE_COMPRESSION_LOADING });
    const imageFile = event.target.files[0];
    const state = getState();
    const submit_by = state.auth.authData.user.id;
    const fileType = "image";
    const compressOptions = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    imageCompression(imageFile, compressOptions)
      .then(function (compressedFile) {
        console.log(
          "compressedFile instanceof Blob",
          compressedFile instanceof Blob
        );
        console.log(
          `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        );
        const data = new FormData();
        data.append("file_name", compressedFile.name);
        data.append("file", compressedFile);
        data.append("type", fileType);
        data.append("submit_by", submit_by);
        dispatch({
          type: ACTION_CONSTANTS.IMAGE_COMPRESSION,
          payload: data,
        });
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const uploadImageAction = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const imageFormData = state.orderStates.compressedImageFormData;

    console.log(imageFormData);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    dispatch({ type: ACTION_CONSTANTS.UPLOAD_IMAGE_REQUEST });
    const { data } = await AXIOS.post(`/api/v1/upload`, imageFormData, config);
    if (data.success === true) {
      storageHandler.setSessionStorage("UPLOADED_IMAGE_ID", data.data);
      successToast(data.msg);
      dispatch({
        type: ACTION_CONSTANTS.UPLOAD_IMAGE_SUCCESS,
        payload: data,
      });
    } else {
      successToast(data.msg);
    }
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const detectGoogleBotAction = () => async (dispatch, getState) => {
  try {
    const botPattern =
      "(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";
    const botRegex = new RegExp(botPattern, "i");
    const userAgent = navigator.userAgent;
    if (botRegex.test(userAgent)) {
      console.log("the user agent is a crawler!");
      dispatch({
        type: ACTION_CONSTANTS.DETECT_GOOGLE_BOT,
      });
      dispatch(getGuestToken());
    }
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const cancelImageUploadAction = () => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_CONSTANTS.CANCEL_IMAGE_UPLOAD,
      payload: {},
    });
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const detectBrowserScroll = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const hasReachedBottom =
      window.innerHeight + window.scrollY >= document.body.offsetHeight;
    if (hasReachedBottom) {
      dispatch(getUserOrdersAction());
      dispatch({
        type: ACTION_CONSTANTS.DETECT_BROWSER_SCROLL,
        payload: hasReachedBottom,
      });
    }
  } catch (error) {
    handleAxiosErrors(error);
  }
};

export const setGiftCode = (coupon) => async (dispatch, getState) => {
  try {
    const state = getState();
    const customer = state.auth.authData.customer.id;
    const { data } = await AXIOS.post(`/api/v1/app/coupons/apply/`, {
      coupon,
      customer,
    });
    successToast(data.msg);
    dispatch({
      type: ACTION_CONSTANTS.SET_COUPON_GIFT,
      payload: data,
    });
  } catch (error) {
    handleAxiosErrors(error);
  }
};
