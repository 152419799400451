import { ACTION_CONSTANTS } from '../constants/actionConstants';

export default function appConfigReducers(state = {}, action) {
  const payload = action.payload;

  switch (action.type) {
    case ACTION_CONSTANTS.SHOW_ALERT_DIALOG:
      return {
        ...state,
        openDialog: payload,
      };
    case ACTION_CONSTANTS.ALERT_DIALOG_CONTENT:
      return {
        ...state,
        alertDialogContent: payload,
      };
    case ACTION_CONSTANTS.SKELETON_LOADING_STATUS:
      return {
        ...state,
        skeletonType: payload,
      };
    case ACTION_CONSTANTS.ACTION_DIALOG_HANDLER:
      return {
        ...state,
        actionDialog: payload,
      };
    case ACTION_CONSTANTS.DRAWER_HANDLER:
      return {
        ...state,
        infoDrawer: payload,
      };
    case ACTION_CONSTANTS.GET_APP_CONFIGS:
      return {
        ...state,
        configs: payload.data,
      };
    case ACTION_CONSTANTS.ERROR_PAGE:
      return {
        ...state,
        errorPageStatus: payload,
      };
    case ACTION_CONSTANTS.USER_NAVIGATION_HISTORY:
      return {
        ...state,
        historyValue: payload,
      };
      case ACTION_CONSTANTS.DETECT_BROWSER_SCROLL:
        return {
          ...state,
          hasReachedBottom: payload,
        }
    default:
      return state;
  }
}
