import AppBarContainer from '../navigation/AppBarContainer';
import AddressContainer from '../../../redux/containers/AddressContainer';
import SelectPayment from './SelectPayment';
import SelectCoupon from './SelectCoupon';
import SubmitOrder from './SubmitOrder';
import { useEffect } from 'react';
import LoadingSkeleton from '../../helpers/LoadingSkeleton';
import withAuth from '../../../utils/withAuth';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import SelectDeliveryType from './SelectDeliveryType';

const FinalizePayment = (props) => {
  const userTypingAction = props.userTypingAction;
  const userTypings = props.userTypings;
  const userProfile = props.userProfile;
  const priceInvoice = props.priceInvoice;
  const getUserDetailsAction = props.getUserDetailsAction;
  const drawerHandler = props.drawerHandler;
  const getProfileLoading = props.getProfileLoading;
  const getOrderPriceAction = props.getOrderPriceAction;
  const requestingPrice = props.requestingPrice;
  const skeletonLoadingStatusAction = props.skeletonLoadingStatusAction;
  const submitOrderAction = props.submitOrderAction;
  const addressId = props.addressId;
  const showAlertDialogAction = props.showAlertDialogAction;
  const alertDialogContentAction = props.alertDialogContentAction;
  const submittingOrder = props.submittingOrder;
  const submitOrderResponse = props.submitOrderResponse;
  const orderSubmitted = props.orderSubmitted;
  const isCheckingCoupon = props.isCheckingCoupon;
  const getCartAction = props.getCartAction;

  useEffect(() => {
    getCartAction();
    skeletonLoadingStatusAction('listSkeleton');
    // getUserDetailsAction();
  }, []);

  return (
    <div>
      <>
        <AppBarContainer />

        <SelectDeliveryType userTypings={userTypings} />
        {userTypings.submitDelivery === false ? null : <AddressContainer />}
        {requestingPrice === true ? (
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            direction='column'
          >
            <Skeleton
              variant='text'
              width={'90%'}
              height={100}
              animation='wave'
            />
            <br />
            <Skeleton
              variant='text'
              width={'90%'}
              height={100}
              animation='wave'
            />
          </Grid>
        ) : (
          <>
            {priceInvoice.invoice.price <= 0 ? (
              <div>
                <br />
                <br />
                <Typography
                  style={{ fontWeight: 'bold' }}
                  color='primary'
                  align='center'
                  variant='subtitle1'
                  gutterBottom
                >
                  پرداخت این سفارش در محل و توافقی می باشد
                </Typography>
              </div>
            ) : (
              <>
                <SelectCoupon drawerHandler={drawerHandler} />
                <SelectPayment
                  getOrderPriceAction={getOrderPriceAction}
                  requestingPrice={requestingPrice}
                  userTypingAction={userTypingAction}
                  userTypings={userTypings}
                  userProfile={userProfile}
                  priceInvoice={priceInvoice}
                  getUserDetailsAction={getUserDetailsAction}
                  drawerHandler={drawerHandler}
                  getProfileLoading={getProfileLoading}
                />
              </>
            )}
          </>
        )}
        <SubmitOrder
          priceInvoice={priceInvoice}
          drawerHandler={drawerHandler}
          submitOrderAction={submitOrderAction}
          addressId={addressId}
          showAlertDialogAction={showAlertDialogAction}
          alertDialogContentAction={alertDialogContentAction}
          submittingOrder={submittingOrder}
          submitOrderResponse={submitOrderResponse}
          orderSubmitted={orderSubmitted}
          requestingPrice={requestingPrice}
          userTypings={userTypings}
        />
      </>
    </div>
  );
};

export default FinalizePayment;
