import React from 'react';
import { Breadcrumbs, Typography } from '@material-ui/core';
import shortStringRegex from '../../../../utils/shortStringRegex';
import { useHistory } from 'react-router-dom';

export default function CatSteps({
    data,
    classes,
}) {
    const history = useHistory()
    const goToCat = (id) => {
        history.push(`/?category=${id}`)
    }

    return (
        <div
        className={classes.breadCrumbContainer}
        >
        <Breadcrumbs
        >
            {data
                ?.categories
                ?.map((step, index) => (
                    <Typography
                        variant='subtitle2'
                        onClick={() => {
                            goToCat(step.id)
                        }}
                        key={index}
                        className={classes.breadCrumbTypo}
                    >
                        {shortStringRegex(step.name)}
                    </Typography>
                ))
                .reverse()}
        </Breadcrumbs>
  </div>);
}
