import {
  makeStyles,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  Badge,
  Theme,
  createStyles,
  withStyles,
  Grow,
  Link,
} from '@material-ui/core';
import priceRegex from '../../../utils/priceRegex';
import stringLengthRegex from '../../../utils/stringLengthRegex';
import { useDispatch } from 'react-redux';
import { userTypingAction } from '../../../redux/actions/reduxActions';
import { useHistory } from 'react-router-dom';
import emptyImage from '../../../static/image/emptyImage.svg';
import TypoGraphy from '../../helpers/TypoGraphy';
import soldOutIcon from "../../../static/image/soldOut.png"

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(1),
  },
  card: {
    overflow: 'hidden',
    width: theme.spacing(35),
    borderRadius: theme.spacing(2),
    boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.2)',
  },
  cardMedia: {
    width: '100%',
    objectFit: 'cover',
    backgroundRepeat: 'no-repeat',
    height: theme.spacing(18),
    borderRadius: theme.spacing(2),
  },
  realPrice: {
    fontSize: 15,
    textDecoration: 'line-through',
    opacity: '0.7',
  },
  name: {
    fontSize: 14,
    marginRight: theme.spacing(2),
    fontWeight: 'bold',
  },
  price: {
    fontSize: 14,
    marginLeft: theme.spacing(2),
    fontWeight: 'bold',
  },
  soldOutIcon: {
    position: 'absolute',
    left: 15,
    top: 8,
    zIndex: 30000
  }
}));

const StyledBadge = withStyles((theme) =>
  createStyles({
    badge: {
      right: 30,
      top: 20,
      border: `2px solid #FF0042`,
      padding: '0 4px',
    },
  })
)(Badge);

export default function DiscountProductsList(props) {
  const classes = useStyles();
  const item = props.product;
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Link
      href={`/product/${item.id}`}
      underline='none'
      onClick={(event) => {
        event.preventDefault();
      }}
    >
      <Grow in={true} timeout={2000}>
        <div
          className={classes.root}
          onClick={() => {
            dispatch(userTypingAction(item.id, 'singleProductId'));
            history.push(`/product/${item.id}`);
          }}
        >
          <Card className={classes.card}>
            <CardActionArea>
              <StyledBadge
                badgeContent={
                  <TypoGraphy
                    text={`${item.discount_percent}%`}
                    variant={'body2'}
                  />
                }
                color='primary'
                id='discount-badge'
              >
                {item.limit === 0 &&
                  <img
                    src={soldOutIcon}
                    alt="soldOutIcon"
                    width="30"
                    className={classes.soldOutIcon}
                    height="30"
                  />
                }
                <CardMedia
                  className={classes.cardMedia}
                  src={item.image}
                  component='img'
                  onError={(event) => {
                    event.target.src = emptyImage;
                  }}
                />
              </StyledBadge>
              <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                style={{ marginBottom: 5 }}
              >
                <Typography color='textPrimary' className={classes.name}>
                  {stringLengthRegex(item.name)}
                </Typography>

                <Typography color='textPrimary' className={classes.price}>
                  <Typography color='primary' className={classes.realPrice}>
                    {item.real_price <= 0
                      ? 'توافقی'
                      : priceRegex(item.real_price, 'withCurrency')}
                  </Typography>
                  {item.price <= 0
                    ? 'توافقی'
                    : priceRegex(item.price, 'withCurrency')}
                </Typography>
              </Grid>
            </CardActionArea>
          </Card>
        </div>
      </Grow>
    </Link>
  );
}
