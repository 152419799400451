import { Typography } from '@material-ui/core'
import React from 'react'

export default function Polls({ data, classes }) {
    return (
        <div>
            {
                data.poll_url ? (
                    <iframe
                        title="wat"
                        src={`${data.poll_url}&orderId=${data?.order_id}&p=${data?.customer?.mobile}`}
                        className={classes.pollFrame}
                    />

                ) :
                    (
                        <Typography style={{ marginTop: 30 }} variant="h6" color="primary" align="center">
                            تنظیمات نظرسنجی برای شما یافت نشد
                        </Typography>
                    )
            }
        </div>
    )
}
