import {
  useScrollTrigger,
  Fab,
  Grow,
  makeStyles,
  Theme,
} from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

function ScrollTop(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });
  return (
    <Grow timeout={1000} in={trigger}>
      {children}
    </Grow>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: window.location.pathname.match('/search')
      ? theme.spacing(2)
      : theme.spacing(7.5),
    right: theme.spacing(2),
    zIndex: 1000,
  },
}));

export default function ScrollToTop(props) {
  const classes = useStyles();
  const handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <ScrollTop {...props}>
      <div className={classes.root} onClick={handleGoToTop}>
        <Fab color='secondary' size='small' aria-label='scroll back to top'>
          <KeyboardArrowUpIcon fontSize='large' />
        </Fab>
      </div>
    </ScrollTop>
  );
}
