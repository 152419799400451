import priceRegex from '../../../utils/priceRegex';
import './ReceiptParent.css';

export default function ReceiptFactor(props) {
  const factorFullObject = props.factorFullObject;

  return (
    <div id='section-to-print'>
      <div id='receipt-body'>
        <div id='top'>
          <p id='receipt-num'>{factorFullObject.id}</p>
          <p id='shop-title'>{factorFullObject.shop.name}</p>
          <p id='receipt-date'>
            {factorFullObject.created_at.slice(0, 10)}
            <br />
            {factorFullObject.created_at.slice(11, 16)}
          </p>
        </div>

        <div id='mid'>
          <p id='customer-name'>مشتری: {factorFullObject.customer.mobile}</p>
          <p id='deliver-order'>وضعیت: {factorFullObject.status_title}</p>
        </div>
        <hr />
        <div id='table-body'>
          <table id='table'>
            <thead id='table-head'>
              <tr>
                <th>آیتم</th>
                <th>قیمت</th>
                <th>تعداد</th>
                <th>جمع</th>
              </tr>
            </thead>
            {factorFullObject.products.map((items, index) => (
              <tbody id='table-item' key={index}>
                <tr key={items.product.id}>
                  <td id='item-text'>
                    <p>{items.product.name}</p>
                  </td>
                  <td id='item-text'>
                    <p>
                      {items.product.price <= 0
                        ? 'توافقی'
                        : priceRegex(items.product.price, 'withoutCurrency')}
                    </p>
                  </td>
                  <td id='item-text'>
                    <p>{items.count}</p>
                  </td>

                  <td id='item-text'>
                    <p>
                      {items.product.price <= 0
                        ? 'توافقی'
                        : priceRegex(
                            items.product.price * items.count,
                            'withoutCurrency'
                          )}
                    </p>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
        <hr />
        <div id='table-body'>
          <table id='table'>
            <thead id='table-head'>
              <tr>
                <th>بدون تخفیف</th>
                <th id='discount-item'>میزان تخفیف</th>
                <th>پرداختی</th>
              </tr>
            </thead>
            <tbody>
              <tr id='table-item'>
                <td id='item-text'>
                  {factorFullObject.invoice.real_price <= 0
                    ? 'توافقی'
                    : priceRegex(
                        factorFullObject.invoice.real_price,
                        'withoutCurrency'
                      )}
                </td>
                <td id='discount-item'>
                  {factorFullObject.invoice.discount <= 0
                    ? 0
                    : factorFullObject.invoice.discount.toLocaleString('fa-IR')}
                </td>
                <td id='item-text' className='pricePaid'>
                  {factorFullObject.invoice.price <= 0
                    ? 'توافقی'
                    : priceRegex(
                        factorFullObject.invoice.price,
                        'withoutCurrency'
                      )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <div>
          {factorFullObject.delivery.deliver_address.address.length !== 0 && (
            <p id='phone-title'>
              آدرس مشترک: {factorFullObject.delivery.deliver_address.address}
            </p>
          )}
          <p id='address-title'>
            تلفن فروشگاه: {factorFullObject.shop.number[0].number}
          </p>
          <p id='phone-title'>
            آدرس فروشگاه: {factorFullObject.shop.full_address.address}
          </p>

          <p id='copyright'>نرم افزار رزگل</p>
        </div>
      </div>
    </div>
  );
}
