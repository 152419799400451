import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  IconButton,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PrismaZoom from "react-prismazoom";

export default function ImageGallery({ show, close, images, classes }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [clientXPosition, setClientXPosition] = useState(0);

  const changeImage = (index) => {
    setCurrentImage(index);
  };

  const handleTouchStart = (position) => {
    setClientXPosition(position);
  };

  return (
    <Dialog open={show} maxWidth={"md"} fullScreen onClose={close}>
      <DialogTitle>
        <Grid container justifyContent="flex-start" alignItems="center">
          <IconButton onClick={close} color="primary" size="medium" edge="end">
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.imageGalleryContainer}>
        <PrismaZoom maxZoom={2}>
          <img
            src={images[currentImage]?.image}
            alt="prdImage"
            className={classes.galleryImgParent}
            onTouchEnd={(event) => {
              if (event.changedTouches[0].clientX - clientXPosition > 0) {
                if (currentImage === images.length - 1) {
                  setCurrentImage(0);
                } else {
                  setCurrentImage(currentImage + 1);
                }
              } else {
                if (currentImage === 0) {
                  setCurrentImage(images.length - 1);
                } else {
                  setCurrentImage(currentImage - 1);
                }
              }
            }}
            onTouchStart={(event) => {
              handleTouchStart(event.touches[0].clientX);
            }}
          />
        </PrismaZoom>
        <Grid container justifyContent="center" alignItems="center">
          <div className={classes.galleryDiv}>
            {images?.map((img, index) => (
              <img
                key={index}
                onClick={() => {
                  changeImage(index);
                }}
                alt={index}
                src={img.image}
                style={{
                  borderRadius: 5,
                  border: currentImage === index ? "2px solid #F47497" : "none",
                  margin: 5,
                  width: 80,
                  height: 80,
                }}
              />
            ))}
          </div>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
