import { combineReducers } from "redux";
import userTypingsReducer from "../reducers/userTypingsReducers";
import appConfigReducers from "../reducers/appConfigsReducers"
import authenticationReducer from "../reducers/authReducers"
import getProductsReducer from "../reducers/productsReducers"
import cartReducer from "../reducers/cartReducers";
import userInfosReducer from "../reducers/userInfosReducers";
import addressReducer from "../reducers/addressReducers";
import orderReducers from "../reducers/orderReducers";

const rootReducers = combineReducers({
    userTypings: userTypingsReducer,
    appConfigs: appConfigReducers,
    auth: authenticationReducer,
    products: getProductsReducer,
    cart: cartReducer,
    userInfos: userInfosReducer,
    mapStates: addressReducer,
    orderStates: orderReducers
})

export default rootReducers