import {
  Drawer,
  Grid,
  CircularProgress,
  Select,
  MenuItem,
  ListItemText,
  Container,
  Chip,
} from '@material-ui/core';
import MaterialButton from './MaterialButton';
import CancelIcon from '@material-ui/icons/Cancel';
import TypoGraphy from './TypoGraphy';
import SingleOrder from '../layout/orders/SingleOrder';
import AddressContainer from '../../redux/containers/AddressContainer';
import InputTextField from './InputTextField';
import { withRouter } from 'react-router-dom';
import ChargeAccountContainer from '../../redux/containers/ChargeAccountContainer';

const InfoDrawerHandler = (props) => {
  const status = props.infoDrawer;
  const drawerHandler = props.drawerHandler;
  const singleOrder = props.userInfos.singleOrder;
  const singleOrderLoading = props.userInfos.singleOrderLoading;
  const userTypings = props.userTypings;
  const userTypingAction = props.userTypingAction;
  const checkCouponAction = props.checkCouponAction;
  const configs = props.configs;
  const addressId = props.addressId;
  const showAlertDialogAction = props.showAlertDialogAction;
  const alertDialogContentAction = props.alertDialogContentAction;
  const priceInvoice = props.priceInvoice;
  const getUserDetailsAction = props.getUserDetailsAction;
  const handleMsgChipsBtn = props.handleMsgChipsBtn;
  const isCheckingCoupon = props.isCheckingCoupon;

  const closeHandler = () => {
    drawerHandler(false, status.type);
    if (status.type === 'chargeAccount') {
      getUserDetailsAction();
    }
  };

  const handleInputChange = (value, inputType) => {
    if (status.type === 'addMsg') {
      userTypingAction(value, inputType);
    } else if (status.type === 'addCoupon') {
      userTypingAction(value, inputType);
    }
  };

  const validateCoupon = () => {
    checkCouponAction();
    closeHandler();
  };

  const continueAfterAddress = () => {
    if (addressId === null || addressId === undefined) {
      showAlertDialogAction(true);
      alertDialogContentAction(
        'آدرس انتخاب نشده',
        'روی دکمه انتخاب آدرس کلیک کنید',
        'متوجه شدم'
      );
    } else {
      closeHandler();

      window.location.href = '/confirm-basket';
    }
  };

  return (
    <div>
      <Drawer
        PaperProps={{ square: false }}
        anchor={'bottom'}
        open={status.open}
        onClose={closeHandler}
        style={{ zIndex: 300000 }}
      >
        <div
          style={{
            margin: '10px 0 10px 0',
            borderBottom: '1px solid #ccc',
            width: '100%',
          }}
        >
          <Grid container justifyContent='space-around' alignItems='center'>
            <TypoGraphy
              text={
                status.type === 'orderDetails' ? (
                  <>فاکتور</>
                ) : status.type === 'selectAddress' ? (
                  <>انتخاب آدرس</>
                ) : status.type === 'addMsg' ? (
                  <>ثبت توضیحات</>
                ) : status.type === 'addCoupon' ? (
                  <>ثبت کد تخفیف</>
                ) : status.type === 'orderTime' ? (
                  <>بازه زمانی تحویل سفارش</>
                ) : status.type === 'paymentMethod' ? (
                  <>روش پرداخت</>
                ) : status.type === 'chargeAccount' ? (
                  <>شارژ حساب شما</>
                ) : status.type === 'priceInfo' ? (
                  <>فاکتور</>
                ) : null
              }
              align={'right'}
              color={'textPrimary'}
              variant={'subtitle1'}
            />
            <MaterialButton
              btnType={'iconBtn'}
              icon={<CancelIcon />}
              color={'primary'}
              size={'small'}
              edge={'end'}
              onClick={closeHandler}
            />
          </Grid>
        </div>

        {status.type === 'orderDetails' ? (
          <>
            {singleOrderLoading === false ? (
              <SingleOrder singleOrder={singleOrder} />
            ) : (
              <>
                <Grid container justifyContent='center' alignItems='center'>
                  <CircularProgress color='primary' size={40} />
                </Grid>
                <br />
              </>
            )}
          </>
        ) : status.type === 'selectAddress' ? (
          <>
            <AddressContainer />
            <MaterialButton
              text={'ادامه'}
              btnType={'button'}
              variant={'contained'}
              color={'secondary'}
              style={{ margin: 10 }}
              onClick={continueAfterAddress}
            />
          </>
        ) : status.type === 'addMsg' ? (
          <>
            <InputTextField
              multiline={true}
              placeholder={'تایپ کنید یا روی یکی از دکمه ها کلیک کنید'}
              value={userTypings.orderMsg}
              onChange={handleInputChange}
              inputType={'orderMsg'}
              style={{ margin: 10 }}
              variant={'outlined'}
            />
            <div
              style={{
                overflow: 'auto',
                whiteSpace: 'nowrap',
                margin: 10,
              }}
            >
              {addMsgChips.map((chip, index) => (
                <div key={index} style={{ display: 'inline-block' }}>
                  <Chip
                    color='primary'
                    size='small'
                    key={chip.key}
                    label={chip.name}
                    onClick={() => {
                      handleMsgChipsBtn(chip.key);
                    }}
                    style={{ marginLeft: 2 }}
                  />
                </div>
              ))}
            </div>
          </>
        ) : status.type === 'addCoupon' ? (
          <>
            <InputTextField
              label={'کد تخفیف خود را بنویسید'}
              inputType={'copyCoupon'}
              value={userTypings.couponCode}
              onChange={handleInputChange}
              style={{ margin: '0 10px 20px 10px' }}
            />
            <MaterialButton
              btnType={'button'}
              text={'بررسی کد تخفیف'}
              color={'primary'}
              size={'large'}
              onClick={validateCoupon}
              variant={'contained'}
              disabled={isCheckingCoupon}
              style={{ margin: '0 5px 5px 5px' }}
            />
          </>
        ) : status.type === 'orderTime' ? (
          <Grid container justifyContent='space-around' alignItems='center'>
            <div style={{ margin: 20 }}>
              <Select
                value={userTypings.orderDate}
                onChange={(event) => {
                  userTypingAction(event.target.value, 'orderDate');
                }}
              >
                {configs.order_time_day.map((day, index) => (
                  <MenuItem key={index} value={day.key}>
                    {day.value}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div style={{ margin: 20 }}>
              <Select
                onChange={(event) => {
                  userTypingAction(event.target.value, 'orderTime');
                }}
                value={userTypings.orderTime}
              >
                {configs.order_time_zone.map((zone, index) => (
                  <MenuItem key={index} value={zone.key}>
                    {zone.value}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>
        ) : status.type === 'chargeAccount' ? (
          <>
            <ChargeAccountContainer />
          </>
        ) : status.type === 'priceInfo' ? (
          <Container>
            <ListItemText
              primary={
                <TypoGraphy
                  text={'پرداختی'}
                  variant={'subtitle1'}
                  align={'right'}
                  color={'textPrimary'}
                />
              }
              secondary={
                <TypoGraphy
                  text={`${priceInvoice.invoice.price.toLocaleString(
                    'fa-IR'
                  )} تومان`}
                  variant={'subtitle1'}
                  align={'right'}
                  style={{ color: 'red', fontWeight: 'bold' }}
                />
              }
            />

            <ListItemText
              primary={
                <TypoGraphy
                  text={'هزینه ارسال'}
                  variant={'subtitle1'}
                  align={'right'}
                  color={'textPrimary'}
                />
              }
              secondary={
                <TypoGraphy
                  text={`${priceInvoice.invoice.shipping_price.toLocaleString(
                    'fa-IR'
                  )} تومان`}
                  variant={'subtitle1'}
                  align={'right'}
                  style={{ color: 'red', fontWeight: 'bold' }}
                />
              }
            />

            <ListItemText
              primary={
                <TypoGraphy
                  text={'تخفیف'}
                  variant={'subtitle1'}
                  align={'right'}
                  color={'textPrimary'}
                />
              }
              secondary={
                <TypoGraphy
                  text={`${priceInvoice.invoice.discount.toLocaleString(
                    'fa-IR'
                  )} تومان`}
                  variant={'subtitle1'}
                  align={'right'}
                  style={{ color: 'red', fontWeight: 'bold' }}
                />
              }
            />
          </Container>
        ) : null}
      </Drawer>
    </div>
  );
};

const addMsgChips = [
  { name: 'تماس با شماره دیگر', key: 0 },
  { name: 'تماس پیک ارسال', key: 1 },
  { name: 'تحویل گیرنده', key: 2 },
];

export default withRouter(InfoDrawerHandler);
