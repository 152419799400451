import React, { useEffect, useState } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  Button,
  Dialog,
  IconButton,
  Typography,
  Grid,
  Grow,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import RedirectPages from './RedirectPages';
import { useHistory } from 'react-router-dom';

const styles = (theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      left: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

// export interface DialogTitleProps extends WithStyles<typeof styles> {
//   id;
//   children: React.ReactNode;
//   onClose: () => void;
// }

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <Grid container justifyContent='space-between' alignItems='center'>
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant='h6'>{children}</Typography>
        {onClose ? (
          <IconButton
            color='primary'
            className={classes.closeButton}
            onClick={onClose}
          >
            <CancelIcon color='primary' />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    </Grid>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function AlertHandler(props) {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const open = props.open;
  const handleClose = props.showAlertDialogAction;
  const content = props.content;
  const history = useHistory();

  const handleDialogClose = () => {
    handleClose(false);
  };

  const handleDialogButton = () => {
    if (content.textButton === 'برو به صفحه ثبت نام') {
      setShouldRedirect(true);
      handleClose(false);
    } else {
      handleClose(false);
    }
  };

  useEffect(() => {}, [open, content]);

  return (
    <div>
      <Dialog
        style={{
          zIndex: 30000000,
        }}
        transitionDuration={1000}
        TransitionComponent={Grow}
        fullWidth
        maxWidth={'xl'}
        onClose={handleDialogClose}
        open={open}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleDialogClose}>
          {content.title}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{content.content}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogButton}
            color='primary'
            variant='contained'
          >
            {content.textButton}
          </Button>
        </DialogActions>
      </Dialog>
      {shouldRedirect === true && <RedirectPages redirect={'/login'} />}
    </div>
  );
}
