import { useState, useEffect } from "react";
import {
  makeStyles,
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  withStyles
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
// import type { RootState } from '../../../redux/setup/store';
import RedirectPages from "../../helpers/RedirectPages";
import EmojiFoodBeverageOutlinedIcon from "@material-ui/icons/EmojiFoodBeverageOutlined";
import ShoppingBasketOutlinedIcon from "@material-ui/icons/ShoppingBasketOutlined";
import StorefrontOutlinedIcon from "@material-ui/icons/StorefrontOutlined";
import HomeWorkOutlinedIcon from "@material-ui/icons/HomeWorkOutlined";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
// import { useAppSelector } from '../../../redux/setup/hooks';
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: 1000,
  },
  indicator: {
    top: "0px",
  },
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '2 4px',
  },
}))(Badge);

export default function IconTabs() {
  const classes = useStyles();
  const location = useLocation();
  const pathname = location.pathname;
  const params = new URLSearchParams(location.search);
  const categoryId = params.get("category");
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const token = localStorage.getItem("AUTH_JWT");
  const [value, setValue] = useState(pathname.length === 1 ? "/" : pathname);

  const products = useSelector((state) => state.products);
  const { cartFromServer } = products;

  const nav = [
    {
      label: "خانه",
      value: "/",
      icon: <HomeWorkOutlinedIcon />,
    },
    {
      label: "سبدخرید",
      value: "/cart",
      icon: (
        <StyledBadge
          color="secondary"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          badgeContent={
            cartFromServer?.length > 0 ? cartFromServer?.length : null
          }
        >
          <ShoppingBasketOutlinedIcon />

        </StyledBadge>
      )
      ,
    },
    { label: "سفارشات", value: "/orders", icon: <StorefrontOutlinedIcon /> },

    {
      label: "کلاب",
      value: "/club",
      icon: <CardGiftcardIcon />,
    },
  ];

  const handleChange = (event, newValue) => {
    setShouldRedirect(true);
    const value = newValue;
    setValue(value);
    setTimeout(() => {
      setShouldRedirect(false);
    }, 100);
  };

  useEffect(() => {
    setValue(pathname);
  }, [pathname]);

  if (!localStorage.getItem("AUTH_JWT")) {
    return null;
  } else {
    return (
      <>
        {!token ||
          pathname.match("/login") ||
          pathname.match("/payment") ||
          pathname.match("/details") ||
          pathname.match("/product/") ||
          pathname.match("/confirm-basket") ||
          pathname.match("/order/") ||
          pathname.match("/profile") ||
          pathname.match("/paid-order") ||
          pathname.match("/failed-payment") ||
          pathname.match("/charge-account") ||
          pathname.match("/addresses") ||
          pathname.match("/transactions") ||
          pathname.match("/qrcode") ||
          pathname.match("/blog") ||
          // pathname.match("/gift-code") ||
          pathname.match("/search") ? null : (
          <>
            <BottomNavigation
              className={classes.root}
              value={value}
              onChange={handleChange}
            // showLabels
            >
              {nav.map((item, index) => (
                <BottomNavigationAction
                  label={item.label}
                  key={index}
                  value={item.value}
                  icon={item.icon}
                />
              ))}
            </BottomNavigation>

            {shouldRedirect === true && <RedirectPages redirect={value} />}
          </>
        )}
      </>
    );
  }
}
