import {
  makeStyles,
  Theme,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function LoadingPage() {
  const classes = useStyles();

  return (
    <div>
      <Backdrop className={classes.backdrop} open>
        <CircularProgress color='primary' />
      </Backdrop>
    </div>
  );
}
