import { useEffect, useState, ChangeEvent } from "react";
import LoadingSkeleton from "../../helpers/LoadingSkeleton";
import {
  makeStyles,
  Theme,
  createStyles,
  IconButton,
  Grid,
  Container,
  CircularProgress,
  AppBar,
  Toolbar,
  Paper,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  CircularProgressProps,
  Divider,
} from "@material-ui/core";
import TypoGraphy from "../../helpers/TypoGraphy";
import MaterialButton from "../../helpers/MaterialButton";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import FavoriteIcon from "@material-ui/icons/Favorite";
import priceRegex from "../../../utils/priceRegex";
import CommentsList from "./productDetails/CommentsList";
import GoToPayment from "../orders/GoToPayment";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import emptyImage from "../../../static/image/emptyImage.svg";
import Carousel from "react-material-ui-carousel";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import ShareIcon from "@material-ui/icons/Share";
import { useHistory } from "react-router-dom";
import longStringRegex from "../../../utils/longStringsRegex";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import emptyComment from "../../../static/image/emptyComment.svg";
import emptyDescription from "../../../static/image/emptyDescription.svg";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { successToast } from "../../../utils/toastHandler";
import LoadingPage from "../../helpers/LoadingPage";
import soldOutIcon from "../../../static/image/soldOut.png";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: 400,
      flexGrow: 1,
    },
    header: {
      display: "flex",
      alignItems: "center",
      height: 50,
      paddingLeft: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
    },
    img: {
      height: 255,
      maxWidth: 400,
      overflow: "hidden",
      display: "block",
      width: "100%",
    },
    carouselRoot: {
      flexGrow: 1,
    },
    emptyCartImage: {
      width: 100,
      height: 100,
      margin: theme.spacing(0, 1, 1, 1),
    },
    carouselImg: {
      height: 200,
      width: "100%",
      objectFit: "cover",
    },
    favoriteBtn: {
      position: "absolute",
      right: theme.spacing(2),
      top: theme.spacing(0.5),
      zIndex: 30000,
    },
    speedDial: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    appBar: {
      flexGrow: 1,
      marginBottom: theme.spacing(7.1),
    },
    propertyTab: {
      flexGrow: 1,
    },
    divider: {
      margin: theme.spacing(1),
      height: theme.spacing(1),
    },
  })
);

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        value={100}
        style={{ color: "#cccccc", position: "absolute" }}
      />
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
          style={{
            fontSize: 15,
            fontWeight: "bold",
          }}
        >{`${Math.round(props.realValue)}`}</Typography>
      </Box>
    </Box>
  );
}

const SingleProductParent = (props) => {
  const getProductDetailsAction = props.getProductDetailsAction;
  const skeletonLoadingStatusAction = props.skeletonLoadingStatusAction;
  const singleProduct = props.singleProduct;
  const singleProductLoading = props.singleProductLoading;
  const classes = useStyles();
  const userTypingAction = props.userTypingAction;
  const getCommentsAction = props.getCommentsAction;
  const commentsLoading = props.commentsLoading;
  const commentsList = props.commentsList;
  const actionDialogHandler = props.actionDialogHandler;
  // const [itemQuantity, setItemQuantity] = useState(0);
  const [isIncludedInFavorites, setIsIncludedInFavorites] = useState(false);
  const addToFavoritesAction = props.addToFavoritesAction;
  const getUserFavoritesAction = props.getUserFavoritesAction;
  const addCartAction = props.addCartAction;
  const getCartAction = props.getCartAction;
  const cartFromServer = props.cartFromServer;
  const isCartReceived = props.isCartReceived;
  const favorites = props.favorites;
  const favoritesLoading = props.favoritesLoading;
  const removeCartAction = props.removeCartAction;
  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const [productTabValue, setProductTabValue] = useState("description");
  let role = "guest";
  if (props.auth.authData.user) {
    role = props.auth.authData.user.role;
  }
  const checkQtyInCart = props.checkQtyInCart;
  const history = useHistory();
  const alertDialogContentAction = props.alertDialogContentAction;
  const showAlertDialogAction = props.showAlertDialogAction;
  const deleteFromFavoritesAction = props.deleteFromFavoritesAction;
  const productTabs = [
    { name: "توضیحات", value: "description" },
    { name: "مشخصات", value: "properties" },
    { name: "دیدگاه", value: "comments" },
  ];
  const isInFavorites = props.isInFavorites;
  const handleProductTabChange = (event, newValue) => {
    setProductTabValue(newValue);
    window.scrollTo(0, document.body.scrollHeight);
  };
  const [showLoading, setShowLoading] = useState(false);
  const itemQuantity = props.itemQuantity;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    userTypingAction(
      window.location.pathname.slice(9, 1000000),
      "singleProductId"
    );
    skeletonLoadingStatusAction("productDetails");
    getCommentsAction();
    getProductDetailsAction();
    if (localStorage.getItem("AUTH_JWT")) {
      if (role !== "guest") {
        getCartAction();
      }
    }
  }, []);

  const increaseItem = () => {
    if (role === "guest") {
      alertDialogContentAction(
        "دسترسی ندارید",
        "برای دسترسی به این بخش باید ثبت نام کنید",
        "برو به صفحه ثبت نام"
      );
      showAlertDialogAction(true);
    } else {
      addCartAction(singleProduct.id);
    }
  };

  const requestService = () => {
    if (role === "guest") {
      alertDialogContentAction(
        "دسترسی ندارید",
        "برای دسترسی به این بخش باید ثبت نام کنید",
        "برو به صفحه ثبت نام"
      );
      showAlertDialogAction(true);
    } else {
      addCartAction(singleProduct.id);
      setShowLoading(true);
      setTimeout(() => {
        if (isCartReceived === false) {
          history.push("/confirm-basket");
          setShowLoading(false);
        }
      }, 2000);
    }
  };

  const decreaseItem = () => {
    removeCartAction(singleProduct.id);
  };

  const handleSpeedDialOpen = () => {
    setOpenSpeedDial(true);
  };

  const handleSpeedDialClose = () => {
    setOpenSpeedDial(false);
  };

  return (
    <div>
      {showLoading === true && <LoadingPage />}
      {singleProductLoading === false ? (
        <div>
          <div className={classes.appBar}>
            <AppBar position="fixed" color={"inherit"} elevation={1}>
              <Toolbar>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <TypoGraphy
                    text={longStringRegex(singleProduct.name)}
                    color={"textSecondary"}
                    variant={"overline"}
                    align={"center"}
                  />

                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                </Grid>
              </Toolbar>
            </AppBar>
          </div>
          {singleProduct.images.length === 0 ? (
            <img
              className={classes.carouselImg}
              src={emptyImage}
              alt={"carouselImg"}
            />
          ) : (
            <>
              {singleProduct.images.length > 1 ? (
                <Carousel
                  navButtonsAlwaysVisible
                  animation={"fade"}
                  timeout={300}
                  swipe
                >
                  {singleProduct.images.map((img, index) => (
                    <div key={index}>
                      <img
                        className={classes.carouselImg}
                        src={img.image}
                        alt={"carouselImg"}
                        onError={(event) => {
                          event.target.src = emptyImage;
                        }}
                      />
                    </div>
                  ))}
                </Carousel>
              ) : (
                <img
                  className={classes.carouselImg}
                  src={singleProduct.image}
                  alt={"carouselImg"}
                  onError={(event) => {
                    event.target.src = emptyImage;
                  }}
                />
              )}
            </>
          )}

          <TypoGraphy
            text={singleProduct.name}
            align={"center"}
            variant={"h6"}
            color={"textPrimary"}
            gutterBottom={true}
            style={{ width: "100%" }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              overflow: "hidden",
              margin: "0px 15px 0px 15px",
            }}
          >
            <IconButton
              onClick={() => {
                if (navigator.share) {
                  navigator.share({
                    title: `از محصول "${singleProduct.name}" در رزگل دیدن کنید`,
                    url: window.location.href,
                  });
                }
              }}
              edge="start"
              color="primary"
              size="small"
            >
              <ShareIcon />
            </IconButton>
            <Container>
              {singleProduct.limit === 0 ? (
                <Grid container justifyContent="center" alignItems="centeer">
                  <img src={soldOutIcon} alt="soldOut" width="40" height="50" />
                </Grid>
              ) : (
                <>
                  {singleProduct.limit === itemQuantity ? (
                    <Typography
                      align="center"
                      variant="subtitle2"
                      color="textPrimary"
                    >
                      دکمه تکمیل خرید را بزنید
                    </Typography>
                  ) : (
                    <>
                      {itemQuantity === 0 ? (
                        <>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            {singleProduct.limit === 1 ? (
                              <>
                                <MaterialButton
                                  disabled={
                                    isCartReceived === true ? true : false
                                  }
                                  style={{ marginTop: 5, marginBottom: 5 }}
                                  btnType={"button"}
                                  color={"secondary"}
                                  size={"small"}
                                  onClick={requestService}
                                  text={"درخواست"}
                                  variant={"contained"}
                                />
                              </>
                            ) : (
                              <MaterialButton
                                disabled={
                                  isCartReceived === true ? true : false
                                }
                                style={{ marginTop: 5, marginBottom: 5 }}
                                btnType={"button"}
                                color={"secondary"}
                                size={"small"}
                                onClick={increaseItem}
                                text={"افزودن به سبدخرید"}
                                variant={"contained"}
                              />
                            )}
                          </Grid>
                        </>
                      ) : (
                        <>
                          {singleProduct.limit !== 1 && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <MaterialButton
                                disabled={
                                  isCartReceived === true ? true : false
                                }
                                btnType={"iconBtn"}
                                color={"primary"}
                                size={"medium"}
                                onClick={increaseItem}
                                icon={<AddCircleOutlineOutlinedIcon />}
                              />
                              {isCartReceived === false ? (
                                <TypoGraphy
                                  text={itemQuantity}
                                  align={"center"}
                                  color={"textPrimary"}
                                  variant={"subtitle1"}
                                />
                              ) : (
                                <CircularProgress
                                  size={15}
                                  color={"secondary"}
                                />
                              )}

                              <MaterialButton
                                disabled={
                                  isCartReceived === true ? true : false
                                }
                                btnType={"iconBtn"}
                                color={"inherit"}
                                size={"medium"}
                                onClick={decreaseItem}
                                icon={<RemoveCircleOutlineIcon />}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Container>
            <>
              {isInFavorites === false ? (
                <IconButton
                  disabled={favoritesLoading === true ? true : false}
                  onClick={() => {
                    if (role === "guest") {
                      alertDialogContentAction(
                        "دسترسی ندارید",
                        "برای دسترسی به این بخش باید ثبت نام کنید",
                        "برو به صفحه ثبت نام"
                      );
                      showAlertDialogAction(true);
                    } else {
                      addToFavoritesAction();
                    }
                  }}
                  size="small"
                  edge="start"
                  color="primary"
                >
                  <FavoriteBorderIcon />
                </IconButton>
              ) : (
                <IconButton
                  disabled={favoritesLoading === true ? true : false}
                  onClick={() => {
                    if (role === "guest") {
                      alertDialogContentAction(
                        "دسترسی ندارید",
                        "برای دسترسی به این بخش باید ثبت نام کنید",
                        "برو به صفحه ثبت نام"
                      );
                      showAlertDialogAction(true);
                    } else {
                      deleteFromFavoritesAction();
                    }
                  }}
                  size="small"
                  edge="start"
                  color="primary"
                >
                  <FavoriteIcon />
                </IconButton>
              )}
            </>
          </div>

          <Container style={{ marginTop: 5 }}>
            <Grid
              container
              justifyContent={"space-around"}
              alignItems={"baseline"}
            >
              {singleProduct.price <= 0 ? (
                <TypoGraphy
                  text={"توافقی"}
                  align={"center"}
                  color={"textPrimary"}
                  style={{ fontWeight: "bold", fontSize: 18, margin: 5 }}
                />
              ) : (
                <>
                  {singleProduct.discount_percent !== 0 && (
                    <div>
                      <TypoGraphy
                        text={`${singleProduct.discount_percent}%`}
                        align={"center"}
                        variant={"subtitle1"}
                        color={"textPrimary"}
                        style={{
                          border: "1px solid #FF3368",
                          width: "fit-content",
                          height: "fit-content",
                          padding: 1,
                          backgroundColor: "#800021",
                          borderRadius: 5,
                          color: "white",
                        }}
                      />
                      <TypoGraphy
                        text={priceRegex(
                          singleProduct.real_price,
                          "withCurrency"
                        )}
                        align={"center"}
                        variant={"subtitle1"}
                        color={"textSecondary"}
                        style={{
                          textDecoration: "line-through",
                          opacity: 0.8,
                        }}
                      />
                    </div>
                  )}
                  <TypoGraphy
                    text={priceRegex(singleProduct.price, "withCurrency")}
                    align={"center"}
                    color={"textPrimary"}
                    style={{ fontWeight: "bold", fontSize: 18, margin: 5 }}
                  />
                </>
              )}
            </Grid>
          </Container>

          <SpeedDial
            className={classes.speedDial}
            ariaLabel="SpeedDial tooltip example"
            icon={<SpeedDialIcon />}
            onClose={handleSpeedDialClose}
            onOpen={handleSpeedDialOpen}
            open={openSpeedDial}
          >
            {isInFavorites === false ? (
              <SpeedDialAction
                icon={<FavoriteBorderIcon color="primary" />}
                tooltipTitle={"افزودن به علاقه مندی"}
                tooltipOpen
                onClick={() => {
                  if (role === "guest") {
                    alertDialogContentAction(
                      "دسترسی ندارید",
                      "برای دسترسی به این بخش باید ثبت نام کنید",
                      "برو به صفحه ثبت نام"
                    );
                    showAlertDialogAction(true);
                  } else {
                    addToFavoritesAction();
                    handleSpeedDialClose();
                  }
                }}
              />
            ) : (
              <SpeedDialAction
                icon={<FavoriteIcon color="primary" />}
                tooltipTitle={"حذف از علاقه مندی"}
                tooltipOpen
                onClick={() => {
                  if (role === "guest") {
                    alertDialogContentAction(
                      "دسترسی ندارید",
                      "برای دسترسی به این بخش باید ثبت نام کنید",
                      "برو به صفحه ثبت نام"
                    );
                    showAlertDialogAction(true);
                  } else {
                    deleteFromFavoritesAction();
                    handleSpeedDialClose();
                  }
                }}
              />
            )}

            <div>
              <SpeedDialAction
                icon={<AddShoppingCartIcon color="secondary" />}
                tooltipTitle={"افزودن به سبد خرید"}
                tooltipOpen
                onClick={() => {
                  if (singleProduct.limit === 0) {
                    successToast("متاسفانه موجودی تمام شده است");
                  } else {
                    if (role === "guest") {
                      alertDialogContentAction(
                        "دسترسی ندارید",
                        "برای دسترسی به این بخش باید ثبت نام کنید",
                        "برو به صفحه ثبت نام"
                      );
                      showAlertDialogAction(true);
                    } else {
                      addCartAction(singleProduct.id);
                      handleSpeedDialClose();
                    }
                  }
                }}
              />
            </div>

            <SpeedDialAction
              icon={<ShareIcon style={{ color: "#30354B" }} />}
              tooltipTitle={"اشتراک گذاری صفحه"}
              tooltipOpen
              onClick={() => {
                if (navigator.share) {
                  navigator.share({
                    title: `از محصول "${singleProduct.name}" در رزگل دیدن کنید`,
                    url: window.location.href,
                  });
                }
                handleSpeedDialClose();
              }}
            />
          </SpeedDial>

          <Paper className={classes.propertyTab}>
            <Tabs
              indicatorColor={"primary"}
              textColor={"primary"}
              value={productTabValue}
              onChange={handleProductTabChange}
              centered
            >
              {productTabs.map((tab, index) => (
                <Tab
                  key={index}
                  style={{
                    borderRight:
                      tab.value !== "comments" ? "1px solid #cccccc" : "none",
                    borderLeft:
                      tab.value !== "description"
                        ? "1px solid #cccccc"
                        : "none",
                  }}
                  label={tab.name}
                  value={tab.value}
                />
              ))}
            </Tabs>
          </Paper>

          <div>
            {productTabValue === "description" ? (
              <div
                style={{
                  paddingBottom: 100,
                  marginTop: 10,
                }}
              >
                {singleProduct.description == null ||
                singleProduct.description.length === 0 ? (
                  <div>
                    <Grid container justifyContent="center">
                      <img
                        alt="emptyComment"
                        src={emptyDescription}
                        className={classes.emptyCartImage}
                      />
                    </Grid>
                    <TypoGraphy
                      color={"textSecondary"}
                      variant={"subtitle1"}
                      align={"center"}
                      text={"هیچ توضیحاتی ثبت نشده است"}
                    />
                    <Divider className={classes.divider} />
                  </div>
                ) : (
                  <TypoGraphy
                    text={singleProduct.description}
                    color={"textPrimary"}
                    variant={"subtitle2"}
                    align={"justify"}
                    style={{
                      marginRight: 10,
                      marginLeft: 10,
                      fontWeight: "bold",
                      whiteSpace: "pre-wrap",
                      lineHeight: "1.8em",
                    }}
                  />
                )}
              </div>
            ) : productTabValue === "properties" ? (
              <div
                style={{
                  paddingBottom: 100,
                  marginTop: 10,
                }}
              >
                <List>
                  {singleProduct.properties.length === 0 ? (
                    <div>
                      <Grid container justifyContent="center">
                        <img
                          alt="emptyComment"
                          src={emptyDescription}
                          className={classes.emptyCartImage}
                        />
                      </Grid>
                      <TypoGraphy
                        color={"textSecondary"}
                        variant={"subtitle1"}
                        align={"center"}
                        text={"هیچ مشخصاتی ثبت نشده است"}
                      />
                      <Divider className={classes.divider} />
                    </div>
                  ) : (
                    <>
                      {singleProduct.properties.map((property, index) => (
                        <>
                          {property.group_type === "title" ? (
                            <TypoGraphy
                              text={property.title}
                              color="primary"
                              align="right"
                              style={{
                                fontWeight: "bold",
                                fontSize: 18,
                                marginRight: 12,
                                marginTop: 10,
                              }}
                            />
                          ) : (
                            <ListItem key={index}>
                              <Grid
                                container
                                justify="space-around"
                                alignItems="center"
                              >
                                {property.group_type === "chart" &&
                                property.max != null ? (
                                  <ListItemText
                                    primary={
                                      <TypoGraphy
                                        style={{
                                          fontSize: 15,
                                          fontWeight: "bold",
                                        }}
                                        text={`${property.title} (${property.value_type})`}
                                        align={"right"}
                                        color={"textPrimary"}
                                      />
                                    }
                                  />
                                ) : (
                                  <ListItemText
                                    primary={
                                      <TypoGraphy
                                        style={{
                                          fontSize: 15,
                                          fontWeight: "bold",
                                        }}
                                        text={`${property.title}`}
                                        align={"right"}
                                        color={"textPrimary"}
                                      />
                                    }
                                  />
                                )}

                                {property.group_type === "chart" &&
                                property.max != null ? (
                                  <CircularProgressWithLabel
                                    realValue={property.value}
                                    value={
                                      property.value <= 100
                                        ? property.value
                                        : ((property.value - 0) * 100) /
                                          (property.max - 0)
                                    }
                                  />
                                ) : (
                                  <ListItemText
                                    primary={
                                      <TypoGraphy
                                        style={{
                                          fontSize: 15,
                                          fontWeight: "bold",
                                        }}
                                        align={"left"}
                                        color={"textSecondary"}
                                        text={
                                          property.value +
                                          (property.value_type != null
                                            ? property.value_type
                                            : "")
                                        }
                                      />
                                    }
                                  />
                                )}
                              </Grid>
                            </ListItem>
                          )}
                          {property.group_type !== "title" && <Divider />}
                        </>
                      ))}
                    </>
                  )}
                </List>
              </div>
            ) : (
              <>
                {commentsLoading === true ? (
                  <Grid container justifyContent="center" alignItems="center">
                    <CircularProgress size={20} color="primary" />
                  </Grid>
                ) : (
                  <Paper style={{ paddingBottom: 100 }} elevation={0}>
                    <CommentsList
                      comments={commentsList}
                      actionDialogHandler={actionDialogHandler}
                      role={role}
                      alertDialogContentAction={alertDialogContentAction}
                      showAlertDialogAction={showAlertDialogAction}
                    />
                  </Paper>
                )}
              </>
            )}
          </div>

          {cartFromServer.length !== 0 && <GoToPayment />}
        </div>
      ) : (
        <LoadingSkeleton />
      )}
    </div>
  );
};

export default SingleProductParent;
