import { AppBar, Divider, Tab, Tabs, Typography } from "@material-ui/core";
import React from "react";
import TypoGraphy from "../../../helpers/TypoGraphy";
import { useHistory } from "react-router-dom";
import stringLengthRegex from "../../../../utils/stringLengthRegex";
import emptyImage from "../../../../static/image/emptyImage.svg";
import { getLocalStorage } from "../../../../utils/storageHandler";
import shortStringRegex from "../../../../utils/shortStringRegex";

export default function RecommendedPrds({ classes }) {
  const popularProducts = getLocalStorage("POPULAR_PRODUCTS");

  const tabChangeHandler = (event, newValue) => {
    document.location.href = `/product/${newValue}`;
  };

  return (
    <div>
      <TypoGraphy
        text={"محصولات مشابه"}
        variant={"subtitle2"}
        align={"right"}
        color={"textPrimary"}
        className={classes.recommendTitle}
      />
      <Divider />
      <div
        style={{
          flexGrow: 1,
          width: "100%",
          direction: "ltr !important",
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          selectionFollowsFocus
          onChange={tabChangeHandler}
        >
          {popularProducts?.map((prd, index) => (
            <Tab
              className={classes.recommendPrdBtn}
              value={prd.id}
              icon={
                <img
                  alt={prd.name}
                  src={prd.image}
                  className={classes.recommendPrdImg}
                  onError={(event) => {
                    event.target.src = emptyImage;
                  }}
                />
              }
              key={index}
              label={
                <Typography variant="subtitle2" color="textPrimary">
                  {shortStringRegex(prd.name)}
                </Typography>
              }
            />
          ))}
        </Tabs>
      </div>
    </div>
  );
}
