import { useEffect } from 'react';
import GuestLoginPage from '../../helpers/GuestLoginPage';
import AppBarContainer from '../navigation/AppBarContainer';
import {
  Grid,
  CircularProgress,
  Container,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  CardHeader,
} from '@material-ui/core';
import TypoGraphy from '../../helpers/TypoGraphy';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import emptyTransactions from '../../../static/image/emptyTransactions.svg';

export default function TransactionParent(props) {
  const userTransactions = props.userTransactions;
  const gettingTransactions = props.gettingTransactions;
  const getUserTransaction = props.getUserTransaction;
  let role = 'guest';
  if (props.auth.authData.user) {
    role = props.auth.authData.user.role;
  }

  const toTomanWithZero = (number) => {
    if (number) {
      return `${number.toLocaleString('fa-IR')} تومان`;
    }
  };

  useEffect(() => {
    if (role !== 'guest') {
      getUserTransaction();
    }
  }, []);

  return (
    <div>
      {role !== 'guest' ? (
        <>
          <AppBarContainer />
          {gettingTransactions === false ? (
            <>
              {userTransactions.transactions.length === 0 ? (
                <div style={{ marginTop: 150 }}>
                  <Grid container justifyContent='center'>
                    <img
                      alt='emptyOrders'
                      src={emptyTransactions}
                      style={{
                        width: 100,
                        height: 100,
                        margin: 16,
                      }}
                    />
                  </Grid>
                  <TypoGraphy
                    text={'تراکنشی ثبت نشده است'}
                    align={'center'}
                    variant={'h6'}
                    color={'textSecondary'}
                  />
                  <Divider style={{ margin: 8, height: 8 }} />
                </div>
              ) : (
                <Container
                  style={{
                    position: 'absolute',
                    top: '12%',
                    width: '100%',
                  }}
                >
                  <Card
                    style={{
                      boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.2)',
                    }}
                  >
                    <CardContent>
                      <Grid
                        container
                        justifyContent='space-between'
                        alignItems='baseline'
                      >
                        <TypoGraphy text={'اعتبار شما:'} />
                        <TypoGraphy
                          text={toTomanWithZero(
                            userTransactions.credit !== 0
                              ? userTransactions.credit
                              : '0'
                          )}
                        />
                      </Grid>
                    </CardContent>
                  </Card>
                  <br />
                  <TypoGraphy
                    text={'آخرین تراکنش ها'}
                    variant={'h6'}
                    color={'textPrimary'}
                  />
                  <Divider />
                  <List>
                    {userTransactions.transactions.map((transaction) => (
                      <Card
                        style={{
                          boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.2)',
                          marginBottom: 8,
                          padding: 5,
                          borderRadius: 20,
                        }}
                      >
                        <Grid
                          container
                          justifyContent='space-between'
                          alignItems='center'
                        >
                          <CardHeader
                            style={{ padding: '5px 5px 5px 5px' }}
                            title={
                              <TypoGraphy
                                text={
                                  <TypoGraphy
                                    color={'textPrimary'}
                                    text={
                                      transaction.type === 'submit_order'
                                        ? 'ثبت سفارش'
                                        : 'شارژ حساب'
                                    }
                                  />
                                }
                                align={'right'}
                              />
                            }
                            subheader={
                              <TypoGraphy
                                text={`${transaction.created_at.slice(0, 11)}`}
                                align={'right'}
                              />
                            }
                          />
                          <div>
                            {transaction.is_income === false ? (
                              <TypoGraphy
                                text={`-${toTomanWithZero(transaction.price)}`}
                                variant='subtitle2'
                                style={{
                                  backgroundColor: '#ff9c9c',
                                  borderRadius: '20px',
                                  padding: '1px 10px 1px 10px',
                                  color: '#8c0000',
                                }}
                              />
                            ) : (
                              <TypoGraphy
                                text={`+${toTomanWithZero(transaction.price)}`}
                                variant='subtitle2'
                                style={{
                                  backgroundColor: '#9cffa5',
                                  borderRadius: '20px',
                                  padding: '1px 10px 1px 10px',
                                  color: '#005408',
                                }}
                              />
                            )}
                          </div>
                        </Grid>
                      </Card>
                    ))}
                  </List>
                </Container>
              )}
            </>
          ) : (
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              style={{ marginTop: 200 }}
            >
              <CircularProgress color='primary' size={80} />
            </Grid>
          )}
        </>
      ) : (
        <>
          <AppBarContainer />
          <div className='guestLoginPage'>
            <GuestLoginPage />
          </div>
        </>
      )}
    </div>
  );
}
