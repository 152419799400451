import {
  makeStyles,
  Theme,
  Grid,
  Divider,
  Container,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Button,
  CardActions,
  Typography,
} from '@material-ui/core';
import TypoGraphy from '../../helpers/TypoGraphy';
import priceRegex from '../../../utils/priceRegex';
import { successToast } from '../../../utils/toastHandler';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useState, useEffect } from 'react';
import LoadingSkeleton from '../../helpers/LoadingSkeleton';
import AppBarContainer from '../navigation/AppBarContainer';
import withAuth from '../../../utils/withAuth';
import emptyCoupons from '../../../static/image/emptyCoupons.svg';
import ScrollToTop from '../../helpers/ScrollToTop';
import emptyCart from "../../../static/image/emptyCart.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(8),
  },
  emptyCartImage: {
    width: 100,
    height: 100,
    margin: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(1),
    height: theme.spacing(1),
  },
  cardRoot: {
    marginBottom: theme.spacing(2),
  },
  card: {
    overflow: 'hidden',
    width: '100%',
    borderRadius: theme.spacing(1),
    boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.2)',
    // border: '1px solid rgba(254,114,76,0.5)',
    marginTop: theme.spacing(2),
  },
  img: {
    marginLeft: theme.spacing(1),
    objectFit: 'cover',
  },
  infoFlex: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
  },
  dotted: {
    border: '1px dashed #ccc',
  },
}));

const ShipClub = (props) => {
  const coupons = props.userInfos.userCoupons;
  const userTypingAction = props.userTypingAction;
  const userTypings = props.userTypings;
  const skeletonLoadingStatusAction = props.skeletonLoadingStatusAction;
  const getUserCouponsAction = props.getUserCouponsAction;
  const loading = props.loading;
  const userInfos = props.userInfos;
  const classes = useStyles();

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    skeletonLoadingStatusAction('listSkeleton');
    getUserCouponsAction();
  }, []);

  return (
    <div className={classes.root}>
      {userInfos.couponsLoading === true ? (
        <LoadingSkeleton />
      ) : (
        <>
          {/* <AppBarContainer /> */}
          {coupons.length === 0 ? (
            <div style={{ marginTop: 200 }}>
              <Grid container justifyContent='center'>
                <img
                  alt='emptyCoupon'
                  src={emptyCoupons}
                  className={classes.emptyCartImage}
                />
              </Grid>
              <TypoGraphy
                text={'کوپن تخفیفی برای شما ثبت نشده'}
                variant={'h6'}
                align={'center'}
                color={'textPrimary'}
              />
              <Divider className={classes.divider} />
            </div>
          ) : (
            <div className={classes.cardRoot}>
              {coupons.map((coupon, index) => (
                <Container key={index}>
                  <Card className={classes.card} key={coupon.id}>
                    <CardHeader
                      style={{ marginRight: 0, paddingRight: 0 }}
                      avatar={
                        <Avatar
                          src={coupon.image}
                          alt={'orderPic'}
                          className={classes.img}
                        />
                      }
                      title={
                        <TypoGraphy
                          text={coupon.name}
                          variant={'subtitle1'}
                          align={'right'}
                          color={'textPrimary'}
                        />
                      }
                      subheader={ 
                          <TypoGraphy
                            text={`میزان تخفیف: ${priceRegex(
                              coupon.value,
                              'withCurrency'
                            )}`}
                            variant={'subtitle2'}
                            align={'right'}
                            color={'primary'}
                          />
                      }
                      action={
                        <TypoGraphy
                          text={coupon.end_date?.slice(0, 10)}
                          variant={'subtitle2'}
                          align={'right'}
                          color={'textSecondary'}
                        />
                      }
                    />
                    <Typography
                      variant={'subtitle2'}
                      align={'center'}
                      color={'textSecondary'}
                      gutterBottom
                    >
                      {coupon.description?.split('-').map((item) => (
                        <Typography
                          variant="subtitle1"
                          color="textPrimary"
                          align="center"
                        >
                          {item}
                        </Typography>
                      ))}

                    </Typography>
                    <hr className={classes.dotted} />
                    <CardActions>
                      <Grid
                        container
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <TypoGraphy
                          text={coupon.code}
                          variant={'subtitle2'}
                          align={'right'}
                          style={{
                            border: '1px dotted #4d4d4d',
                            padding: 3,
                          }}
                        />
                        <CopyToClipboard
                          text={coupon.code}
                          onCopy={() => setCopied(true)}
                        >
                          <Button
                            size={'small'}
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => {
                              successToast('کد تخفیف کپی شد');
                              userTypingAction(coupon.code, 'copyCoupon');
                            }}
                          >
                            کپی کردن
                          </Button>
                        </CopyToClipboard>
                      </Grid>
                    </CardActions>
                  </Card>
                </Container>
              ))}
            </div>
          )}
          <ScrollToTop />
        </>
      )}
    </div>
  );
};

export default ShipClub;
