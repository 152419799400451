import { ACTION_CONSTANTS } from '../constants/actionConstants';

export default function orderReducers(state = {}, action) {
  const payload = action.payload;
  switch (action.type) {
    case ACTION_CONSTANTS.GET_PRICE_REQUEST:
      return {
        ...state,
        requestingPrice: true,
      };
    case ACTION_CONSTANTS.GET_PRICE_SUCCESS:
      return {
        ...state,
        priceInvoice: payload.data,
        requestingPrice: false,
      };
    case ACTION_CONSTANTS.REQUEST_ONLINE_PAYMENT_REQUEST:
      return {
        ...state,
        payBtnLoading: true,
      };
    case ACTION_CONSTANTS.REQUEST_ONLINE_PAYMENT_SUCCESS:
      return {
        ...state,
        payBtnLoading: false,
      };
    case ACTION_CONSTANTS.REQUEST_PAYMENT_REQUEST:
      return {
        ...state,
        payBtnLoading: true,
      };
    case ACTION_CONSTANTS.REQUEST_PAYMENT_SUCCESS:
      return {
        ...state,
        payBtnLoading: false,
        paymentUrl: payload.data.payment_url,
        urlReceived: payload.success,
      };
    case ACTION_CONSTANTS.SUBMIT_ORDER_REQUEST:
      return {
        ...state,
        submittingOrder: true,
      };
    case ACTION_CONSTANTS.SUBMIT_ORDER_SUCCESS:
      return {
        ...state,
        submittingOrder: false,
        submitOrderResponse: payload.data,
        orderSubmitted: payload.success,
      };
    case ACTION_CONSTANTS.IMAGE_COMPRESSION_LOADING:
      return {
        ...state,
        imageCompressionLoading: true,
      };
    case ACTION_CONSTANTS.IMAGE_COMPRESSION:
      return {
        ...state,
        imageCompressionLoading: false,
        compressedImageFormData: payload,
      };
    case ACTION_CONSTANTS.CANCEL_IMAGE_UPLOAD:
      return {
        ...state,
        imageCompressionLoading: false,
        compressedImageFormData: payload,
        hasUploadedImage: false,
      };
    case ACTION_CONSTANTS.UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        imageCompressionLoading: true,
      };
    case ACTION_CONSTANTS.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        uploadedImageId: payload.data,
        hasUploadedImage: true,
        imageCompressionLoading: false,
      };
    case ACTION_CONSTANTS.SHOULD_UPLOAD_PHOTO:
      return {
        ...state,
        shouldUploadImage: payload,
      };

    case ACTION_CONSTANTS.CHECK_LOCATION_ZONE:
      return {
        ...state,
        zoneMsg: payload.msg,
        isInZone: payload.isInZone,
      };
    default:
      return state;
  }
}
