import MapComponent from './MapComponent';
import {
  List,
  ListItemText,
  makeStyles,
  createStyles,
  Theme,
  Drawer,
  Divider,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import TypoGraphy from '../../helpers/TypoGraphy';
import MaterialButton from '../../helpers/MaterialButton';
import InputTextField from '../../helpers/InputTextField';
import { useState, useEffect } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import priceRegex from '../../../utils/priceRegex';
import emptyAddress from '../../../static/image/emptyAddress.svg';
import longStringRegex from '../../../utils/longStringsRegex';
import AppBarContainer from '../navigation/AppBarContainer';
import GuestLoginPage from '../../helpers/GuestLoginPage';
import { useGeolocation } from 'react-use';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: window.location.pathname.match('/checkout')
        ? theme.spacing(0, 1, 0, 1)
        : 0,
    },
    margin: {
      marginTop: theme.spacing(3),
    },
    map: {
      padding: theme.spacing(1),
    },
    addressInputs: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    input: {
      padding: theme.spacing(1),
    },
    listRoot: {
      margin: window.location.pathname.match('/checkout')
        ? theme.spacing(1, 0, 1, 0)
        : theme.spacing(2),
      height: window.location.pathname.match('/checkout') ? 150 : 450,
      overflow: 'auto',
    },
    emptyCartImage: {
      width: 80,
      height: 80,
      // margin: theme.spacing(2),
    },
  })
);

export default function AddressParent(props) {
  // const location = useGeolocation();
  // const stringLocation = JSON.stringify(location);
  // const loc = JSON.parse(stringLocation);
  // const lat = loc.latitude;
  // const lng = loc.longitude;
  const mapStates = props.mapStates;
  const userAddresses = props.userAddresses;
  const mapCenterAction = props.mapCenterAction;
  const markerLatLngAction = props.markerLatLngAction;
  const setAddressIdAction = props.setAddressIdAction;
  const setAddressTitleAction = props.setAddressTitleAction;
  const setAddressAction = props.setAddressAction;
  const setAddressNumber = props.setAddressNumber;
  const addAddressAction = props.addAddressAction;
  const alertDialogContentAction = props.alertDialogContentAction;
  const showAlertDialogAction = props.showAlertDialogAction;
  const getUserAddressesAction = props.getUserAddressesAction;
  const setShippingPriceAction = props.setShippingPriceAction;
  const addressLoading = props.addressLoading;
  const classes = useStyles();
  const pathname = window.location.pathname;
  let role = 'guest';
  if (props.auth.authData.user) {
    role = props.auth.authData.user.role;
  }
  const zoneMsg = props.zoneMsg;
  const isInZone = props.isInZone;
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleAddClick = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleAddAddressClick = () => {
    if (mapStates.addressTitle.length === 0) {
      alertDialogContentAction(
        'دقت کنید',
        'نام آدرس را وارد کنید',
        'متوجه شدم'
      );
      showAlertDialogAction(true);
    } else if (mapStates.fullAddress.length === 0) {
      alertDialogContentAction(
        'دقت کنید',
        'جزئیات آدرس را وارد کنید',
        'متوجه شدم'
      );

      showAlertDialogAction(true);
    } else {
      addAddressAction();
      handleDrawerClose();
    }
  };

  const handleInputChange = (value, inputType) => {
    if (inputType === 'addressTitle') {
      setAddressTitleAction(value);
    } else if (inputType === 'fullAddress') {
      setAddressAction(value);
    } else if (inputType === 'addressNumber') {
      setAddressNumber(value);
    }
  };

  useEffect(() => {
    if (role !== 'guest') {
      getUserAddressesAction();
    }
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        markerLatLngAction(lat, lng);
        mapCenterAction([lat, lng]);
      });
    } else {
      alert('دسترسی به لوکیشن را فعال کنید');
    }
  }, []);

  return (
    <div className={classes.root}>
      {role !== 'guest' ? (
        <>
          {addressLoading === false ? (
            <>
              {pathname.match('/addresses') ? (
                <>
                  <AppBarContainer />

                  <>
                    <div
                      style={{
                        position: 'absolute',
                        top: '10%',
                        width: '100%',
                      }}
                    >
                      <List>
                        {userAddresses.length === 0 ? (
                          <div style={{ marginTop: 150 }}>
                            <Grid container justifyContent='center'>
                              <img
                                alt='emptyCart'
                                src={emptyAddress}
                                className={classes.emptyCartImage}
                              />
                            </Grid>
                            <TypoGraphy
                              align={'center'}
                              text={'آدرسی ثبت نشده است'}
                              variant={'subtitle1'}
                              color={'primary'}
                            />
                          </div>
                        ) : (
                          <div className={classes.listRoot}>
                            {userAddresses.map((address, index) => (
                              <ListItemText
                                key={index}
                                secondary={
                                  <TypoGraphy
                                    align={'right'}
                                    text={`هزینه ارسال: ${priceRegex(
                                      address.price,
                                      'withCurrency'
                                    )}`}
                                    variant={'body2'}
                                    color={'textSecondary'}
                                  />
                                }
                                primary={
                                  <>
                                    <TypoGraphy
                                      align={'right'}
                                      text={`${
                                        address.title
                                      }، ${longStringRegex(address.address)}`}
                                      variant={'subtitle2'}
                                      color={'textPrimary'}
                                    />
                                  </>
                                }
                              />
                            ))}
                          </div>
                        )}
                        <div style={{ borderTop: '1px solid #ccc' }} />
                      </List>
                    </div>
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '2%',
                        transform: 'translateY(-50%, -50%)',
                        width: '100%',
                        padding: '0px 5px 0px 5px',
                      }}
                    >
                      <MaterialButton
                        fullWidth={true}
                        text={'ثبت آدرس جدید'}
                        variant={'contained'}
                        color={'primary'}
                        btnType={'button'}
                        onClick={handleAddClick}
                      />
                    </div>
                    <Drawer
                      PaperProps={{ square: false }}
                      anchor={'bottom'}
                      open={openDrawer}
                      onClose={handleDrawerClose}
                    >
                      <Grid
                        container
                        justifyContent='space-around'
                        alignItems='center'
                        style={{ margin: '10px 0 10px 0' }}
                      >
                        <TypoGraphy
                          align={'right'}
                          text={'ثبت آدرس جدید'}
                          variant={'subtitle1'}
                        />
                        <MaterialButton
                          size={'small'}
                          edge={'end'}
                          color={'primary'}
                          onClick={handleDrawerClose}
                          btnType={'iconBtn'}
                          icon={<CancelIcon />}
                        />
                      </Grid>
                      <Divider />
                      <div className={classes.map}>
                        <MapComponent
                          mapStates={mapStates}
                          mapCenterAction={mapCenterAction}
                          markerLatLngAction={markerLatLngAction}
                        />
                      </div>
                      {zoneMsg.length !== 0 && isInZone === false && (
                        <Typography
                          style={{
                            color: 'red',
                            fontWeight: 'bold',
                          }}
                          variant='subtitle1'
                          align='center'
                        >
                          {zoneMsg}
                        </Typography>
                      )}
                      <div className={classes.addressInputs}>
                        <InputTextField
                          placeholder={'نام آدرس(اجباری)'}
                          variant={'outlined'}
                          size={'small'}
                          fullWidth={true}
                          className={classes.input}
                          value={mapStates.addressTitle}
                          onChange={handleInputChange}
                          inputType={'addressTitle'}
                        />
                        <InputTextField
                          placeholder={'آدرس کامل(اجباری)'}
                          variant={'outlined'}
                          size={'small'}
                          fullWidth={true}
                          className={classes.input}
                          value={mapStates.fullAddress}
                          onChange={handleInputChange}
                          inputType={'fullAddress'}
                        />
                        <InputTextField
                          placeholder={'تلفن آدرس(اختیاری)'}
                          variant={'outlined'}
                          size={'small'}
                          fullWidth={true}
                          className={classes.input}
                          value={mapStates.addressNumber}
                          onChange={handleInputChange}
                          inputType={'addressNumber'}
                          type={'number'}
                        />
                        <MaterialButton
                          style={{
                            margin: 5,
                            padding: 5,
                          }}
                          size={'large'}
                          text={'ثبت'}
                          variant={'contained'}
                          color={'secondary'}
                          btnType={'button'}
                          onClick={handleAddAddressClick}
                          fullWidth={true}
                        />
                        <br />
                      </div>
                    </Drawer>
                  </>
                </>
              ) : (
                <>
                  <List>
                    <ListItemText
                      primary={
                        <TypoGraphy
                          align={'right'}
                          text={'لیست آدرس ها'}
                          variant={'h6'}
                        />
                      }
                    />
                    <div style={{ borderBottom: '1px solid #ccc' }} />
                    {userAddresses.length === 0 ? (
                      <>
                        <Grid container justifyContent='center'>
                          <img
                            alt='emptyCart'
                            src={emptyAddress}
                            className={classes.emptyCartImage}
                          />
                        </Grid>
                        <TypoGraphy
                          align={'center'}
                          text={'آدرسی ثبت نشده است'}
                          variant={'subtitle1'}
                          color={'primary'}
                        />
                      </>
                    ) : (
                      <div className={classes.listRoot}>
                        {userAddresses.map((address, index) => (
                          <RadioGroup
                            row
                            key={index}
                            value={mapStates.addressId}
                            onChange={(event) => {
                              setAddressIdAction(parseInt(event.target.value));
                              if (parseInt(event.target.value) === address.id) {
                                setShippingPriceAction(address.price);
                              }
                            }}
                          >
                            <FormControlLabel
                              value={address.id}
                              control={<Radio />}
                              label={
                                <>
                                  <TypoGraphy
                                    align={'right'}
                                    text={`${address.title}، ${longStringRegex(
                                      address.address
                                    )}`}
                                    variant={'subtitle2'}
                                    color={'textPrimary'}
                                  />

                                  <TypoGraphy
                                    align={'right'}
                                    text={`هزینه ارسال: ${priceRegex(
                                      address.price,
                                      'withCurrency'
                                    )}`}
                                    variant={'body2'}
                                    color={'textSecondary'}
                                  />
                                </>
                              }
                            />
                          </RadioGroup>
                        ))}
                      </div>
                    )}
                    <div style={{ borderTop: '1px solid #ccc' }} />
                  </List>

                  <MaterialButton
                    fullWidth={true}
                    text={'ثبت آدرس جدید'}
                    variant={'contained'}
                    color={'primary'}
                    btnType={'button'}
                    onClick={handleAddClick}
                  />

                  <Drawer
                    PaperProps={{ square: false }}
                    anchor={'bottom'}
                    open={openDrawer}
                    onClose={handleDrawerClose}
                  >
                    <Grid
                      container
                      justifyContent='space-around'
                      alignItems='center'
                      style={{ margin: '10px 0 10px 0' }}
                    >
                      <TypoGraphy
                        align={'right'}
                        text={'ثبت آدرس جدید'}
                        variant={'subtitle1'}
                      />
                      <MaterialButton
                        size={'small'}
                        edge={'end'}
                        color={'primary'}
                        onClick={handleDrawerClose}
                        btnType={'iconBtn'}
                        icon={<CancelIcon />}
                      />
                    </Grid>
                    <Divider />
                    <div className={classes.map}>
                      <MapComponent
                        mapStates={mapStates}
                        mapCenterAction={mapCenterAction}
                        markerLatLngAction={markerLatLngAction}
                      />
                    </div>
                    {zoneMsg.length !== 0 && isInZone === false && (
                      <Typography
                        variant='subtitle2'
                        align='center'
                        style={{
                          fontWeight: 'bold',
                          color: 'red',
                        }}
                      >
                        {zoneMsg}
                      </Typography>
                    )}
                    <div className={classes.addressInputs}>
                      <InputTextField
                        placeholder={'نام آدرس(اجباری)'}
                        variant={'outlined'}
                        size={'small'}
                        fullWidth={true}
                        className={classes.input}
                        value={mapStates.addressTitle}
                        onChange={handleInputChange}
                        inputType={'addressTitle'}
                      />
                      <InputTextField
                        placeholder={'آدرس کامل(اجباری)'}
                        variant={'outlined'}
                        size={'small'}
                        fullWidth={true}
                        className={classes.input}
                        value={mapStates.fullAddress}
                        onChange={handleInputChange}
                        inputType={'fullAddress'}
                      />
                      <InputTextField
                        placeholder={'تلفن آدرس(اختیاری)'}
                        variant={'outlined'}
                        size={'small'}
                        fullWidth={true}
                        className={classes.input}
                        value={mapStates.addressNumber}
                        onChange={handleInputChange}
                        inputType={'addressNumber'}
                        type={'number'}
                      />

                      <MaterialButton
                        style={{
                          margin: 5,
                          padding: 5,
                        }}
                        size={'large'}
                        text={'ثبت'}
                        variant={'contained'}
                        color={'secondary'}
                        btnType={'button'}
                        onClick={handleAddAddressClick}
                        fullWidth={true}
                      />
                      <br />
                    </div>
                  </Drawer>
                </>
              )}
            </>
          ) : (
            <>
              {pathname.match('/addresses') ? (
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%, -50%)',
                    width: '100%',
                  }}
                >
                  <Grid container justifyContent='center' alignItems='center'>
                    <CircularProgress color='primary' size={60} />
                  </Grid>
                </div>
              ) : (
                <Grid container justifyContent='center' alignItems='center'>
                  <CircularProgress color='primary' size={30} />
                </Grid>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <AppBarContainer />
          <div className='guestLoginPage'>
            <GuestLoginPage />
          </div>
        </>
      )}
    </div>
  );
}
