import { Grow, Link, Theme, makeStyles } from "@material-ui/core";

import emptyImage from "../../../static/image/emptyImage.svg";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    margin: "0.7rem 0.7rem 0.8rem 0.5rem",
    borderRadius: theme.spacing(1),
    display: "flex",
    boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.2)",
  },
  cardMedia: {
    height: 70,
    cursor: "pointer",
    objectFit: "cover",
    borderRadius: "0px !important",
  },
  name: {
    fontSize: 14,
  },

  chip: {
    // boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.2)",
    // border: "1px solid #ffabc1",
    height: 70,
    objectFit: "cover",
    // borderRadius: theme.spacing(2),
    borderRadius: "0",
    cursor: "pointer",
  },
  card: {
    height: "max-content",
    width: "max-content",
    borderRadius: theme.spacing(2),
  },
  bottomCard: {
    width: theme.spacing(8),
    borderBottomLeftRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    padding: theme.spacing(0.5),
  },
}));

export default function CategoryProductsList(props) {
  const classes = useStyles();
  const item = props.product;
  const isParent = props.isParent;
  const history = useHistory();

  return (
    <Link
      href={`/?category=${item.id}`}
      underline="none"
      onClick={(event) => {
        event.preventDefault();
      }}
    >
      <Grow in={true} timeout={2000}>
        <div
          className={classes.root}
          onClick={() => {
            history.push(`/?category=${item.id}`);
          }}
        >
          {isParent === true ? (
            <img
              className={classes.chip}
              src={item.image}
              alt="icon"
              onError={(event) => {
                event.target.src = emptyImage;
              }}
            />
          ) : (
            <>
              <img
                className={classes.cardMedia}
                src={item.image}
                alt="icon"
                onError={(event) => {
                  event.target.src = emptyImage;
                  }}
                  style={{borderRadius:"0"}}
              />
            </>
          )}
        </div>
      </Grow>
    </Link>
  );
}
