import { Tabs, Tab } from '@material-ui/core'
import React from 'react'

export default function DetailsTabs({
    tabVal,
    detailsTabsArr,
    handleTabChanger,
    classes
}) {
    return (
        <Tabs
            onChange={handleTabChanger}
            variant="fullWidth"
            centered
            indicatorColor='primary'
            textColor="primary"
            value={tabVal}
            className={classes.tabViewContainer}
        >
            {detailsTabsArr.filter(tab => tab.show === true).map((tb, index) => (
                <Tab
                    key={index}
                    label={tb.title}
                    value={tb.value}
                    icon={tb.icon}

                />
            ))}
        </Tabs>
    )
}
