import Rating from '@material-ui/lab/Rating';

export default function RateDefaultStars({ stars }) {
  return (
    <Rating
      value={stars}
      readOnly
      size="small"
    />
  )
}
