import { Typography } from "@material-ui/core";
import React from "react";
import priceRegex from "../../../../utils/priceRegex";
import LocalGroceryStoreOutlinedIcon from "@material-ui/icons/LocalGroceryStoreOutlined";

export default function PriceContainer({ classes, data }) {
  return (
    <div className={classes.priceGrid}>
      {data?.discount_percent !== 0 && (
        <div className={classes.discountBox}>
          <div>{data.discount_percent}٪</div>
          {/* <div>
                        تخفیف
                    </div> */}
        </div>
      )}

      <div className={classes.priceFrame}>
        {data?.real_price !== data?.price && (
          <div className={classes.realPriceReg}>
            {priceRegex(data?.real_price, "withoutCurrency")}
          </div>
        )}

        <div className={classes.priceReg}>
          {data?.price <= 0
            ? "توافقی"
            : priceRegex(data?.price, "withoutCurrency")}
          &nbsp;
          {!(data?.price <= 0) && (
            <Typography color="textSecondary" variant="overline" align="center">
              تومان
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}
