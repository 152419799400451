import { useState } from 'react';
import {
  makeStyles,
  Card,
  CardActionArea,
  CardMedia,
  Chip,
  Theme,
  Grow,
  Link,
} from '@material-ui/core';
import RedirectPages from '../../helpers/RedirectPages';
import priceRegex from '../../../utils/priceRegex';
import { useDispatch } from 'react-redux';
import {
  userTypingAction,
  getProductDetailsAction,
} from '../../../redux/actions/reduxActions';
import { useHistory } from 'react-router-dom';
import stringLengthRegex from '../../../utils/stringLengthRegex';
import emptyImage from '../../../static/image/emptyImage.svg';
import soldOutIcon from "../../../static/image/soldOut.png"

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(1),
  },
  card: {
    overflow: 'hidden',
    width: theme.spacing(28),
    borderRadius: theme.spacing(2),
    boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.2)',
    // border: '1px solid #9f2600',
  },
  cardMedia: {
    width: '100%',
    objectFit: 'cover',
    backgroundRepeat: 'no-repeat',
    height: theme.spacing(18),
    borderRadius: theme.spacing(2),
  },
  container: {
    position: 'relative',
  },
  nameChip: {
    position: 'absolute',
    right: 15,
    top: 8,
  },
  priceChip: {
    position: 'absolute',
    left: 15,
    bottom: 8,
  },
  soldOutIcon: {
    position: 'absolute',
    left: 15,
    top: 8,
    zIndex: 30000
  }
}));

export default function NewProductsList(props) {
  const classes = useStyles();
  const item = props.product;
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectValue, setRedirectValue] = useState('');

  return (
    <Link
      href={`/product/${item.id}`}
      underline='none'
      onClick={(event) => {
        event.preventDefault();
      }}
    >
      <Grow in={true} timeout={2000}>
        <div
          className={classes.root}
          onClick={() => {
            history.push(`/product/${item.id}`);
          }}
        >
          <Card className={classes.card}>
            <CardActionArea>
              <div className={classes.container}>
                <CardMedia
                  alt='newProducts'
                  className={classes.cardMedia}
                  src={item.image}
                  component='img'
                  onError={(event) => {
                    event.target.src = emptyImage;
                  }}
                />
                {item.limit === 0 &&
                  <img
                    src={soldOutIcon}
                    alt="soldOutIcon"
                    width="30"
                    className={classes.soldOutIcon}
                    height="30"
                  />
                }
                <Chip
                  size='small'
                  color='primary'
                  className={classes.nameChip}
                  label={stringLengthRegex(item.name)}
                />
                <Chip
                  size='small'
                  color='secondary'
                  className={classes.priceChip}
                  label={
                    item.price <= 0
                      ? 'توافقی'
                      : priceRegex(item.price, 'withCurrency')
                  }
                />
              </div>
            </CardActionArea>
          </Card>
        </div>
      </Grow>
      {shouldRedirect === true && <RedirectPages redirect={redirectValue} />}
    </Link>
  );
}
