import {
  Avatar,
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import React from "react";
import priceRegex from "../../../../utils/priceRegex";
import longStringRegex from "../../../../utils/longStringsRegex";

export default function Products({ data, classes, actionDialogHandler }) {
  return (
    <List>
      {data?.products?.map((prd, index) => (
        <ListItem divider key={index}>
          <ListItemAvatar>
            <Avatar src={prd?.product?.image} variant="rounded" />
          </ListItemAvatar>
          <ListItemText
            className={classes.litItmTxt}
            primary={
              <Typography variant="subtitle2" color="textPrimary">
                {longStringRegex(prd?.product?.name)}
              </Typography>
            }
            secondary={
              <div className={classes.totalCountDiv}>
                <Typography variant="overline" color="textSecondary">
                  {prd?.price <= 0
                    ? "توافقی"
                    : priceRegex(prd?.price, "withCurrency")}
                </Typography>
                {prd?.discount > 0 && (
                  <Typography
                    variant="overline"
                    className={classes.discountTxt}
                  >
                    تخفیف:{" "}
                    {prd?.discount <= 0
                      ? "0"
                      : priceRegex(prd?.discount, "withCurrency")}
                  </Typography>
                )}
              </div>
            }
          />
          <div className={classes.totalCountDiv}>
            <div className={classes.countDiv}>
              <Typography
                className={classes.countTxt}
                variant="subtitle2"
                color="textSecondary"
              >
                {prd?.count}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                عدد
              </Typography>
            </div>
            <Typography variant="subtitle2" color="primary">
              {prd?.total <= 0
                ? "توافقی "
                : priceRegex(prd?.total, "withCurrency")}
            </Typography>
          </div>
        </ListItem>
      ))}
      <div
        style={{
          margin: "40px 20px 50px 20px",
        }}
      >
        <Button
          onClick={() => {
            actionDialogHandler(true, "orderFactor");
          }}
          size="small"
          color="secondary"
          variant="contained"
          fullWidth
        >
          مشاهده فاکتور
        </Button>
      </div>
    </List>
  );
}
