import TypoGraphy from './TypoGraphy';
import MaterialButton from './MaterialButton';
import { Container, Grid, Divider } from '@material-ui/core';
import RedirectPages from './RedirectPages';
import { useState } from 'react';
import lockIcon from '../../static/image/lockIcon.svg';

export default function GuestLoginPage() {
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const onGoToLoginSelect = () => {
    setShouldRedirect(true);
  };

  return (
    <Container>
      <Grid container justifyContent='center'>
        <img
          alt='guestIcon'
          src={lockIcon}
          style={{
            width: 100,
            height: 100,
            margin: 16,
          }}
        />
      </Grid>
      <Divider style={{ margin: 8, height: 8 }} />
      <TypoGraphy
        text={'برای استفاده از این بخش ثبت نام کنید'}
        variant={'body1'}
        color={'textPrimary'}
        gutterBottom={true}
        align={'center'}
      />
      <MaterialButton
        btnType={'button'}
        text={'برو به صفحه ثبت نام'}
        variant={'contained'}
        fullWidth={true}
        color={'primary'}
        size={'small'}
        onClick={onGoToLoginSelect}
      />
      {shouldRedirect === true && <RedirectPages redirect={'/login'} />}
    </Container>
  );
}
