import React, { useState, useEffect } from 'react';
import {
    makeStyles,
    withStyles,
    MenuItem,
    FormControl,
    Select,
    InputBase,
    Typography,
    Grid,
    ListItemText,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { userTypingAction } from '../../../redux/actions/reduxActions';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        maxWidth: 20,
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(0,1),
    },
    menuPaper: {
        maxHeight: 200,
    },
}));

export default function BirthdayInput() {
    const typeReducer = useSelector((state) => state.userTypings);
    const { userBirthdayValue } = typeReducer;
    const dispatch = useDispatch();
    const classes = useStyles();
    const [day, setDay] = useState(userBirthdayValue?.slice(8, 10));
    const [month, setMonth] = useState(userBirthdayValue?.slice(5, 7));
    const [year, setYear] = useState(userBirthdayValue?.slice(2, 4));

    const handleDayChange = (event) => {
        setDay(event.target.value);
    };
    const handleMonthChange = (event) => {
        setMonth(event.target.value);
    };
    const handleYearChange = (event) => {
        setYear(event.target.value);
    };
    useEffect(() => {
        if (day?.length !== 0 || month?.length !== 0 || year?.length !== 0) {
            dispatch(userTypingAction(`13${year}-${month}-${day}`, 'userBirthday'));
        }
    }, [day, month, year, dispatch]);

    return (
        <div>

            <ListItemText
                primary={
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                    >
                        تاریخ تولد
                    </Typography>
                }
                secondary={
                    <>
                        {userBirthdayValue?.length > 0 &&
                            <Typography
                                variant="overline"
                                color="primary"
                            >
                                {userBirthdayValue}
                            </Typography>
                        }
                    </>
                }
            />
            <Grid
                container
                justifyContent="center"
                alignItems="center"
            >
                <FormControl className={classes.margin}>
                    <Typography>روز</Typography>
                    <Select
                        MenuProps={{
                            getContentAnchorEl: () => null,
                            classes: { paper: classes.menuPaper },
                        }}
                        value={day}
                        onChange={handleDayChange}
                        input={<BootstrapInput />}
                    >
                        {days.map((day) => (
                            <MenuItem value={day.value}>{day.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl className={classes.margin}>
                    <Typography>ماه</Typography>
                    <Select
                        MenuProps={{
                            getContentAnchorEl: () => null,
                            classes: { paper: classes.menuPaper },
                        }}
                        value={month}
                        onChange={handleMonthChange}
                        input={<BootstrapInput />}
                    >
                        {months.map((month) => (
                            <MenuItem value={month?.value}>{month?.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl className={classes.margin}>
                    <Typography>سال</Typography>
                    <Grid container justifyContent='flex-end' alignItems='baseline'>
                        <BootstrapInput
                            value={year}
                            inputProps={{ maxLength: 2 }}
                            onChange={handleYearChange}
                        />
                        <Typography variant='h6' color='textPrimary'>
                            &nbsp;13
                        </Typography>
                    </Grid>
                </FormControl>
            </Grid>

        </div>
    );
}

const days = [
    { name: 1, value: '01' },
    { name: 2, value: '02' },
    { name: 3, value: '03' },
    { name: 4, value: '04' },
    { name: 5, value: '05' },
    { name: 6, value: '06' },
    { name: 7, value: '07' },
    { name: 8, value: '08' },
    { name: 9, value: '09' },
    { name: 10, value: '10' },
    { name: 11, value: '11' },
    { name: 12, value: '12' },
    { name: 13, value: '13' },
    { name: 14, value: '14' },
    { name: 15, value: '15' },
    { name: 16, value: '16' },
    { name: 17, value: '17' },
    { name: 18, value: '18' },
    { name: 19, value: '19' },
    { name: 20, value: '20' },
    { name: 21, value: '21' },
    { name: 22, value: '22' },
    { name: 23, value: '23' },
    { name: 24, value: '24' },
    { name: 25, value: '25' },
    { name: 26, value: '26' },
    { name: 27, value: '27' },
    { name: 28, value: '28' },
    { name: 29, value: '29' },
    { name: 30, value: '30' },
    { name: 31, value: '31' },
];

const months = [
    { name: 'فروردین', value: '01' },
    { name: 'اردیبهشت', value: '02' },
    { name: 'خرداد', value: '03' },
    { name: 'تیر', value: '04' },
    { name: 'مرداد', value: '05' },
    { name: 'شهریور', value: '06' },
    { name: 'مهر', value: '07' },
    { name: 'آبان', value: '08' },
    { name: 'آذر', value: '09' },
    { name: 'دی', value: '10' },
    { name: 'بهمن', value: '11' },
    { name: 'اسفند', value: '12' },
];
