import { useEffect } from 'react';
import TypoGraphy from '../../helpers/TypoGraphy';
import { getQRToken } from '../../../redux/actions/reduxActions';
import { useDispatch } from 'react-redux';
import { LinearProgress } from '@material-ui/core';

export default function QRCodeScanner({ location }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const id = location.pathname.slice(8, 100000000000000000000000000);
    dispatch(getQRToken(id));
  }, [dispatch, location.pathname]);

  return (
    <div
      style={{
        marginTop: 200,
      }}
    >
      <TypoGraphy
        text={'در حال پردازش'}
        variant='h6'
        color='textPrimary'
        align='center'
      />
      <br />
      <LinearProgress color='primary' />
    </div>
  );
}
