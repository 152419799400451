import InputTextField from "../../helpers/InputTextField";
import MaterialButton from "../../helpers/MaterialButton";
import TypoGraphy from "../../helpers/TypoGraphy";
import {
  Grow,
  Paper,
  Slide,
  Grid,
  Typography,
  Fade,
  TextField,
  Theme,
  makeStyles,
  CircularProgress,
  Button,
  InputAdornment,
  IconButton,
  Box,
} from "@material-ui/core";
import SmsIcon from "@material-ui/icons/Sms";
import AlertHandler from "../../helpers/AlertHandler";
import RedirectPages from "../../helpers/RedirectPages";
import { FormHelperText } from "@material-ui/core";
import { useState, useEffect } from "react";
// import OtpInput from 'react-otp-input';
// import Lottie from 'react-lottie';
import OtpCounter from "./otpCounter";
import loginLogo from "../../../static/image/loginLogo.svg";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseIcon from "@material-ui/icons/Close";
import LoadingPage from "../../helpers/LoadingPage";
import useStyles from "./login.style.js";
import LoginImg from "../../../static/image/loginImage.png";
import LoginImg_Lg from "../../../static/image/loginImg.png";
import LoginImg_Md from "../../../static/image/loginImage-md.png";
import { useMediaQuery } from "react-responsive";

export default function LoginParent(props) {
  const classes = useStyles();
  const {
    userTypings,
    userTypingAction,
    receiveOtpSmsAction,
    resendOtpSmsAction,
    validateOtpSmsAction,
    showAlertDialogAction,
    alertDialogContentAction,
    auth,
  } = props;
  const [isGettingGuestToken, setIsGettingGuestToken] = useState(false);
  const [resendBtnDis, setResendBtnDis] = useState(true);
  const [whatInput, setWhatInput] = useState("code");
  const getGuestToken = props.getGuestToken;
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectValue, setRedirectValue] = useState("");
  const [loadPage, setLoadPage] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  let role = "guest";
  if (props.auth.authData.user) {
    role = props.auth.authData.user.role;
  }
  useEffect(() => {
    if (auth.verifySuccess === true) {
      setLoadPage(true);
      // setTimeout(() => {
      //   window.location.replace('/');
      // }, 3000);
    }
  }, [auth.verifySuccess]);

  useEffect(() => {
    if (localStorage.getItem("AUTH_JWT")) {
      if (role !== "guest") {
        setRedirectValue("/");
        setShouldRedirect(true);
        setTimeout(() => {
          setRedirectValue("");
          setShouldRedirect(false);
        }, 10);
      }
    }
  }, []);

  const inputMobileNumberValue = (value, inputType) => {
    userTypingAction(value, inputType);
    if (value?.length > 11) {
      alertDialogContentAction(
        "شماره اشتباه",
        "شماره تلفن همراه باید 11 رقم باشد",
        "اصلاح شماره"
      );
      showAlertDialogAction(true);
    }
  };

  const submitGetSmsButton = () => {
    receiveOtpSmsAction();
    setWhatInput("code");
  };

  const inputOtpValue = (value, inputType) => {
    userTypingAction(value, inputType);
  };

  const validateSms = () => {
    validateOtpSmsAction();
  };

  const resendSms = () => {
    // countdownForResendCode(10);
    setResendBtnDis(true);
    resendOtpSmsAction();
  };

  const editMobileHandler = () => {
    setWhatInput("username");
  };

  return (
    <>
      {loadPage === true && <LoadingPage />}
      <Fade in timeout={2000}>
        <div className="loginContainer">
          {/* <div className='loginLogo'>
            <img alt='loginLogo' src={loginLogo} className='loginLogoPic' />
          </div> */}
          {/* <Grid item xs={12} sm={12} md={12} className="loginPhoto" /> */}
          <img
            src={isTabletOrMobile ? LoginImg : LoginImg_Lg}
            alt="loginImage"
            style={{
              width: "100vw",
            }}
          />
          {/* <div>
            <Grid item xs={12} sm={12} md={12} className="loginPhoto" />
          </div> */}
          <div className="login-paper-container">
            <Paper elevation={10} className="login-paper-div">
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {auth.isSmsReceived === true && whatInput === "code" ? (
                  <Grid item xs={12} sm={6} lg={4}>
                    <div style={{ marginBottom: "4rem" }}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        style={{
                          padding: "0 1rem",
                        }}
                      >
                        <TypoGraphy
                          text={"کد تایید"}
                          align={"right"}
                          component="span"
                          className={classes.lable}
                        />
                        <OtpCounter userTypingAction={userTypingAction} />
                      </Box>
                      <TextField
                        direction="ltr"
                        autoFocus
                        className="costom-textField"
                        fullWidth={true}
                        InputProps={{
                          className: classes.whiteInput,
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{
                                display: !userTypings.password
                                  ? "none"
                                  : "flex",
                              }}
                            >
                              <IconButton
                                size="small"
                                edge="start"
                                onClick={() => {
                                  userTypingAction("", "password");
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          maxLength: 5,
                        }}
                        value={userTypings.password}
                        placeholder="*****"
                        onChange={(event) => {
                          userTypingAction(event.target.value, "password");
                        }}
                      />

                      <Typography
                        variant={"caption"}
                        display={"block"}
                        align={"center"}
                        style={{
                          color: "#6D6D6D",
                          fontWeight: "600",
                          margin: "1rem auto 0",
                        }}
                      >
                        شماره وارد شده:
                        <span style={{ color: "#ED1652", margin: "0 .5rem" }}>
                          {userTypings.username}
                        </span>
                      </Typography>

                      <MaterialButton
                        btnType={"button"}
                        size={"small"}
                        fullWidth={true}
                        color={"#F47497"}
                        text={"ارسال مجدد کد تایید"}
                        onClick={resendSms}
                        disabled={userTypings.otpResendBtnDisable}
                        style={{
                          color: "#FF005C",
                          fontWeight: "bold",
                        }}
                      />
                    </div>

                    <div>
                      <MaterialButton
                        btnType={"button"}
                        size={"small"}
                        fullWidth={true}
                        color={"primary"}
                        text={"ورود"}
                        variant={"contained"}
                        onClick={validateSms}
                        disabled={
                          !userTypings.password || auth.loadingLoginButton
                        }
                        className={classes.containedButton}
                      />

                      <MaterialButton
                        btnType={"button"}
                        size={"small"}
                        fullWidth={true}
                        color={"#16EDB1"}
                        text={"تصحیح شماره موبایل"}
                        variant={"contained"}
                        onClick={editMobileHandler}
                        className={classes.outlinedButton}
                      />
                    </div>

                    <br />
                    <div>
                      {auth.verifyMsg.length > 0 &&
                        auth.verifySuccess === false && (
                          <AlertHandler
                            severity={"error"}
                            text={auth.verifyMsg}
                          />
                        )}
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6} lg={4}>
                    <div style={{ marginBottom: "3rem" }}>
                      <TypoGraphy
                        text={"شماره تلفن"}
                        variant={"subtitle1"}
                        align={"right"}
                        className={classes.lable}
                      />
                      <TextField
                        dir="ltr"
                        autoFocus
                        className="costom-textField"
                        align="left"
                        InputProps={{
                          className: classes.whiteInput,
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{
                                display: !userTypings.username
                                  ? "none"
                                  : "flex",
                              }}
                            >
                              <IconButton
                                size="small"
                                edge="start"
                                onClick={() => {
                                  inputMobileNumberValue("", "username");
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        placeholder="*********09"
                        type={"number"}
                        value={userTypings.username}
                        fullWidth={true}
                        margin={"dense"}
                        onChange={(event) => {
                          inputMobileNumberValue(
                            event.target.value,
                            "username"
                          );
                        }}
                      />
                      <TypoGraphy
                        text={
                          "پس از وارد کردن شماره تلفن، کلید ثبت نام را بزنید"
                        }
                        variant={"subtitle1"}
                        align={"right"}
                        className={classes.inputDescription}
                      />
                    </div>
                    <Grid container justifyContent="center" alignItems="center">
                      <Typography
                        align="center"
                        variant="overline"
                        color="textPrimary"
                        gutterBottom
                      >
                        ثبت نام در رزگل به معنای مطالعه و پذیرفتن{" "}
                        <span
                          style={{
                            color: "#09a0ad",
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                          onClick={() => {
                            window.open("https://blog.rosegol.app/rules/");
                          }}
                        >
                          قوانین و مقررات
                        </span>{" "}
                        است
                      </Typography>
                    </Grid>

                    <div style={{ margin: "0 1rem" }}>
                      <MaterialButton
                        fullWidth={true}
                        btnType={"button"}
                        size="medium"
                        color={"#16EDB1"}
                        text={"ثبت نام"}
                        variant={"contained"}
                        onClick={submitGetSmsButton}
                        className={classes.containedButton}
                        style={{ borderRadius: "0" }}
                        disabled={
                          !userTypings.username || auth.loadingLoginButton
                        }
                      />
                      <Button
                        fullWidth={true}
                        onClick={() => {
                          setIsGettingGuestToken(true);
                          getGuestToken();
                        }}
                        variant="outlined"
                        color="#16EDB1"
                        size="medium"
                        disabled={isGettingGuestToken}
                        className={classes.outlinedButton}
                      >
                        {isGettingGuestToken === false ? (
                          <TypoGraphy
                            text={"ورود مهمان"}
                            variant={"subtitle2"}
                            style={{ color: "#04c704", fontSize: "1.2em" }}
                          />
                        ) : (
                          <CircularProgress
                            size={26}
                            style={{ color: "#16EDB1" }}
                          />
                        )}
                      </Button>
                    </div>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </div>
        </div>
      </Fade>
      {shouldRedirect === true && <RedirectPages redirect={redirectValue} />}
    </>
  );
}
