import { TextField } from '@material-ui/core';

export default function InputTextField(props) {
  const label = props.label;
  const placeholder = props.placeholder;
  const fullWidth = props.fullWidth;
  const margin = props.margin;
  const style = props.style;
  const helperText = props.helperText;
  const className = props.className;
  const defaultValue = props.defaultValue;
  const value = props.value;
  const onChange = props.onChange;
  const InputLabelProps = props.InputLabelProps;
  const variant = props.variant;
  const size = props.size;
  const InputProps = props.InputProps;
  const inputType = props.inputType;
  const type = props.type;
  const error = props.error;
  const inputProps = props.inputProps;
  const multiline = props.multiline;
  const autoFocus = props.autoFocus;
  const disabled = props.disabled;

  return (
    <TextField
      disabled={disabled}
      autoFocus={autoFocus}
      label={label}
      placeholder={placeholder}
      fullWidth={fullWidth}
      margin={margin}
      style={style}
      helperText={helperText}
      className={className}
      defaultValue={defaultValue}
      value={value}
      multiline={multiline}
      onChange={(event) => {
        const value = event.target.value;
        onChange(value, inputType);
      }}
      InputLabelProps={InputLabelProps}
      variant={variant}
      size={size}
      InputProps={InputProps}
      inputProps={inputProps}
      type={type}
      error={error}
    />
  );
}
