import {
  makeStyles,
  Theme,
  Grid,
  Divider,
  Container,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Button,
  CardActions,
} from '@material-ui/core';
import TypoGraphy from '../../helpers/TypoGraphy';
// import emptyCart from '../../../static/image/emptycart.svg';
import priceRegex from '../../../utils/priceRegex';
import { successToast } from '../../../utils/toastHandler';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useState } from 'react';
import emptyCart from "../../../static/image/emptyCart.svg"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(7),
  },
  emptyCartImage: {
    width: 100,
    height: 100,
    margin: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(1),
    height: theme.spacing(1),
  },
  cardRoot: {
    marginBottom: theme.spacing(10),
  },
  card: {
    overflow: 'hidden',
    width: '100%',
    borderRadius: theme.spacing(1),
    boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.2)',
    // border: '1px solid rgba(254,114,76,0.5)',
    marginTop: theme.spacing(2),
  },
  img: {
    marginLeft: theme.spacing(1),
    objectFit: 'cover',
  },
  infoFlex: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
  },
  dotted: {
    border: '1px dashed #ccc',
  },
}));

export default function UserFavorites(props) {
  const favorites = props.userInfos.userFavorites;
  const userTypingAction = props.userTypingAction;
  const userTypings = props.userTypings;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {favorites.length === 0 ? (
        <>
          <Grid container justifyContent='center'>
            <img
              alt='emptyCart'
              src={emptyCart}
              className={classes.emptyCartImage}
            />
          </Grid>
          <TypoGraphy
            text={'لیست شما خالی است'}
            variant={'h6'}
            align={'center'}
            color={'textPrimary'}
          />
          <Divider className={classes.divider} />
        </>
      ) : (
        <div className={classes.cardRoot}></div>
      )}
    </div>
  );
}
