import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { nestedRoutes } from './nestedRoutes';
import BottomNav from '../components/layout/navigation/BottomNav';
import ActionDialogContainer from '../redux/containers/ActionDialogContainer';
import DrawerContainer from '../redux/containers/DrawerContainer';
import AlertDialogHandler from '../redux/containers/AlertDialogContainer';
import Page404 from '../components/helpers/Page404';

export default function Routes() {
  return (
    <Router>
      <Switch>
        {nestedRoutes.map((route, index) => (
          <Route
            key={index}
            exact={route.isExact}
            path={route.path}
            component={route.component}
          />
        ))}
        <Route component={Page404} />
      </Switch>
      <DrawerContainer />
      <ActionDialogContainer />
      <AlertDialogHandler />
      <BottomNav />
    </Router>
  );
}
