import {
  Drawer,
  Grid,
  List,
  ListItem,
  Divider,
  Container,
} from '@material-ui/core';
import MaterialButton from '../../helpers/MaterialButton';
import CancelIcon from '@material-ui/icons/Cancel';
import TypoGraphy from '../../helpers/TypoGraphy';
import priceRegex from '../../../utils/priceRegex';
import midStringRegex from '../../../utils/midStringRegex';

export default function SingleOrder(props) {
  const singleOrder = props.singleOrder;
  return (
    <Container>
      <List>
        <ListItem style={{ padding: 5 }}>
          <Grid container justifyContent='space-between' alignItems='center'>
            <TypoGraphy
              text={'قیمت اصلی محصولات'}
              align={'right'}
              color={'textPrimary'}
              variant={'subtitle1'}
            />
            <TypoGraphy
              text={
                singleOrder.invoice.products_price <= 0
                  ? 'توافقی'
                  : priceRegex(
                      singleOrder.invoice.products_price,
                      'withCurrency'
                    )
              }
              align={'left'}
              color={'textPrimary'}
              variant={'subtitle1'}
            />
          </Grid>
        </ListItem>
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          {singleOrder.invoice.discount !== 0 ||
            (!(singleOrder.invoice.discount < 0) && (
              <ListItem style={{ padding: 5 }}>
                <Grid
                  container
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <TypoGraphy
                    text={'میزان تخفیف'}
                    align={'right'}
                    color={'secondary'}
                    variant={'subtitle1'}
                  />

                  <TypoGraphy
                    text={priceRegex(
                      singleOrder.invoice.discount,
                      'withCurrency'
                    )}
                    align={'left'}
                    color={'secondary'}
                    variant={'subtitle1'}
                  />
                </Grid>
              </ListItem>
            ))}

          {singleOrder.invoice.shipping_price !== 0 && (
            <ListItem style={{ padding: 5 }}>
              <Grid
                container
                justifyContent='space-between'
                alignItems='center'
              >
                <TypoGraphy
                  text={'هزینه پیک'}
                  align={'right'}
                  color={'textSecondary'}
                  variant={'subtitle1'}
                />
                <TypoGraphy
                  text={priceRegex(
                    singleOrder.invoice.shipping_price,
                    'withCurrency'
                  )}
                  align={'left'}
                  color={'textSecondary'}
                  variant={'subtitle1'}
                />
              </Grid>
            </ListItem>
          )}
          <Divider />
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          {singleOrder.products.map((product, index) => (
            <Grid
              container
              justifyContent='space-between'
              key={index}
              alignItems='center'
            >
              <TypoGraphy
                text={`${midStringRegex(product.product.name)}(${
                  product.count
                } عدد)`}
                align={'right'}
                color={'textSecondary'}
                variant={'subtitle2'}
              />
              <TypoGraphy
                text={
                  product.product.price <= 0
                    ? 'توافقی'
                    : priceRegex(
                        product.product.price * product.count,
                        'withCurrency'
                      )
                }
                align={'left'}
                color={'textSecondary'}
                variant={'subtitle2'}
              />
            </Grid>
          ))}

          <Divider />
        </div>
        <ListItem style={{ padding: 5 }}>
          <Grid container justifyContent='space-between' alignItems='center'>
            <TypoGraphy
              text={'پرداختی'}
              align={'right'}
              color={'primary'}
              variant={'subtitle1'}
            />
            <TypoGraphy
              text={
                singleOrder.invoice.price <= 0
                  ? 'توافقی'
                  : priceRegex(singleOrder.invoice.price, 'withCurrency')
              }
              align={'left'}
              color={'primary'}
              variant={'subtitle1'}
            />
          </Grid>
        </ListItem>
      </List>
    </Container>
  );
}
