import { useEffect } from 'react';
import LoadingSkeleton from '../../helpers/LoadingSkeleton';
import {
  List,
  ListItem,
  ListItemIcon,
  Divider,
  ListItemText,
  Dialog,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import TypoGraphy from '../../helpers/TypoGraphy';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import UserInformation from './UserInformation';
import ProfileAppBar from '../navigation/AppBarContainer';
import AddressContainer from '../../../redux/containers/AddressContainer';
import UserOrders from './UserOrders';
import ShipClub from './ShipClub';
import UserFavorites from './UserFavorites';
import { useHistory } from 'react-router-dom';
import AppBarContainer from '../navigation/AppBarContainer';
import withAuth from '../../../utils/withAuth';
import GuestLoginPage from '../../helpers/GuestLoginPage';

const ProfileParent = (props) => {
  const userTypingAction = props.userTypingAction;
  const getUserDetailsAction = props.getUserDetailsAction;
  const getUserAddressesAction = props.getUserAddressesAction;
  const editProfileAction = props.editProfileAction;
  const skeletonLoadingStatusAction = props.skeletonLoadingStatusAction;
  const loading = props.loading;
  const getUserCouponsAction = props.getUserCouponsAction;
  const getUserOrdersAction = props.getUserOrdersAction;
  const getUserFavoritesAction = props.getUserFavoritesAction;
  const profileMode = props.profileMode;
  const logoutAction = props.logoutAction;
  const userTypings = props.userTypings;
  const userInfos = props.userInfos;
  const actionDialogHandler = props.actionDialogHandler;
  const getSingleOrderAction = props.getSingleOrderAction;
  const drawerHandler = props.drawerHandler;
  const copyCouponCodeAction = props.copyCouponCodeAction;
  const history = useHistory();
  let role = 'guest';
  if (props.auth.authData.user) {
    role = props.auth.authData.user.role;
  }

  useEffect(() => {
    if (role !== 'guest') {
      skeletonLoadingStatusAction('profile');
      getUserDetailsAction();
    }
  }, []);

  return (
    <div>
      {role !== 'guest' ? (
        <>
          {loading === false ? (
            <>
              <AppBarContainer />
              <UserInformation
                userTypingAction={userTypingAction}
                editProfileAction={editProfileAction}
                userTypings={userTypings}
                userInfos={userInfos}
                logoutAction={logoutAction}
              />
            </>
          ) : (
            <LoadingSkeleton />
          )}
        </>
      ) : (
        <>
          <AppBarContainer />
          <div className='guestLoginPage'>
            <GuestLoginPage />
          </div>
        </>
      )}
    </div>
  );
};

export default ProfileParent;
