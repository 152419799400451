import { connect } from 'react-redux';
import {
  mapCenterAction,
  markerLatLngAction,
  setAddressIdAction,
  setAddressTitleAction,
  setAddressAction,
  setAddressNumber,
  addAddressAction,
  showAlertDialogAction,
  alertDialogContentAction,
  getUserAddressesAction,
  setShippingPriceAction,
} from '../actions/reduxActions';
import AddressParent from '../../components/layout/address/AddressParent';

const mapStateToProps = (state, ownProps) => {
  return {
    mapStates: state.mapStates,
    userAddresses: state.userInfos.userAddresses,
    getAddressesLoading: state.userInfos.getAddressesLoading,
    addressLoading: state.userInfos.addressLoading,
    auth: state.auth,
    zoneMsg: state.orderStates.zoneMsg,
    isInZone: state.orderStates.isInZone,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    mapCenterAction: (center) => {
      dispatch(mapCenterAction(center));
    },
    markerLatLngAction: (lat, lng) => {
      dispatch(markerLatLngAction(lat, lng));
    },
    setAddressIdAction: (id) => {
      dispatch(setAddressIdAction(id));
    },
    setAddressTitleAction: (value) => {
      dispatch(setAddressTitleAction(value));
    },
    setAddressAction: (value) => {
      dispatch(setAddressAction(value));
    },
    setAddressNumber: (value) => {
      dispatch(setAddressNumber(value));
    },
    addAddressAction: () => {
      dispatch(addAddressAction());
    },
    alertDialogContentAction: (title, content, textButton) => {
      dispatch(alertDialogContentAction(title, content, textButton));
    },
    showAlertDialogAction: (open) => {
      dispatch(showAlertDialogAction(open));
    },
    getUserAddressesAction: () => {
      dispatch(getUserAddressesAction());
    },
    setShippingPriceAction: (value) => {
      dispatch(setShippingPriceAction(value));
    },
  };
};

const AddressContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressParent);

export default AddressContainer;
