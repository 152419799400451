import { Button, useTheme } from '@material-ui/core';
import React from 'react';

export default function ExtraActions({
    classes,
    infoVal,
    changeInfoVal
}) {
    const theme = useTheme();
    const actionBtns = [
        {
            title: 'دیدگاه ها',
            value: 'comments',
            className: classes.extraBtn,
        },
        {
            title: 'توضیحات',
            value: 'description',
            className: classes.extraBtn,
        },
        {
            title: 'مشخصات',
            value: 'property',
            className: classes.extraBtnWithoutMargin,
        },
    ]

    return (
        <div
            className={classes.actionBtnContainer}
        >
            {actionBtns?.map((item, index) => (
                <Button
                    key={index}
                    onClick={() => {
                        changeInfoVal(item.value);
                    }}
                    style={{
                        backgroundColor:
                            infoVal
                                ===
                                item.value
                                ?
                                theme.palette.primary.main
                                :
                                '#ffffff',
                        color:
                            infoVal
                                ===
                                item.value
                                ?
                                '#ffffff'
                                :
                                theme.palette.text.secondary,
                        border:
                            infoVal
                                ===
                                item.value
                                ?
                                `1px solid ${theme.palette.primary.main}`
                                :
                                '1px solid #ffffff',
                    }}
                    variant="contained"
                    size="small"
                    className={item.className}
                >
                    {item.title}
                </Button>
            ))}
        </div>
    );
}
