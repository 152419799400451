import { ACTION_CONSTANTS } from '../constants/actionConstants';

export default function getProductsReducer(state = {}, action) {
  const payload = action.payload;

  switch (action.type) {
    case ACTION_CONSTANTS.GET_HOME_ITEMS_REQUEST:
      return {
        ...state,
        loadingProducts: true,
      };
    case ACTION_CONSTANTS.GET_HOME_ITEMS:
    case ACTION_CONSTANTS.GET_PRODUCTS_BY_CATEGORY:
    case ACTION_CONSTANTS.GET_ALL_PRODUCTS_BY_TYPE:
      return {
        ...state,
        homeProducts: payload.data,
        loadingProducts: false,
      };
    case ACTION_CONSTANTS.GET_CATEGORIES:
      return {
        ...state,
        categories: payload.data,
        loadingProducts: false,
      };
    case ACTION_CONSTANTS.GET_PRODUCT_DETAILS_REQUEST:
      return {
        ...state,
        singleProductLoading: true,
      };
    case ACTION_CONSTANTS.GET_PRODUCT_DETAILS:
      return {
        ...state,
        productDetails: payload.data,
        loadingProducts: false,
        singleProductLoading: false,
      };
    case ACTION_CONSTANTS.GET_CART_REQUEST:
      return {
        ...state,
        isCartReceived: true,
      };
    case ACTION_CONSTANTS.GET_CART_SUCCESS:
      return {
        ...state,
        isCartReceived: false,
        cartFromServer: payload.data,
      };
    case ACTION_CONSTANTS.CHECK_HOW_MUCH_IN_CART:
      return {
        ...state,
        isCartReceived: false,
        itemQuantity: payload,
      };
    case ACTION_CONSTANTS.UPDATE_CART_REQUEST:
      return {
        ...state,
        isCartReceived: true,
      };
    case ACTION_CONSTANTS.SEARCH_PRODUCTS_REQUEST:
      return {
        ...state,
        searchLoading: true,
      };
    case ACTION_CONSTANTS.SEARCH_PRODUCTS:
      return {
        ...state,
        gettingSearchProducts: false,
        searchedProducts: payload.data,
        searchLoading: false,
      };
      case ACTION_CONSTANTS.CHECK_IF_IN_FAVORITES:
        return {
          ...state,
          isInFavorites: payload,
        }
    default:
      return state;
  }
}
