import { Typography } from '@material-ui/core';
import React from 'react';
import longStringRegex from "../../../../utils/longStringsRegex"

export default function Name({
    data, 
    classes,
}) {
    return (
        <div
        className={classes.prdName}
        >
            <Typography
                variant="subtitle1"
                color="textPrimary"
                align="center"
                className={classes.prdNameTypo}
            >
                {longStringRegex(data?.name)}
            </Typography>
      </div>
  );
}
