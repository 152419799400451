import {
  makeStyles,
  Theme,
  AppBar,
  Grid,
  ListItemText,
  CircularProgress,
  ListItem,
} from '@material-ui/core';
import MaterialButton from '../../helpers/MaterialButton';
import TypoGraphy from '../../helpers/TypoGraphy';
import priceRegex from '../../../utils/priceRegex';
import { useHistory } from 'react-router-dom';
import RedirectPages from '../../helpers/RedirectPages';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    padding: 5,
    borderTop: '1px solid #ccc',
  },
}));

export default function SubmitOrder(props) {
  const classes = useStyles();
  const priceInvoice = props.priceInvoice;
  const history = useHistory();
  const drawerHandler = props.drawerHandler;
  const submitOrderAction = props.submitOrderAction;
  const addressId = props.addressId;
  const alertDialogContentAction = props.alertDialogContentAction;
  const showAlertDialogAction = props.showAlertDialogAction;
  const submittingOrder = props.submittingOrder;
  const submitOrderResponse = props.submitOrderResponse;
  const orderSubmitted = props.orderSubmitted;
  const requestingPrice = props.requestingPrice;
  const deliveryType = props.userTypings.submitDelivery;

  const handleSubmitOrder = () => {
    if (deliveryType === true) {
      if (addressId === null || addressId === undefined) {
        showAlertDialogAction(true);
        alertDialogContentAction(
          'آدرس انتخاب نشده',
          'لطفا آدرس خود را انتخاب کنید',
          'متوجه شدم'
        );
      } else {
        submitOrderAction();
      }
    } else {
      submitOrderAction();
    }
  };

  const seePriceInfo = () => {
    drawerHandler(true, 'priceInfo');
  };

  return (
    <AppBar position='fixed' color='inherit' className={classes.appBar}>
      <Grid container justifyContent='space-between' alignItems='center'>
        {requestingPrice === false ? (
          <>
            {priceInvoice.invoice.price <= 0 ? (
              <ListItemText
                primary={'قابل پرداخت'}
                secondary={'توافقی'}
                style={{ marginRight: 5 }}
              />
            ) : (
              <ListItemText
                primary={
                  <MaterialButton
                    text={
                      requestingPrice === false ? (
                        'مشاهده جزئیات قیمت'
                      ) : (
                        <Skeleton variant='text' width={70} animation='wave' />
                      )
                    }
                    btnType={'button'}
                    variant={'text'}
                    color={'primary'}
                    style={{ marginRight: 5 }}
                    size={'small'}
                    onClick={seePriceInfo}
                  />
                }
                secondary={
                  <TypoGraphy
                    text={
                      requestingPrice === false ? (
                        priceRegex(priceInvoice.invoice.price, 'withCurrency')
                      ) : (
                        <Skeleton variant='text' width={70} animation='wave' />
                      )
                    }
                    variant={'subtitle1'}
                    align={'right'}
                    style={{ marginRight: 20, fontWeight: 'bold' }}
                    color={'textPrimary'}
                  />
                }
              />
            )}
          </>
        ) : (
          <div>
            <Skeleton variant='text' width={70} animation='wave' />
            <Skeleton variant='text' width={70} animation='wave' />
          </div>
        )}

        {requestingPrice === true ? (
          <div style={{ marginLeft: 10, borderRadius: 20 }}>
            <Skeleton variant='rect' width={150} height={50} animation='wave' />
          </div>
        ) : (
          <MaterialButton
            btnType={'button'}
            text={'ثبت سفارش'}
            variant={'contained'}
            color={'primary'}
            size={'large'}
            disabled={submittingOrder}
            style={{ width: 150, height: 45, marginLeft: 5, fontSize: 18 }}
            onClick={handleSubmitOrder}
          />
        )}
      </Grid>
      {orderSubmitted === true && (
        <RedirectPages redirect={`/order/${submitOrderResponse.order_id}`} />
      )}
    </AppBar>
  );
}
