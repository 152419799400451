import AppBarContainer from '../navigation/AppBarContainer';
import {
  makeStyles,
  Theme,
  Card,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
  Grid,
  CircularProgress,
  useScrollTrigger,
  Fab,
  Button,
  Drawer,
  InputAdornment,
} from '@material-ui/core';
import TypoGraphy from '../../helpers/TypoGraphy';
import stringLengthRegex from '../../../utils/stringLengthRegex';
import priceRegex from '../../../utils/priceRegex';
import { useHistory, useLocation } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { successToast } from '../../../utils/toastHandler';
import { useEffect, useState } from 'react';
import LoadingSkeleton from '../../helpers/LoadingSkeleton';
import InputTextField from '../../helpers/InputTextField';
import MaterialButton from '../../helpers/MaterialButton';
import emptyProducts from '../../../static/image/emptyProducts.svg';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import ScrollToTop from '../../helpers/ScrollToTop';
import longStringRegex from '../../../utils/longStringsRegex';
import RedirectPages from '../../helpers/RedirectPages';
import TuneIcon from '@material-ui/icons/Tune';
import emptyImage from '../../../static/image/emptyImage.svg';
import shortStringRegex from '../../../utils/shortStringRegex';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(1),
  },
  ImageList: {
    display: 'inline-block',
    height: 'max-content',
    margin: theme.spacing(0, 0.5, 0, 0.5),
  },
  gridRoot: {
    overflow: 'auto',
    whiteSpace: 'nowrap',
    paddingBottom: 15,
  },
  emptyCartImage: {
    width: 100,
    height: 100,
  },
  foodAvatar: {
    width: 45,
    height: 45,
    borderRadius: '50%',
    boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.2)',
  },
}));

export default function SearchParent(props) {
  const classes = useStyles();
  const categories = props.categories;
  const parentCategory = props.parentCategory;
  const userTypings = props.userTypings;
  const userTypingAction = props.userTypingAction;
  const history = useHistory();
  const addToCartAction = props.addToCartAction;
  const products = props.products;
  const gettingSearchProducts = props.gettingSearchProducts;
  const searchProductsAction = props.searchProductsAction;
  const skeletonLoadingStatusAction = props.skeletonLoadingStatusAction;
  const searchLoading = props.searchLoading;
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectValue, setRedirectValue] = useState('');
  const location = useLocation();
  const userNavigationHistorySaver = props.userNavigationHistorySaver;
  const [categoryName, setCategoryName] = useState('');
  const [sortValue, setSortValue] = useState('');
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);

  useEffect(() => {
    if (userTypings.filterToSearch === '') {
      userTypingAction('discount', 'filterToSearch');
      return setSortValue('تخفیف دار');
    }
    filtersChip.map((filters) => {
      if (userTypings.filterToSearch === filters.value) {
        return setSortValue(filters.name);
      }
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
    userTypingAction(parentCategory[0].id, 'categoryToSearch');
    setCategoryName('همه');
    skeletonLoadingStatusAction('listSkeleton');
    searchProductsAction();
  }, [searchProductsAction]);

  const handleSearchBtn = () => {
    searchProductsAction();
  };

  const handleInputChange = (value, inputType) => {
    userTypingAction(value, inputType);
    searchProductsAction();
  };

  const closeDrawer = () => {
    setShowFilterDrawer(false);
  };

  const openDrawer = () => {
    setShowFilterDrawer(true);
  };

  return (
    <div className={classes.root}>
      {gettingSearchProducts === false ? (
        <>
          <AppBarContainer />
          {/* <TypoGraphy
            text={'جستجوی نام محصول'}
            align={'right'}
            style={{ margin: '10px 10px 5px 0' }}
            variant={'subtitle1'}
            color={'textPrimary'}
          /> */}
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            style={{ padding: '5px 10px 10px 10px' }}
          >
            <InputTextField
              variant={'outlined'}
              size={'small'}
              placeholder={'جستجوی نام محصول...'}
              value={userTypings.typeProductSearch}
              inputType={'typeProductSearch'}
              onChange={handleInputChange}
              fullWidth={true}
              autoFocus={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      size='small'
                      edge='end'
                      color='secondary'
                      onClick={() => {
                        userTypingAction('', 'typeProductSearch');
                        searchProductsAction();
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid container justifyContent='space-between' alignItems='center'>
            <MaterialButton
              style={{ marginRight: 8 }}
              btnType={'button'}
              icon={<TuneIcon />}
              variant={'contained'}
              color={'primary'}
              size={'small'}
              text={'اعمال فیلتر جستجو'}
              onClick={openDrawer}
            />
            <div
              style={{
                marginLeft: 8,
              }}
            >
              {categoryName.length !== 0 && (
                <Chip
                  style={{ marginLeft: 5 }}
                  size='small'
                  label={shortStringRegex(categoryName)}
                  onDelete={() => {
                    setCategoryName('');
                    userTypingAction('', 'categoryToSearch');
                    searchProductsAction();
                  }}
                />
              )}
              {sortValue.length !== 0 && (
                <Chip
                  size='small'
                  label={shortStringRegex(sortValue)}
                  onDelete={() => {
                    setSortValue('');
                    userTypingAction('', 'filterToSearch');
                    searchProductsAction();
                  }}
                />
              )}
            </div>
          </Grid>
          <Divider style={{ marginTop: 5 }} />

          <TypoGraphy
            text={'محصولات'}
            align={'right'}
            style={{ margin: '10px 10px 5px 0' }}
            variant={'subtitle1'}
            color={'textPrimary'}
          />
          <Divider />
          <div style={{ marginBottom: 20 }}>
            <List>
              {products.length === 0 ? (
                <>
                  <Grid container justifyContent='center' alignItems='center'>
                    <img
                      className={classes.emptyCartImage}
                      src={emptyProducts}
                      alt='emptyProducts'
                    />
                  </Grid>
                  <TypoGraphy
                    text={'محصولی یافت نشد'}
                    align={'center'}
                    style={{ margin: '10px 10px 5px 0' }}
                    variant={'h6'}
                    color={'primary'}
                  />
                </>
              ) : (
                <>
                  {searchLoading === true ? (
                    <Grid
                      container
                      justifyContent='center'
                      alignItems='center'
                      style={{ marginTop: 20 }}
                    >
                      <CircularProgress color='primary' size={50} />
                    </Grid>
                  ) : (
                    <>
                      {products.map((product, index) => (
                        <div key={index}>
                          <ListItem
                            button
                            onClick={() => {
                              history.push(`/product/${product.id}`);
                            }}
                          >
                            <ListItemAvatar>
                              <img
                                alt='foodPic'
                                id='foodSearchPic'
                                src={product.image}
                                className={classes.foodAvatar}
                                onError={(event) => {
                                  event.target.src = emptyImage;
                                }}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <TypoGraphy
                                  text={longStringRegex(product.name)}
                                  align={'right'}
                                  variant={'subtitle2'}
                                  color={'textPrimary'}
                                />
                              }
                              secondary={
                                <TypoGraphy
                                  text={priceRegex(
                                    product.price,
                                    'withCurrency'
                                  )}
                                  align={'right'}
                                  variant={'subtitle2'}
                                  color={'textSecondary'}
                                />
                              }
                            />
                            <IconButton size='small' color='primary'>
                              <ArrowLeftIcon fontSize='small' />
                            </IconButton>
                          </ListItem>
                          <Divider />
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
            </List>
          </div>

          <Drawer
            onClose={closeDrawer}
            open={showFilterDrawer}
            PaperProps={{ square: false }}
            anchor={'bottom'}
          >
            <Grid container justifyContent='space-around' alignItems='center'>
              <TypoGraphy
                text={'اعمال فیلتر جستجو'}
                variant={'subtitle1'}
                align={'right'}
                color={'textPrimary'}
              />
              <IconButton
                color={'primary'}
                size={'small'}
                edge={'end'}
                onClick={closeDrawer}
              >
                <CancelIcon />
              </IconButton>
            </Grid>
            <Divider />
            <div>
              <TypoGraphy
                text={'دسته بندی ها'}
                align={'right'}
                style={{ margin: '5px 10px 5px 0px' }}
                variant={'subtitle1'}
                color={'primary'}
              />
              <div className={classes.gridRoot}>
                <div className={classes.ImageList}>
                  <Chip
                    color={
                      userTypings.categoryToSearch === parentCategory[0].id
                        ? 'primary'
                        : 'default'
                    }
                    size='small'
                    label={'همه'}
                    onClick={() => {
                      setCategoryName('همه');
                      userTypingAction(
                        parentCategory[0].id,
                        'categoryToSearch'
                      );
                      searchProductsAction();
                    }}
                    style={{ margin: '2px 0px 0px 0px' }}
                  />
                </div>
                {categories[0].id === parentCategory[0].id ? null : (
                  <>
                    {categories.map((category, index) => (
                      <div className={classes.ImageList} key={index}>
                        <Chip
                          color={
                            userTypings.categoryToSearch === category.id
                              ? 'primary'
                              : 'default'
                          }
                          size='small'
                          label={category.name}
                          onClick={() => {
                            setCategoryName(category.name);
                            userTypingAction(category.id, 'categoryToSearch');
                            searchProductsAction();
                          }}
                          style={{ margin: '2px 0px 0px 0px' }}
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>

              <TypoGraphy
                text={'مرتب سازی'}
                align={'right'}
                style={{ margin: '5px 10px 5px 0px' }}
                variant={'subtitle1'}
                color={'secondary'}
              />
              <div className={classes.gridRoot}>
                {filtersChip.map((filter, index) => (
                  <div className={classes.ImageList} key={index}>
                    <Chip
                      color={
                        userTypings.filterToSearch === filter.value
                          ? 'primary'
                          : 'default'
                      }
                      size='small'
                      label={filter.name}
                      onClick={() => {
                        setSortValue(filter.name);
                        userTypingAction(filter.value, 'filterToSearch');
                        searchProductsAction();
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <br />
          </Drawer>
          <ScrollToTop />
        </>
      ) : (
        <LoadingSkeleton />
      )}
      {shouldRedirect === true && <RedirectPages redirect={redirectValue} />}
    </div>
  );
}

const filtersChip = [
  { name: 'همه', value: '' },
  { name: 'تخفیف دار', value: 'discount' },
  { name: 'محبوب ترین', value: '-rate' },
  { name: 'جدیدترین', value: '-created_at' },
  { name: 'ارزان ترین', value: 'price' },
  { name: 'گران ترین', value: '-price' },
];
