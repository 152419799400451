import { connect } from 'react-redux';
import {
  drawerHandler,
  skeletonLoadingStatusAction,
  getOrderPriceAction,
  removeFromCartAction,
  increaseUnitsAction,
  decreaseUnitsAction,
  showAlertDialogAction,
  alertDialogContentAction,
  userTypingAction,
  getUserDetailsAction,
  submitOrderAction,
  getCartAction,
} from '../actions/reduxActions';
import FinalizePayment from '../../components/layout/orders/FinalizePayment';

const mapStateToProps = (state, ownProps) => {
  return {
    infoDrawer: state.appConfigs.infoDrawer,
    userInfos: state.userInfos,
    priceInvoice: state.orderStates.priceInvoice,
    cartItems: state.cart.cartItems,
    addressId: state.mapStates.addressId,
    requestingPrice: state.orderStates.requestingPrice,
    userTypings: state.userTypings,
    userProfile: state.userInfos.userProfile,
    getProfileLoading: state.userInfos.getProfileLoading,
    submittingOrder: state.orderStates.submittingOrder,
    submitOrderResponse: state.orderStates.submitOrderResponse,
    orderSubmitted: state.orderStates.orderSubmitted,
    isCheckingCoupon: state.userInfos.isCheckingCoupon
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    drawerHandler: (open, type) => {
      dispatch(drawerHandler(open, type));
    },
    skeletonLoadingStatusAction: (type) => {
      dispatch(skeletonLoadingStatusAction(type));
    },
    getOrderPriceAction: () => {
      dispatch(getOrderPriceAction());
    },
    removeFromCartAction: (id) => {
      dispatch(removeFromCartAction(id));
    },
    increaseUnitsAction: (id) => {
      dispatch(increaseUnitsAction(id));
    },
    decreaseUnitsAction: (id) => {
      dispatch(decreaseUnitsAction(id));
    },
    showAlertDialogAction: (open) => {
      dispatch(showAlertDialogAction(open));
    },
    alertDialogContentAction: (title, content, textButton) => {
      dispatch(alertDialogContentAction(title, content, textButton));
    },
    userTypingAction: (value, type) => {
      dispatch(userTypingAction(value, type));
    },
    getUserDetailsAction: () => {
      dispatch(getUserDetailsAction());
    },
    submitOrderAction: () => {
      dispatch(submitOrderAction());
    },
    getCartAction: () => {
      dispatch(getCartAction())
    }
  };
};

const FinalizePaymentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FinalizePayment);

export default FinalizePaymentContainer;
