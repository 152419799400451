import { Grid, Typography } from '@material-ui/core'
import React from 'react'

export default function Delivery({ data, classes }) {
    return (
        <div>
            <br />
            <div>
                <Grid container alignItems="center" justifyContent='center'>
                    <img
                        alt="mapPic"
                        className={classes.mapImage}
                        height="185"
                        width="340"
                        src={`https://mapapi.shipnow.ir/api/v1/ride?height=185&width=350&origin=${data?.shop?.full_address?.lat
                            },${data?.shop?.full_address?.lng}&destinations=${data?.delivery?.deliver_address?.lat},${data?.delivery?.deliver_address?.lng}`}
                    />
                </Grid>
                <div className={classes.address}>
                    <Typography variant="subtitle1" color="textPrimary" align="right">
                        آدرس: {data?.delivery?.deliver_address?.address}
                    </Typography>
                </div>

            </div>
        </div>
    )
}