import React, { useState } from "react";
import CommentsList from "./CommentsList";
import ExtraActions from "./ExtraActions";
import { useDispatch } from "react-redux";
import {
  actionDialogHandler,
  alertDialogContentAction,
  showAlertDialogAction,
} from "../../../../redux/actions/reduxActions";
import DescriptionsParent from "./DescriptionsParent";
import PropertiesParent from "./PropertiesParent";

export default function ExtraInfo({ data, classes, comments, role }) {
  const [infoVal, setInfoVal] = useState("comments");
  const dispatch = useDispatch();
  const changeInfoVal = (val) => {
    setInfoVal(val);
  };

  const actionDialogOpenerHandler = (open, type) => {
    dispatch(actionDialogHandler(open, type));
  };

  const alertDialogOpener = (title, content, textButton) => {
    dispatch(alertDialogContentAction(title, content, textButton));
  };

  const alertDialogShowHandler = (open) => {
    dispatch(showAlertDialogAction(open));
  };

  return (
    <div className={classes.extraInfos}>
      <ExtraActions
        classes={classes}
        infoVal={infoVal}
        changeInfoVal={changeInfoVal}
      />
      {infoVal === "comments" ? (
        <CommentsList
          comments={comments}
          actionDialogHandler={actionDialogOpenerHandler}
          role={role}
          alertDialogContentAction={alertDialogOpener}
          showAlertDialogAction={alertDialogShowHandler}
        />
      ) : infoVal === "description" ? (
        <DescriptionsParent data={data} classes={classes} />
      ) : infoVal === "property" ? (
        <PropertiesParent data={data} classes={classes} />
      ) : null}
    </div>
  );
}
