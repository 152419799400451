import React, { useEffect, useState } from "react";
import "./carousel.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useMediaQuery } from "react-responsive";

const CarouselModule = (props) => {
  const { children, show, BackgroundColor } = props;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(children.length);

  const [touchPosition, setTouchPosition] = useState(null);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  // Set the length to match current children from props
  useEffect(() => {
    setLength(children.length);
  }, [children]);

  const next = async () => {
    if (currentIndex < length - show) {
      await setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const prev = async () => {
    if (currentIndex > 0) {
      await setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;

    const diff = touchDown - currentTouch;

    if (diff > 5) {
      prev();
    }

    if (diff < -5) {
      next();
    }

    setTouchPosition(null);
  };

  // const handleScroll = (e) => {
  //   console.log("event", e);
  //   let numScrollLeft = Number(e.currentTarget.scrollLeft);

  //   if (numScrollLeft === 0) {
  //     prev();
  //   }
  // };

  return (
    <div
      className="carousel-container"
      style={{
        backgroundColor: BackgroundColor,
      }}
    >
      <div className="carousel-wrapper">
        {/* You can alwas change the content of the button to other things */}
        {currentIndex < length - show && (
          <button onClick={next} className="left-arrow">
            <ArrowBackIosIcon
              style={{
                color: BackgroundColor,
                fontSize: "1.2em",
              }}
            />
          </button>
        )}

        <div
          // onScroll={handleScroll}
          className="carousel-content-wrapper"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          style={{
            flexGrow: length <= show && isTabletOrMobile ? "1" : "unset",
            border: "2px solid blackt",
          }}
        >
          <div
            className={`carousel-content show-${show}`}
            style={{
              transform: `translateX(${currentIndex * (100 / show)}%)`,
              transitionDelay: ".5s",
              // width: isTabletOrMobile
              //   ? length <= show
              //     ? `${100 / show}% !`
              //     : `${80 / show}%`
              //   : `${80 / show}%`,
            }}
          >
            {children}
          </div>
        </div>
        {/* You can alwas change the content of the button to other things */}
        {currentIndex > 0 && (
          <button onClick={prev} className="right-arrow">
            <ArrowForwardIosIcon
              style={{
                color: BackgroundColor,
                fontSize: "1.2em",
              }}
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default CarouselModule;
