import { connect } from 'react-redux';
import ActionDialogHandler from '../../components/helpers/ActionDialogHandler';
import { actionDialogHandler,userTypingAction, addCommentsAction

,showAlertDialogAction,alertDialogContentAction
} from '../actions/reduxActions';

const mapStateToProps = (state, ownProps) => {
  return {
    actionDialogStatus: state.appConfigs.actionDialog,
    userTypings: state.userTypings,
    addCommentDisabled: state.userInfos.addingCommentLoading,
    singleOrder: state.userInfos.singleOrder,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actionDialogHandler: (open, type) => {
      dispatch(actionDialogHandler(open, type));
    },
    userTypingAction: (value, type) => {
      dispatch(userTypingAction(value, type));
    },
    addCommentsAction: () => {
      dispatch(addCommentsAction())
    },
    showAlertDialogAction: (open) => {
      dispatch(showAlertDialogAction(open));
    },
    alertDialogContentAction: (title, content, textButton) => {
      dispatch(alertDialogContentAction(title, content, textButton));
    },
  };
};

const ActionDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionDialogHandler);

export default ActionDialogContainer;
