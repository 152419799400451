import { connect } from 'react-redux';
import OrderDetailsParent from '../../components/layout/orders/orderDetails/OrderDetailsParent';
import {
  skeletonLoadingStatusAction,
  getSingleOrderAction,
  userTypingAction,
  clearOrderAction,
  clearCartAction,
  requestPaymentAction,
  actionDialogHandler,
  requestOnlinePayment
} from '../actions/reduxActions';

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.userInfos.singleOrderLoading,
    singleOrder: state.userInfos.singleOrder,
    singleOrderLoading: state.userInfos.singleOrderLoading,
    paymentStatus: state.orderStates
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    skeletonLoadingStatusAction: (type) => {
      dispatch(skeletonLoadingStatusAction(type));
    },
    getSingleOrderAction: () => {
      dispatch(getSingleOrderAction());
    },
    userTypingAction: (value, type) => {
        dispatch(userTypingAction(value, type));
      },
      clearOrderAction: () => {
        dispatch(clearOrderAction())
      },
      clearCartAction: () => {
        dispatch(clearCartAction())
      },
      requestOnlinePayment: (payment_id) => {
        dispatch(requestOnlinePayment(payment_id))
      },
      actionDialogHandler: (open, type) => {
        dispatch(actionDialogHandler(open, type));
      },
      
  };
};

const OrderDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDetailsParent);

export default OrderDetailsContainer;
