import {
  makeStyles,
  Theme,
  AppBar,
  Grid,
  ListItemText,
  Typography,
} from '@material-ui/core';
import MaterialButton from '../../helpers/MaterialButton';
import TypoGraphy from '../../helpers/TypoGraphy';
import priceRegex from '../../../utils/priceRegex';
import { useHistory, useLocation } from 'react-router-dom';
import RedirectPages from '../../helpers/RedirectPages';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    padding: 5,
    borderTop: '1px solid #ccc',
  },
}));

export default function FinalOrderStep(props) {
  const classes = useStyles();
  const priceInvoice = props.priceInvoice;
  const history = useHistory();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectValue, setRedirectValue] = useState('');
  const userNavigationHistorySaver = props.userNavigationHistorySaver;
  const location = useLocation();
  const hasUploadedImage = props.hasUploadedImage;
  const shouldUploadImage = props.shouldUploadImage;

  const handleContinuePayment = () => {
    history.push('/checkout');
  };

  return (
    <>
      <AppBar position='fixed' color='inherit' className={classes.appBar}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <ListItemText
            primary={
              <TypoGraphy
                text={'قابل پرداخت'}
                variant={'subtitle2'}
                align={'right'}
                color={'textPrimary'}
                style={{ marginRight: 5 }}
              />
            }
            secondary={
              <TypoGraphy
                text={
                  priceInvoice.invoice.price <= 0
                    ? 'توافقی'
                    : priceRegex(priceInvoice.invoice.price, 'withCurrency')
                }
                variant={'subtitle1'}
                align={'right'}
                style={{ marginRight: 20, fontWeight: 'bold' }}
                color={'primary'}
              />
            }
          />

          {shouldUploadImage === true ? (
            <>
              {hasUploadedImage === false ||
              !sessionStorage.getItem('UPLOADED_IMAGE_ID') ? (
                <Typography
                  variant='overline'
                  style={{ fontWeight: 'bold' }}
                  align='left'
                  color='secondary'
                >
                  برای تکمیل سفارش عکس را آپلود کنید
                </Typography>
              ) : (
                <MaterialButton
                  btnType={'button'}
                  text={'ادامه'}
                  variant={'contained'}
                  color={'primary'}
                  size={'large'}
                  style={{
                    width: 150,
                    height: 45,
                    marginLeft: 5,
                    fontSize: 20,
                  }}
                  onClick={handleContinuePayment}
                />
              )}
            </>
          ) : (
            <MaterialButton
              btnType={'button'}
              text={'ادامه'}
              variant={'contained'}
              color={'primary'}
              size={'large'}
              style={{ width: 150, height: 45, marginLeft: 5, fontSize: 20 }}
              onClick={handleContinuePayment}
            />
          )}
        </Grid>
      </AppBar>
      {shouldRedirect === true && <RedirectPages redirect={redirectValue} />}
    </>
  );
}
