import L from "leaflet";
import deliveryMarker from "../../../static/image/deliveryMarker.svg"
import houseMarker from "../../../static/image/houseMarker.svg"

const icon =  L.icon({
  iconSize: [25,41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-black.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png"
});

export const violetIcon = L.icon({
  iconSize: [50, 50],
  iconAnchor: [30, 41],
  popupAnchor: [2, -40],
  iconUrl: houseMarker,
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png"
})

export const orangeIcon = L.icon({
  iconSize: [50, 50],
  iconAnchor: [30, 41],
  popupAnchor: [2, -40],
  iconUrl: deliveryMarker,
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png"
})

export default icon;