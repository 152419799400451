import React, { useState } from "react";
import Carousel from "react-material-ui-carousel";
import emptyImage from "../../../../static/image/emptyImage.svg";
import ImageGallery from "./ImageGallery";

export default function CarouselContainer({ classes, images }) {
  const [showGallery, setShowGallery] = useState(false);

  const dialogOpener = () => {
    setShowGallery(!showGallery);
  };
  const anArrayOfNumbers = images?.map((img, index) => (
    <img key={index} src={img} alt="img" />
  ));

  return (
    <>
      <Carousel
        animation={"fade"}
        timeout={500}
        autoPlay={false}
        swipe
        fullHeightHover={true}
        indicatorIconButtonProps={{
          className: classes.indicatorBtn,
        }}
        activeIndicatorIconButtonProps={{
          className: classes.activeIndicatorBtn,
        }}
        indicatorContainerProps={{
          className: classes.indicatorContainer,
        }}
        className={classes.carousel}

        // IndicatorIcon={anArrayOfNumbers}
      >
        {images?.map((img, index) => (
          <div className={classes.carouselImageDiv} key={index}>
            <img
              onClick={dialogOpener}
              className={classes.carouselImg}
              loading="lazy"
              src={img.image}
              alt={"carouselImg"}
              onError={(event) => {
                event.target.src = emptyImage;
              }}
            />
          </div>
        ))}
      </Carousel>
      <ImageGallery
        show={showGallery}
        close={dialogOpener}
        images={images}
        classes={classes}
      />
    </>
  );
}
