import {
  makeStyles,
  Theme,
  Grid,
  ImageList,
  Card,
  CardHeader,
  Avatar,
  CardActions,
  Button,
  IconButton,
  CardContent,
  List,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
} from '@material-ui/core';
import TypoGraphy from '../../helpers/TypoGraphy';
import priceRegex from '../../../utils/priceRegex';
import MaterialButton from '../../helpers/MaterialButton';
import AddShoppingCartOutlinedIcon from '@material-ui/icons/AddShoppingCartOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import stringLengthRegex from '../../../utils/stringLengthRegex';
import emptyImage from '../../../static/image/emptyImage.svg';
import FileUploadComponent from './FileUploadComponent';
import OrderAddAddressMsg from './OrderAddAddressMsg';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    objectFit: 'cover',
    width: 100,
    height: 100,
    marginLeft: theme.spacing(1)
  },
  iconFlex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(0, 0, 0, 1),
    border: '1px solid #cccccc',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
  productCardRoot: {
    borderRadius: theme.spacing(2),
    boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.2)',
    margin: theme.spacing(1),
    // border: '1px solid rgba(254,114,76,0.2)',
  },
  addBtnGrid: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  cardHeader: {
    padding: '5px 0 5px 5px',
    width: '100%',
  },
  cardContent: {
    padding: '0 5px 0 0',
  },
  cardAction: {
    padding: '0 10px 0 10px',
  },
}));

export default function OrdersCart(props) {
  const classes = useStyles();
  const drawerHandler = props.drawerHandler;
  const removeFromCartAction = props.removeFromCartAction;
  const increaseUnitsAction = props.increaseUnitsAction;
  const decreaseUnitsAction = props.decreaseUnitsAction;
  const priceInvoice = props.priceInvoice;
  const cartFromServer = props.cartFromServer;
  const addCartAction = props.addCartAction;
  const removeCartAction = props.removeCartAction;
  const deleteCartAction = props.deleteCartAction;
  const shouldUploadImage = props.shouldUploadImage;
  const hasUploadedImage = props.hasUploadedImage;
  const history = useHistory()

  const priceList = [
    {
      name: 'جمع قیمت محصولات',
      value: priceRegex(priceInvoice.invoice.products_price, 'withCurrency'),
    },
    {
      name: 'میزان تخفیف',
      value: `${priceInvoice.invoice.discount.toLocaleString('fa-IR')} تومان`,
    },
  ];

  return (
    <div>
      {cartFromServer.map((item, index) => (
        <Card key={index} className={classes.productCardRoot}>
          <div
            style={{
              padding: 0,
              margin: 0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}

          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <img
                alt='cartPic'
                onError={(event) => {
                  event.target.src = emptyImage;
                }}
                src={item.product.image}
                className={classes.avatar}
                onClick={() => {
                  history.push(`/product/${item.product.id}`);
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start"
                }}
              >
                <TypoGraphy
                  text={stringLengthRegex(item.product.name)}
                  color={'textPrimary'}
                  align={'right'}
                  variant={"subtitle2"}
                />
                <TypoGraphy
                  text={
                    item.product.price <= 0
                      ? 'توافقی'
                      : priceRegex(
                        item.product.price,
                        'withCurrency'
                      )
                  }
                  color={'textSecondary'}
                  align={'right'}
                  variant="subtitle2"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "0px 0px 10px 20px"
                }}
              >
                <IconButton
                  color={'default'}
                  size={'small'}
                  edge={'end'}
                  onClick={() => {
                    deleteCartAction(item.product.id, item.count);
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </div>

              <div className={classes.iconFlex}>
                <IconButton
                  size={'small'}
                  onClick={() => {
                    addCartAction(item.product.id);
                  }}
                  edge="start"
                  color={'default'}
                >
                  <AddIcon />
                </IconButton>
                &nbsp;
                <TypoGraphy
                  text={item.count}
                  align={'center'}
                  variant="subtitle2"
                  color={'textPrimary'}
                />
                &nbsp;
                <IconButton
                  color="default"
                  edge="start"
                  size={'small'}
                  onClick={() => {
                    removeCartAction(item.product.id);
                  }}
                >
                  <RemoveIcon />
                </IconButton>
              </div>
            </div>
          </div>
          </Card>
      ))}
      {shouldUploadImage === true && (
        <FileUploadComponent hasUploadedImage={hasUploadedImage} />
      )}
      <OrderAddAddressMsg drawerHandler={drawerHandler} />
      {priceInvoice.invoice.price <= 0 ? (
        <ListItem
          style={{
            marginBottom: 30,
          }}
        >
          <ListItemText
            primary={
              <TypoGraphy
                text={'هزینه سفارش'}
                align={'right'}
                color={'textPrimary'}
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: 'bold',
                  fontSize: 17,
                }}
              />
            }
          />
          <TypoGraphy
            text={'توافقی'}
            align={'left'}
            style={{
              margin: 0,
              padding: 0,
              color: '#eb1111',
              fontSize: 16,
            }}
          />
        </ListItem>
      ) : (
        <List
          style={{
            marginBottom: 30,
          }}
        >
          {priceList.map((price, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={
                  <TypoGraphy
                    text={price.name}
                    align={'right'}
                    color={'textPrimary'}
                    style={{
                      margin: 0,
                      padding: 0,
                      fontWeight: 'bold',
                      fontSize: 17,
                    }}
                  />
                }
              />
              <TypoGraphy
                text={price.value}
                align={'left'}
                style={{
                  margin: 0,
                  padding: 0,
                  color: '#eb1111',
                  fontSize: 16,
                }}
              />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
}
