import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCartAction,
  alertDialogContentAction,
  getCartAction,
  getCommentsAction,
  getProductDetailsAction,
  removeCartAction,
  showAlertDialogAction,
  userTypingAction,
} from "../../../../redux/actions/reduxActions";
import { scrollToTopHandler } from "../../../../utils/shortHandUtils";
import FadeLoader from "react-spinners/FadeLoader";
import { getLocalStorage } from "../../../../utils/storageHandler";
import { Grid, Typography, Button } from "@material-ui/core";
import useStyles from "./productDetails.style";
import SlideContainer from "./SlideContainer";
import MiddleInfo from "./MiddleInfo";
import BottomBar from "./BottomBar";
import ExtraInfo from "./ExtraInfo";
import RecommendedPrds from "./RecommendedPrds";
import SearchIcon from "@material-ui/icons/Search";
import TypoGraphy from "../../../helpers/TypoGraphy";
import { useHistory } from "react-router-dom";

export default function ProductParent() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth?.authData);
  const [showConfetti, setShowConfetti] = useState(false);
  const [itemAction, setItemAction] = useState("none")
  const productState = useSelector((state) => state.products);
  const { cartFromServer, isCartReceived, isInFavorites, itemQuantity } =
    productState;
  const userInfos = useSelector((state) => state.userInfos);
  const {
    singleProduct,
    singleProductLoading,
    commentsList,
    commentsLoading,
    favoritesLoading,
    userFavorites,
  } = userInfos;
  const role = authData?.user?.role ?? "guest";
  const token = getLocalStorage("AUTH_JWT");
  const history = useHistory();

  useEffect(() => {
    dispatch(
      userTypingAction(
        window.location.pathname.slice(9, 10000000),
        "singleProductId"
      )
    );
    scrollToTopHandler();
    dispatch(getCommentsAction());
    dispatch(getProductDetailsAction());
    if (token) {
      if (role !== "guest") {
        dispatch(getCartAction());
      }
    }
  }, []);

  const increaseItem = () => {
    if (role === "guest") {
      dispatch(
        alertDialogContentAction(
          "دسترسی ندارید",
          "برای دسترسی به این بخش باید ثبت نام کنید",
          "برو به صفحه ثبت نام"
        )
      );
      dispatch(showAlertDialogAction(true));
    } else {
      dispatch(addCartAction(singleProduct.id));
      setItemAction("increase")
    }
  };
  const decreaseItem = () => {
    dispatch(removeCartAction(singleProduct.id));
    setItemAction("decrease")
  };

  useEffect(() => {
    if (itemAction === "increase") {
      if (itemQuantity === 0) {
        setShowConfetti(true);
        setTimeout(() => {
          setShowConfetti(false)
        }, 2000);
      }
    }
  }, [isCartReceived, itemQuantity, itemAction]);

  return (
    <div>
      {singleProductLoading ? (
        <Grid
          className={classes.loadingCircle}
          container
          justifyContent="center"
          alignItems="center"
        >
          <FadeLoader color="#C10F40" speedMultiplier={2} width={2} />
        </Grid>
      ) : (
        <div>
            <Grid container>
            
            <Grid xs={12} className={classes.searchGrid}>
              <Button
                fullWidth
                size="medium"
                style={{
                  marginBottom: ".5rem",
                  backgroundColor: "#f0f0f1",
                  // backgroundColor: "#E5E5E5",
                  border: "none",
                  outlined: "0",
                  width: "40%",
                  margin: "1rem 2rem",
                  padding: ".8rem",
                }}
                onClick={() => {
                  history.push("/search");
                }}
                className={classes.searchButton}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <TypoGraphy
                    text={"جستجو در رزگل"}
                    variant={"body2"}
                    style={{ color: "#979797" }}
                  />
                  <SearchIcon style={{ color: "#979797" }} fontSize="small" />
                </Grid>
              </Button>
            </Grid>
            <Grid item xs={12} lg={4} className={classes.imageSliderGrid}>
              <SlideContainer
                images={singleProduct?.images}
                classes={classes}
                data={singleProduct}
                role={role}
                dispatch={dispatch}
                isInFavorites={isInFavorites}
                qty={itemQuantity}
                cartFromServer={cartFromServer}
              />
            </Grid>
            <Grid item xs={12} lg={6} className={classes.dataGrid}>
              <MiddleInfo
                classes={classes}
                data={singleProduct}
                comments={commentsList}
                role={role}
              />
              <div className={classes.otherdata}>
                <RecommendedPrds classes={classes} />
                <ExtraInfo
                  data={singleProduct}
                  classes={classes}
                  comments={commentsList}
                  role={role}
                />
              </div>
            </Grid>
            <Grid item xs={12} lg={2}>
              <BottomBar
                classes={classes}
                data={singleProduct}
                role={role}
                isCartReceived={isCartReceived}
                qty={itemQuantity}
                increaseItem={increaseItem}
                  decreaseItem={decreaseItem}
                  showConfetti={showConfetti}
              />
            </Grid>
          </Grid>
          <Grid container>
            <div className={classes.otherdataDesktop}>
              <RecommendedPrds classes={classes} />
              <ExtraInfo
                data={singleProduct}
                classes={classes}
                comments={commentsList}
                role={role}
              />
            </div>
          </Grid>
        </div>
      )}
    </div>
  );
}
