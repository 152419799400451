import { connect } from 'react-redux';
import ShipClub from '../../components/layout/profile/ShipClub';
import {
  userTypingAction,
  skeletonLoadingStatusAction,
  getUserCouponsAction,
} from '../actions/reduxActions';

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.userInfos.userLoading,
    userTypings: state.userTypings,
    userInfos: state.userInfos,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    userTypingAction: (value, type) => {
      dispatch(userTypingAction(value, type));
    },
    skeletonLoadingStatusAction: (type) => {
      dispatch(skeletonLoadingStatusAction(type));
    },
    getUserCouponsAction: () => {
      dispatch(getUserCouponsAction());
    },
  };
};

const ClubContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShipClub);

export default ClubContainer;
