import { connect } from 'react-redux';
import UserOrders from '../../components/layout/profile/UserOrders';
import {
  editProfileAction,
  getUserAddressesAction,
  getUserDetailsAction,
  userTypingAction,
  skeletonLoadingStatusAction,
  getUserCouponsAction,
  getUserOrdersAction,
  getUserFavoritesAction,
  logoutAction,
  actionDialogHandler,
  getSingleOrderAction,
  drawerHandler,
  detectBrowserScroll,
} from '../actions/reduxActions';

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.userInfos.userLoading,
    profileMode: state.userTypings.profileMode,
    userTypings: state.userTypings,
    userInfos: state.userInfos,
    ordersAreLoading: state.userInfos.ordersAreLoading,
    config: state.appConfigs.configs,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    userTypingAction: (value, type) => {
      dispatch(userTypingAction(value, type));
    },
    getUserDetailsAction: () => {
      dispatch(getUserDetailsAction());
    },
    getUserAddressesAction: () => {
      dispatch(getUserAddressesAction());
    },
    editProfileAction: () => {
      dispatch(editProfileAction());
    },
    skeletonLoadingStatusAction: (type) => {
      dispatch(skeletonLoadingStatusAction(type));
    },
    getUserCouponsAction: () => {
      dispatch(getUserCouponsAction());
    },
    getUserOrdersAction: () => {
      dispatch(getUserOrdersAction());
    },
    getUserFavoritesAction: () => {
      dispatch(getUserFavoritesAction());
    },
    logoutAction: () => {
      dispatch(logoutAction());
    },
    actionDialogHandler: (open, type) => {
      dispatch(actionDialogHandler(open, type));
    },
    getSingleOrderAction: () => {
      dispatch(getSingleOrderAction());
    },
    drawerHandler: (open, type) => {
      dispatch(drawerHandler(open, type));
    },
  };
};

const UserOrdersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserOrders);

export default UserOrdersContainer;
