import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  loadingCircle: {
    height: "100vh",
  },
  searchGrid: {
    [theme.breakpoints.up("lg")]: {
      borderBottom: "1px solid #e0e0e2",
    },
    [theme.breakpoints.down("lg")]: {
      display: "none !important",
    },
  },
  carouselImageDiv: {
    [theme.breakpoints.up("lg")]: {
      height: "100%",
      width: "auto",
      padding: "0",
      borderRadius: theme.spacing(1),
    },
    height: "60vh",
    width: "100vw",
    objectFit: "cover",
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
  },
  carouselImg: {
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      height: "100%",
      borderRadius: theme.spacing(1),
    },
  },
  indicatorBtn: {
    color: "#cccccc",
    marginTop: theme.spacing(-5),
  },
  activeIndicatorBtn: {
    color: theme.palette.primary.dark,
    marginTop: theme.spacing(-5),
  },
  indicatorContainer: {
    marginTop: theme.spacing(-5),
  },
  imageSliderGrid: {
    [theme.breakpoints.up("lg")]: {
      padding: "1rem",
      height: "100%",
      width: "100%",
    },
  },
  dataGrid: {
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(2),
    },
  },
  carouselContainer: {
    [theme.breakpoints.up("lg")]: {
      borderRadius: theme.spacing(1),
      width: "100%",
    },
    height: "60vh",
    width: "100vw",
    position: "relative",
  },
  carousel: {
    [theme.breakpoints.up("lg")]: {
      height: "100%",
      "&>:nth-child(1)": {
        width: "auto",
        height: "100%",
      },
      "&>:nth-child(1)>div": {
        width: "auto",
        height: "100%",
      },
    },
  },
  actionContainer: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    margin: theme.spacing(0, 1),
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  actionBack: {
    position: "absolute",
    top: theme.spacing(1),
    left: 0,
    margin: theme.spacing(0, 1),
  },
  middleInfoContainer: {
    marginTop: theme.spacing(-5),
    borderTopLeftRadius: theme.spacing(3),
    borderTopRightRadius: theme.spacing(3),
    backgroundColor: "#ffffff",
    height: "max-content",
    position: "absolute",
    width: "100vw",
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      margin: "0 !important",
      marginTop: "0 !important",
      borderL: "1px solid",
      position: "unset",
      borderRadius: "0",
      boxShadow: "none",
    },
    boxShadow: "-2px -3px 13px -6px rgba(0,0,0,0.25)",
  },
  actionBtnIcon: {
    marginLeft: theme.spacing(0.8),
  },
  breadCrumbTypo: {
    color: "#81B5D2",
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.5em",
      margin: "0 4px",
    },
  },
  breadCrumbContainer: {
    display: "flex",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-start",
    },
    justifyContent: "center",
    width: "100%",
    marginTop: theme.spacing(1),
  },
  prdName: {
    marginTop: theme.spacing(0.3),
    [theme.breakpoints.up("lg")]: {
      borderBottom: "2px solid #e0e0e2",
    },
  },
  prdNameTypo: {
    fontWeight: "bold",
    [theme.breakpoints.up("lg")]: {
      textAlign: "right",
      fontSize: "2em",
    },
  },
  prdAvail: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-start",
      margin: "8px 0",
    },
  },
  divider: {
    margin: theme.spacing(0, 0.5),
  },
  rateContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rate: {
    color: "#e5aa17",
    fontSize: "0.8rem",
    marginLeft: theme.spacing(0.5),
  },
  actionBtnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-start",
    },
  },
  extraInfos: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      padding: theme.spacing(3, 1, 2, 1),
    },
  },

  extraBtn: {
    marginLeft: theme.spacing(2),
    width: "6rem",
  },
  extraBtnWithoutMargin: {
    width: "6rem",
  },
  emptyImage: {
    width: 100,
    height: 100,
    margin: theme.spacing(1, 1, 1, 1),
  },
  emptyContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingBottom: 20,
  },
  descriptionMsg: {
    marginRight: 10,
    marginLeft: 10,
    fontWeight: "bold",
    whiteSpace: "pre-wrap",
    lineHeight: "1.8em",
  },
  descriptionParent: {
    marginTop: theme.spacing(2),
    marginBottom: 80,
  },
  bottomBar: {
    [theme.breakpoints.up("lg")]: {
      position: "unset",
      display: "flex",
      flexDirection: "column",
      margin: "0",
      borderRadius: "0",
      boxShadow: "none",
      height: "100%",
    },
    top: "auto",
    bottom: 0,
    borderTopLeftRadius: theme.spacing(2),
    borderTopRightRadius: theme.spacing(2),
    // backgroundColor: "#FDCEDA",
    backgroundColor: "#ffffff",
    padding: theme.spacing(2),
    // margin: theme.spacing(1, 0),
  },
  bottomBarContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: theme.spacing(0, 0.5),
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: theme.spacing(2),
      border: "1px solid #e0e0e2",
      backgroundColor: "#f0f0f1",
      padding: theme.spacing(3, 1),
      height: "100%",
    },
  },
  priceGrid: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      marginBottom: theme.spacing(2),
      flexDirection: "column",
      flexGrow: "1",
      width: "100%",
      justifyContent: "space-around",
    },
  },
  priceTitle: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    fontSize: "1.4em",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  discountBox: {
    width: "max-content",
    height: "max-content",
    padding: theme.spacing(0.8, 1.2),
    color: "#ffffff",
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(2),
    fontWeight: "bold",
    textAlign: "center",
    fontSize: ".8rem",
    [theme.breakpoints.up("lg")]: {
      fontSize: "2em",
      padding: "1rem",
      display: "flex",
      borderRadius: "50%",
    },
    // border: "1px solid blue",
  },
  priceReg: {
    fontWeight: "bold",
    fontSize: "1rem",
    color: theme.palette.primary.main,
    textAlign: "center",
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.5em",
      margin: ".5rem",
      alignSelf: "flex-start",
    },
  },
  priceBox: {
    marginRight: theme.spacing(1),
  },
  realPriceReg: {
    textDecoration: "line-through",
    fontWeight: "bold",
    fontSize: "0.8rem",
    color: theme.palette.text.secondary,
    textAlign: "right",
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.2em",
      margin: ".5rem",
      alignSelf: "center",
    },
  },
  priceFrame: {
    [theme.breakpoints.up("lg")]: {
      flexGrow: "1",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
  cartIconBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: 100,
    border: `1px solid #cccccc`,
    // borderTopLeftRadius: theme.spacing(1),
    // borderBottomLeftRadius: theme.spacing(1),
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.5),
    backgroundColor: "#ffffff",
    height: 40,
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },
  goToCart: {
    // borderLeft: `1px solid #cccccc`,
    // color: theme.palette.primary.dark,
    // backgroundColor: theme.palette.primary.dark,
    height: 37,
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    padding: theme.spacing(0.4, 0.4, 0, 0),
  },
  ButtonWrapper: {
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
  cartItemsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "column",
      width: "100% !important",
    },
  },
  qty: {
    fontWeight: "bold",
  },
  goToCartBottom: {
    // color: "#ffffff",
    // fontSize: "1.1rem",
    borderRadius: `8px !important`,
    padding: theme.spacing(0.5),
    height: 37,
    marginLeft: "12px",
    backgroundColor: "#04c704",
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      margin: theme.spacing(0, 0, 1, 0),
    },
  },
  recommendTitle: {
    margin: "10px 10px 0px 0px",
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.5em",
      margin: "8px 0px",
    },
  },
  recommendPrdBtn: {
    [theme.breakpoints.up("lg")]: {
      flexGrow: "1",
      display: "flex",
    },
  },
  recommendPrdImg: {
    width: "80px",
    height: "80px",
    borderRadius: theme.spacing(1),
    objectFit: "cover",
    boxShadow: "2px 3px 15px 3px rgba(0,0,0,0.1)",
    [theme.breakpoints.up("lg")]: {
      width: "120px",
      height: "120px",
      // alignSelf: "flex-start",
      // justifySelf: "center",
    },
  },
  bottomBarWrapper: {
    margin: theme.spacing(8),
  },
  galleryImgParent: {
    width: "100%",
    // height: "100%",
    borderRadius: theme.spacing(1),
  },
  galleryDiv: {
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    gap: theme.spacing(1),
    overflow: "auto",
    marginTop: theme.spacing(2),
  },
  imageGalleryContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    alignContent: "center",
    flexDirection: "column",
  },
  otherdata: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  otherdataDesktop: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "block",
      width: "100%",
      padding: theme.spacing(2),
    },
  },
}));

export default useStyles;
