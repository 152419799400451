import CartContainer from "../redux/containers/CartContainer";
import ClubContainer from "../redux/containers/ClubContainer";
import CompleteOrderContainer from "../redux/containers/CompleteOrderContainer";
import FinalizePaymentContainer from "../redux/containers/FinalizePaymentContainer";
import HomeContainer from "../redux/containers/HomeContainer";
import LoginContainer from "../redux/containers/LoginContainer"
import ProfileContainer from "../redux/containers/ProfileContainer";
import SingleProductContainer from "../redux/containers/SingleProductContainer";
import UserOrdersContainer from "../redux/containers/UserOrdersContainer";
import OrderDetailsContainer from "../redux/containers/OrderDetailsContainer";
import DonePayment from "../components/layout/paymentpages/DonePayment";
import FailedPayment from "../components/layout/paymentpages/FailedPayment";
import SearchContainer from "../redux/containers/SearchContainer";
import ChargeAccountContainer from "../redux/containers/ChargeAccountContainer";
import AddressContainer from "../redux/containers/AddressContainer";
import TransactionContainer from "../redux/containers/TransactionsContainer";
import DrawerContainer from "../redux/containers/DrawerContainer";
import QRCodeScanner from "../components/layout/home/QRCodeScanner";
import ProductParent from "../components/layout/home/productDetails/ProductParent";
import BlogParent from "../components/layout/home/BlogParent";
import GiftCode from "../components/layout/home/GiftCode";

export const nestedRoutes = [
  {
    path: '/',
    component: HomeContainer,
    isExact: true,
  },
  {
    path: '/qrcode/:id',
    component: QRCodeScanner,
    isExact: true,
  },

  {
    path: '/cart',
    component: CartContainer,
    isExact: true,
  },
  {
    path: '/?category=:id',
    component: HomeContainer,
    isExact: true,
  },
  {
    path: '/transactions',
    component: TransactionContainer,
    isExact: true,
  },
  {
    path: '/#',
    component: DrawerContainer,
    isExact: true,
  },
  {
    path: '/charge-account',
    component: ChargeAccountContainer,
    isExact: true,
  },
  {
    path: '/addresses',
    component: AddressContainer,
    isExact: true,
  },
  {
    path: '/checkout',
    component: FinalizePaymentContainer,
    isExact: true
  },
  {
    path: '/confirm-basket',
    component: CompleteOrderContainer,
    isExact: true
  },
  {
    path: '/club',
    component: ClubContainer,
    isExact: true
  },
  {
    path: '/orders',
    component: UserOrdersContainer,
    isExact: true
  },

  {
    path: '/profile',
    component: ProfileContainer,
    isExact: true
  },
  {
    path: '/product/:id',
    component: ProductParent,
    isExact: true
  },
  {
    path: '/order/:id',
    component: OrderDetailsContainer,
    isExact: true
  },
  {
    path: '/login',
    component: LoginContainer,
    isExact: true,
  },
  {
    path: '/search',
    component: SearchContainer,
    isExact: true,
  },
  {
    path: '/paid-order',
    component: DonePayment,
    isExact: true
  },
  {
    path: '/failed-payment',
    component: FailedPayment,
    isExact: true
  },
  {
    path: '/blog',
    component: BlogParent,
    isExact: true
  },
  {
    path: '/gift-code',
    component: GiftCode,
    isExact: true
  },
];
