import * as storageHandler from "../../utils/storageHandler";

// const userInfoFromStorage = storageHandler?.getLocalStorage("USER_INFORMATION")
//   ? storageHandler?.getLocalStorage("USER_INFORMATION")
//   : {};

const userInfoFromStorage = JSON.parse(localStorage.getItem("USER_INFORMATION")) ?? {}

// const cartItemsFromStorage = storageHandler.getLocalStorage("CART_ITEMS")
//   ? storageHandler.getLocalStorage("CART_ITEMS")
//   : [];
const cartItemsFromStorage = JSON.parse(localStorage.getItem("CART_ITEMS")) ?? []


// const appConfigsFromStorage = storageHandler?.getLocalStorage("APP_CONFIGS")
//   ? storageHandler?.getLocalStorage("APP_CONFIGS")
//   : {};
const appConfigsFromStorage = JSON.parse(localStorage.getItem("APP_CONFIGS")) ?? {}


// const paymentFromSession = storageHandler.getSessionStorage("paymentURL")
//   ? storageHandler?.setSessionStorage("paymentURL")
//   : "";
const paymentFromSession = JSON.parse(sessionStorage.getItem("paymentURL")) ?? ""


// const uploadedImageIdFromStorage = storageHandler.getSessionStorage("UPLOADED_IMAGE_ID") ?
//     storageHandler.setSessionStorage("UPLOADED_IMAGE_ID") : ''

// const cartFromStorage = storageHandler?.getLocalStorage("CART")
//   ? storageHandler?.getLocalStorage("CART")
//   : [];
const cartFromStorage = JSON.parse(localStorage.getItem("CART")) ?? []


const initialState = {
  userInformation: { userData: userInfoFromStorage },
  userTypings: {
    reportCommentId: "",
    reportCommentReason: '',
    username: "",
    password: "",
    page: 1,
    limit: 20,
    profileMode: "whole",
    firstName: "",
    lastName: "",
    referrer: "",
    gender: "",
    birthday: null,
    orderTabsStatus: '["created","in_progress","ready","send"]',
    singleOrderId: "",
    couponCode: "",
    singleProductId: "",
    commentStar: 3,
    commentText: "",
    useCoupon: false,
    orderMsg: "",
    orderTime: "",
    orderDate: "",
    paymentMethod: "cash",
    priceToPay: 0,
    categoryToSearch: "",
    filterToSearch: "discount",
    typeProductSearch: "",
    isWebViewed: false,
    otpResendBtnDisable: true,
    submitDelivery: false,
    submitCommentLoading: false,
    addCommentResponse: "",
    userBirthdayValue: "",
  },
  appConfigs: {
    openDialog: false,
    alertDialogContent: { title: "", content: "", textButton: "" },
    skeletonType: "",
    actionDialog: { open: false, type: "" },
    infoDrawer: { open: false, type: "" },
    configs: appConfigsFromStorage,
    historyValue: "/",
    hasReachedBottom: false,
  },
  auth: {
    isSmsReceived: false,
    verifyMsg: "",
    verifySuccess: false,
    authData: userInfoFromStorage,
    loadingLoginButton: false,
    tempId: "",
  },
  products: {
    loadingProducts: false,
    homeProducts: [],
    categories: [],
    productDetails: [],
    gettingSearchProducts: true,
    searchedProducts: [],
    searchLoading: false,
    cartFromServer: [],
    isCartReceived: false,
    isInFavorites: false,
    itemQuantity: 0,
  },
  cart: { cartItems: cartFromStorage },
  userInfos: {
    userProfile: {},
    userAddresses: [],
    userLoading: true,
    userCoupons: [],
    userOrders: [],
    userFavorites: [],
    singleOrder: {},
    singleOrderLoading: true,
    singleProductLoading: true,
    singleProduct: {},
    commentsList: [],
    commentsLoading: true,
    addingCommentLoading: false,
    getAddressesLoading: true,
    getProfileLoading: true,
    favoritesLoading: false,
    ordersAreLoading: false,
    isCheckingCoupon: false,
    userTransactions: [],
    gettingTransactions: true,
  },
  mapStates: {
    zoom: 15,
    center: [32.646305292977814, 51.66759777069089],
    lat: 32.646305292977814,
    lng: 51.66759777069089,
    addressId: null,
    addressTitle: "",
    fullAddress: "",
    addressNumber: "",
    shippingPrice: "",
    addressLoading: false,
  },
  orderStates: {
    priceInvoice: "...",
    requestingPrice: true,
    payBtnLoading: false,
    paymentUrl: paymentFromSession,
    submittingOrder: false,
    submitOrderResponse: {},
    orderSubmitted: false,
    urlReceived: false,
    imageCompressionLoading: false,
    compressedImageFormData: {},
    uploadedImageId: "",
    hasUploadedImage: false,
    shouldUploadImage: false,
    zoneMsg: "",
    isInZone: true,
  },
};

export default initialState;
