import { connect } from 'react-redux';
import SearchParent from '../../components/layout/home/SearchParent';
import {
  getHomeItemsAction,
  getAppConfigsAction,
  getCategoriesAction,
  getProductDetailsAction,
  getProductsByCategoryAction,
  getAllProductsByTypeAction,
  skeletonLoadingStatusAction,
  userTypingAction,
  addToCartAction,
  searchProductsAction,
  userNavigationHistorySaver,
} from '../actions/reduxActions';
import * as storageHandler from '../../utils/storageHandler';

const mapStateToProps = (state, ownProps) => {
  return {
    products: state.products.searchedProducts,
    categories: storageHandler.getLocalStorage('CATEGORIES'),
    parentCategory: storageHandler.getLocalStorage('PARENT_CATEGORY'),
    userTypings: state.userTypings,
    gettingSearchProducts: state.products.gettingSearchProducts,
    searchLoading: state.products.searchLoading,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getHomeItemsAction: () => {
      dispatch(getHomeItemsAction());
    },
    getAppConfigsAction: () => {
      dispatch(getAppConfigsAction());
    },
    getCategoriesAction: () => {
      dispatch(getCategoriesAction());
    },
    getProductDetailsAction: () => {
      dispatch(getProductDetailsAction());
    },
    getProductsByCategoryAction: () => {
      dispatch(getProductsByCategoryAction());
    },
    getAllProductsByTypeAction: () => {
      dispatch(getAllProductsByTypeAction());
    },
    skeletonLoadingStatusAction: (type) => {
      dispatch(skeletonLoadingStatusAction(type));
    },
    userTypingAction: (value, type) => {
      dispatch(userTypingAction(value, type));
    },
    addToCartAction: (id, name, price, qty, image) => {
      dispatch(addToCartAction(id, name, price, qty, image));
    },
    searchProductsAction: () => {
      dispatch(searchProductsAction());
    },
    userNavigationHistorySaver: (history) => {
      dispatch(userNavigationHistorySaver(history));
    },
  };
};

const SearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchParent);

export default SearchContainer;
