import {
    Box,
    CircularProgress,
    Typography,
    Grid,
    List,
    ListItem,
    ListItemText,
    Divider,
    Fade
} from '@material-ui/core';
import React from 'react';
import emptyDescription from '../../../../static/image/emptyDescription.svg';
import TypoGraphy from '../../../helpers/TypoGraphy';

function CircularProgressWithLabel(
    props
) {
    return (
        <Box position='relative' display='inline-flex'>
            <CircularProgress
                variant='determinate'
                value={100}
                style={{ color: '#cccccc', position: 'absolute' }}
            />
            <CircularProgress variant='determinate' {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position='absolute'
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <Typography
                    variant='caption'
                    component='div'
                    color='textSecondary'
                    style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                    }}
                >{`${Math.round(props.realValue)}`}</Typography>
            </Box>
        </Box>
    );
}


export default function PropertiesParent({
    data,
    classes
}) {

    return (
        <Fade
            in
            timeout={1000}
        >
            <div
                className={classes.descriptionParent}
            >
                <List>
                    {data?.properties?.length === 0 ? (
                        <div
                            style={{
                                paddingBottom: 20,
                            }}
                        >
                            <Grid container justifyContent='center'>
                                <img
                                    loading="lazy"
                                    alt='emptyComment'
                                    src={emptyDescription}
                                    className={classes.emptyImage}
                                />
                            </Grid>
                            <TypoGraphy
                                color={'textSecondary'}
                                variant={'subtitle2'}
                                align={'center'}
                                text={'هیچ مشخصاتی ثبت نشده است'}
                            />
                        </div>
                    ) : (
                        <>
                            {data?.properties?.map((property, index) => (
                                <>
                                    {property?.group_type === 'title' ? (
                                        <TypoGraphy
                                            text={property.title}
                                            color='primary'
                                            align='right'
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: 18,
                                                marginRight: 12,
                                                marginTop: 10,
                                            }}
                                        />
                                    ) : (
                                        <ListItem key={index}>
                                            <Grid
                                                container
                                                justify='space-around'
                                                alignItems='center'
                                            >
                                                {property?.group_type === 'chart' &&
                                                    property?.max != null ? (
                                                    <ListItemText
                                                        primary={
                                                            <TypoGraphy
                                                                style={{
                                                                    fontSize: 15,
                                                                    fontWeight: 'bold',
                                                                }}
                                                                text={`${property.title} (${property.value_type})`}
                                                                align={'right'}
                                                                color={'textPrimary'}
                                                            />
                                                        }
                                                    />
                                                ) : (
                                                    <ListItemText
                                                        primary={
                                                            <TypoGraphy
                                                                style={{
                                                                    fontSize: 15,
                                                                    fontWeight: 'bold',
                                                                }}
                                                                text={`${property.title}`}
                                                                align={'right'}
                                                                color={'textPrimary'}
                                                            />
                                                        }
                                                    />
                                                )}

                                                {property?.group_type === 'chart' &&
                                                    property?.max != null ? (
                                                    <CircularProgressWithLabel
                                                        realValue={property?.value}
                                                        value={
                                                            property?.value <= 100
                                                                ? property?.value
                                                                : ((property?.value - 0) * 100) /
                                                                (property?.max - 0)
                                                        }
                                                    />
                                                ) : (
                                                    <ListItemText
                                                        primary={
                                                            <TypoGraphy
                                                                style={{
                                                                    fontSize: 15,
                                                                    fontWeight: 'bold',
                                                                }}
                                                                align={'left'}
                                                                color={'textSecondary'}
                                                                text={
                                                                    property?.value +
                                                                    (property?.value_type != null
                                                                        ? property?.value_type
                                                                        : '')
                                                                }
                                                            />
                                                        }
                                                    />
                                                )}
                                            </Grid>
                                        </ListItem>
                                    )}
                                    {property?.group_type !== 'title' && <Divider />}
                                </>
                            ))}
                        </>
                    )}
                </List>
            </div>
        </Fade>

    );
}
