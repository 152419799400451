import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  whiteInput: {
    backgroundColor: "#fff",
    color: "#888888 !important",
    border: "none",
    borderBottom: "0px",
    padding: "8px",
    borderRadius: "16px",
    fontSize: "1.2em",
    direction: "ltr !important",
    textAlign: "left !important",
    outline: "0",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
    "&:hover,&:focus,&:active,&:after,&:before": {
      border: "none !important",
      outline: "0 !important",
    },
  },
  lable: {
    color: "#888888",
    lineHeight: "inherit",
    alignSelf: "start",
    fontSize: "1.2em",
    fontWeight: "600",
    marginRight: ".5rem",
    display: "block",
  },
  inputDescription: {
    color: "#6D6D6D",
    lineHeight: "inherit",
    alignSelf: "start",
    fontSize: "1em",
    marginRight: ".5rem",
  },
  containedButton: {
    backgroundColor: "#04c704",
    width: "100%",
    borderRadius: "0px",
    color: "#fff",
    marginBottom: "1rem",
    fontSize: "1.2em",
    "&:hover,&:focus,&:active,&:after,&:before": {
      backgroundColor: "#04c704",
      border: "none",
      color: "#fff",
    },
    "&:disabled": {
      backgroundColor: "#04c704",
      border: "none",
      color: "#fff",
      opacity: 0.5,
    },
  },
  outlinedButton: {
    border: "2px solid #04c704",
    color: "#04c704",
    width: "100%",
    // marginBottom: "1rem",
    backgroundColor: "transparent",
    borderRadius: "0px",
    boxShadow: "none",
    "&:hover,&:focus,&:active,&:after,&:before": {
      backgroundColor: "transparent",
      border: "2px solid #04c704",
      color: "#04c704",
    },
  },
}));
export default useStyles;
