import {
  createStyles,
  makeStyles,
  Theme,
  Fab,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { useState, useEffect } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import TypoGraphy from '../../helpers/TypoGraphy';
import MaterialButton from '../../helpers/MaterialButton';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: 70,
      left: 5,
    },
  })
);

export default function PriceInfoFab(props) {
  const priceInvoice = props.priceInvoice.invoice;
  const [showPriceDrawer, setShowPriceDrawer] = useState(false);
  const classes = useStyles();

  const closeHandler = () => {
    setShowPriceDrawer(false);
  };

  const openHandler = () => {
    setShowPriceDrawer(true);
  };

  useEffect(() => {}, [priceInvoice]);

  const toTomanWithZero = (number) => {
    if (number) {
      return `${number.toLocaleString('fa-IR')} تومان`;
    }
  };

  const priceItems = [
    { name: 'کل', value: toTomanWithZero(priceInvoice.real_price) },
    {
      name: 'میزان تخفیف',
      value: toTomanWithZero(
        priceInvoice.discount !== 0 ? priceInvoice.discount : '0'
      ),
    },
    { name: 'قابل پرداخت', value: toTomanWithZero(priceInvoice.price) },
  ];

  return (
    <>
      <div className={classes.root}>
        <Fab onClick={openHandler} variant='extended' color='secondary'>
          جزئیات هزینه
        </Fab>
      </div>
      <Drawer
        PaperProps={{ square: false }}
        anchor={'bottom'}
        open={showPriceDrawer}
        onClose={closeHandler}
        style={{ zIndex: 300000 }}
      >
        <Grid container justifyContent='space-around' alignItems='center'>
          <TypoGraphy
            align={'right'}
            color={'textPrimary'}
            variant={'subtitle1'}
            text={'جزئیات هزینه سفارش'}
          />
          <MaterialButton
            btnType={'iconBtn'}
            icon={<CancelIcon />}
            color={'primary'}
            size={'small'}
            edge={'end'}
            onClick={closeHandler}
          />
        </Grid>
        <Divider />
        <List>
          {priceItems.map((item, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={
                  <TypoGraphy
                    text={item.name}
                    align={'right'}
                    color={
                      item.name === 'قابل پرداخت' ? 'primary' : 'textPrimary'
                    }
                    variant={'subtitle1'}
                  />
                }
                secondary={
                  <TypoGraphy
                    text={item.value}
                    align={'right'}
                    color={
                      item.name === 'قابل پرداخت'
                        ? 'secondary'
                        : 'textSecondary'
                    }
                    variant={'subtitle2'}
                  />
                }
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
}
