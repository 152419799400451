import React from "react";
import CatSteps from "./CatSteps";
import ExtraInfo from "./ExtraInfo";
import Name from "./Name";
import PrdAvailability from "./PrdAvailability";
import RecommendedPrds from "./RecommendedPrds";

export default function MiddleInfo({ classes, data, comments, role }) {
  return (
    <div className={classes.middleInfoContainer}>
      <CatSteps data={data} classes={classes} />
      <Name data={data} classes={classes} />
      <PrdAvailability data={data} classes={classes} comments={comments} />
      {/* <RecommendedPrds
                classes={classes}
            /> */}
      {/* <ExtraInfo
        data={data}
        classes={classes}
        comments={comments}
        role={role}
      /> */}
    </div>
  );
}
