import { useEffect, useState } from 'react';
import {
    makeStyles,
    Grid
} from '@material-ui/core';
import Header from './Header';
import DetailsTabs from './DetailsTabs';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CommentIcon from '@material-ui/icons/Comment';
import Products from './Products';
import Details from './Details';
import Polls from './Polls';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Delivery from './Delivery';
import ActionBtns from './ActionBtns';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import FadeLoader from "react-spinners/FadeLoader"

const useStyles = makeStyles((theme) => ({
    appBarBox: {
        flexGrow: 1
    },
    iconCallBox: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    callIcon: {
        color: "#054bad"
    },
    locIcon: {
        color: "#047516"
    },
    tabViewContainer: {
        width: "100vw"
    },
    litItmTxt: {
        textAlign: "right",
        marginRight: theme.spacing(1)
    },
    countDiv: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    countTxt: {
        textDecoration: "underline",
        marginLeft: theme.spacing(0.5)
    },
    totalCountDiv: {
        display: "flex",
        flexDirection: "column"
    },
    listItemSecondaryAction: {
        padding: "0px"
    },
    discountTxt: {
        color: "green"
    },
    shopTitle: {
        marginRight: theme.spacing(1)
    },
    litItmTxtWithoutMargin: {
        textAlign: "right"
    },
    mapImage: {
        borderRadius: theme.spacing(1)
    },
    address: {
        margin: theme.spacing(2)
    },
    listGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(2,1fr)"
    },
    speedDial: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    actionBtn: {
        position: 'fixed',
        bottom: theme.spacing(1),
        left: theme.spacing(1),
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center"
    },
    pollFrame: {
        width: "100%",
        height: "73vh",
        border: "none",
    },
    detailContainer: {
        width: "100%",
        height: "73vh",
        overflow: "auto"
    },
    loadingCircle: {
        height: "100vh"
    }
}));

const OrderDetailsParent = (props) => {
    const getSingleOrderAction = props.getSingleOrderAction;
    const skeletonLoadingStatusAction = props.skeletonLoadingStatusAction;
    const loading = props.loading;
    const data = props.singleOrder;
    const userTypingAction = props.userTypingAction;
    const classes = useStyles();
    const actionDialogHandler = props.actionDialogHandler;
    const requestOnlinePayment = props.requestOnlinePayment;
    const payBtnLoading = props.paymentStatus.payBtnLoading;
    const [openLittleMenu, setOpenLittleMenu] = useState(false)

    const littleMenuOpener = () => {
        setOpenLittleMenu(!openLittleMenu)
    }

    const [tabVal, setTabVal] = useState('products')

    const handleTabChanger = (event, newValue) => {
        setTabVal(newValue)
    }


    const detailsTabsArr = [
        { title: 'محصولات', value: 'products', icon: <ShoppingBasketIcon fontSize={"small"} />, show: true },
        { title: 'جزئیات', value: 'details', icon: <AssessmentIcon fontSize={"small"} />, show: true },
        { title: 'نظرسنجی', value: 'polls', icon: <CommentIcon fontSize={"small"} />, show: true },
        {
            title: 'پیک', value: 'delivery', icon: <LocalShippingIcon fontSize={"small"} />,
            show: data?.delivery?.deliver_address?.address?.length !== 0 &&
                data?.delivery?.deliver_address?.lat != null &&
                data?.delivery?.deliver_address?.lng != null
        },
    ]



    const actionBtns = [
        {
            title: 'پرداخت آنلاین',
            show: true,
            click: () => {
                requestOnlinePayment(data?.order_id);
            },
            disabled: payBtnLoading,
            icon: <AccountBalanceWalletIcon fontSize="small" />,
            color: "primary",
            style: { marginLeft: 10 }
        },
        {
            title: 'انتقال به اپ',
            show: data?.submit_by === 'app',
            click: () => {
                window.location.replace('/');
            },
            disabled: false,
            icon: <TouchAppIcon fontSize="small" />,
            color: "inherit"
        },

    ]

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        skeletonLoadingStatusAction('listSkeleton');
        userTypingAction(
            window.location.pathname.slice(7, 1000000),
            'singleOrderId'
        );
        getSingleOrderAction();
    }, []);

    return (
        <div>
            {loading ? (
                <Grid className={classes.loadingCircle} container justifyContent="center" alignItems="center">
                    <FadeLoader
                        color="#C10F40"
                        speedMultiplier={2}
                        width={2}
                    />
                </Grid>
            ) : (
                <>
                    <Header
                        data={data}
                        classes={classes}
                    />
                    <DetailsTabs
                        tabVal={tabVal}
                        detailsTabsArr={detailsTabsArr}
                        handleTabChanger={handleTabChanger}
                        classes={classes}
                    />
                    <div
                        className={classes.detailContainer}
                    >
                        {
                            tabVal === 'products'
                                ?
                                <Products
                                    data={data}
                                    classes={classes}
                                    actionDialogHandler={actionDialogHandler}
                                />
                                :
                                tabVal === 'details'
                                    ?
                                    <Details
                                        data={data}
                                        classes={classes}
                                    />
                                    :
                                    tabVal === 'polls'
                                        ?
                                        <Polls
                                            data={data}
                                            classes={classes}
                                        />
                                        : tabVal === 'delivery'
                                            ?
                                            <Delivery
                                                data={data}
                                                classes={classes}
                                            />
                                            :
                                            null
                        }
                    </div>
                    <ActionBtns
                        data={data}
                        classes={classes}
                        openLittleMenu={openLittleMenu}
                        littleMenuOpener={littleMenuOpener}
                        speedDialArr={actionBtns}
                    />
                </>
            )}

        </div>
    );
};

export default OrderDetailsParent;
