import { ACTION_CONSTANTS } from '../constants/actionConstants';

export default function addressReducer(state = {}, action) {
  const payload = action.payload;

  switch (action.type) {
    case ACTION_CONSTANTS.SET_MAP_CENTER:
      return {
        ...state,
        center: payload,
      };
    case ACTION_CONSTANTS.SET_MARKER_POSITION:
      return {
        ...state,
        lat: payload.lat,
        lng: payload.lng
      };
    case ACTION_CONSTANTS.SET_ADDRESS_ID:
      return {
        ...state,
        addressId: payload,
      };
    case ACTION_CONSTANTS.SET_ADDRESS_TITLE:
      return {
        ...state,
        addressTitle: payload,
      };
    case ACTION_CONSTANTS.SET_FULL_ADDRESS:
      return {
        ...state,
        fullAddress: payload,
      };
    case ACTION_CONSTANTS.SET_ADDRESS_NUMBER:
      return {
        ...state,
        addressNumber: payload,
      };
      case ACTION_CONSTANTS.SET_SHIPPING_PRICE: 
      return {
        ...state,
        shippingPrice: payload
      }
    default:
      return state;
  }
}
