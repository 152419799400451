import { ChangeEvent, useState, Fragment, useEffect } from 'react';
import {
  makeStyles,
  Theme,
  Tabs,
  Tab,
  Card,
  CardContent,
  Grid,
  Container,
  Button,
  Paper,
  Divider,
  useScrollTrigger,
} from '@material-ui/core';
import TypoGraphy from '../../helpers/TypoGraphy';
import priceRegex from '../../../utils/priceRegex';
import RedirectPages from '../../helpers/RedirectPages';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import stringLengthRegex from '../../../utils/stringLengthRegex';
import LoadingSkeleton from '../../helpers/LoadingSkeleton';
import emptyOrders from '../../../static/image/emptyOrders.svg';
import ordersIcon from '../../../static/image/orders.svg';
import ScrollToTop from '../../helpers/ScrollToTop';
import HeadsetIcon from '@material-ui/icons/Headset';
import { useHistory } from 'react-router-dom';
import GuestLoginPage from '../../helpers/GuestLoginPage';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  card: {
    overflow: 'hidden',
    width: '100%',
    borderRadius: theme.spacing(1),
    boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.2)',
    // border: '1px solid rgba(254,114,76,0.5)',
    marginTop: theme.spacing(2),
  },
  avatar: {
    padding: 5,
    // border: '1px solid rgba(254,114,76,0.5)',
    marginLeft: theme.spacing(1),
  },
  cardRoot: {
    marginBottom: theme.spacing(10),
  },
  divider: {
    margin: theme.spacing(1),
    height: theme.spacing(1),
  },
  img: {
    width: 60,
    height: 60,
    padding: 5,
    // // border: '1px solid rgba(254,114,76,0.5)',
    borderRadius: theme.spacing(1),
  },
  infoFlex: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
  },
  rateOrder: {
    width: '100%',
    backgroundColor: '#e0e0e0',
    borderRadius: theme.spacing(1),
  },
  paper: {
    flexGrow: 1,
  },
  emptyCartImage: {
    width: 100,
    height: 100,
    margin: theme.spacing(2),
  },
  contactBtn: {
    position: 'fixed',
    // top: '50%',
    left: 10,
    bottom: theme.spacing(8),
    // backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(1),
    // display: 'flex',
    // justifyContent: 'center',
    // flexDirection: 'column',
    zIndex: 300000,
  },
}));

const UserOrders = (props) => {
  const classes = useStyles();
  const userTypingAction = props.userTypingAction;
  const userTypings = props.userTypings;
  const tabValues = userTypings.orderTabsStatus;
  const getUserOrdersAction = props.getUserOrdersAction;
  const userOrders = props.userInfos.userOrders;
  const actionDialogHandler = props.actionDialogHandler;
  const getSingleOrderAction = props.getSingleOrderAction;
  const drawerHandler = props.drawerHandler;
  const loading = props.loading;
  const skeletonLoadingStatusAction = props.skeletonLoadingStatusAction;
  const ordersAreLoading = props.ordersAreLoading;
  const config = props.config;
  const detectBrowserScroll = props.detectBrowserScroll;
  const history = useHistory();
  let role = 'guest';
  if (props.auth.authData.user) {
    role = props.auth.authData.user.role;
  }
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectValue, setRedirectValue] = useState('');

  const handleTabsChange = (event, newValue) => {
    userTypingAction(newValue, 'orderTabsStatus');
    getUserOrdersAction();
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (role !== 'guest') {
      skeletonLoadingStatusAction('listSkeleton');
      getUserOrdersAction();
    }
  }, []);

  return (
    <div className={classes.root}>
      {role !== 'guest' ? (
        <>
          {ordersAreLoading === false ? (
            <>
              <Paper className={classes.paper}>
                <Tabs
                  value={tabValues}
                  onChange={handleTabsChange}
                  indicatorColor='primary'
                  textColor='primary'
                  centered
                >
                  {statusTabs.map((tab, i) => (
                    <Tab label={tab.name} value={tab.value} key={i} />
                  ))}
                </Tabs>
              </Paper>
              {userOrders.length === 0 ? (
                <div style={{ marginTop: 150 }}>
                  <Grid container justifyContent='center'>
                    <img
                      alt='emptyOrders'
                      src={emptyOrders}
                      className={classes.emptyCartImage}
                    />
                  </Grid>
                  <TypoGraphy
                    text={'سفارشی ثبت نشده است'}
                    align={'center'}
                    variant={'h6'}
                    color={'textSecondary'}
                  />
                  <Divider className={classes.divider} />
                </div>
              ) : (
                <div className={classes.cardRoot}>
                  {userOrders.map((order, index) => (
                    <Container key={index}>
                      <Card className={classes.card}>
                        <CardContent>
                          <Grid
                            container
                            justifyContent='space-between'
                            alignItems='center'
                          >
                            <img
                              src={ordersIcon}
                              alt={'orderPic'}
                              className={classes.img}
                            />
                            <div>
                              <div className={classes.infoFlex}>
                                <Grid
                                  container
                                  justifyContent='center'
                                  alignItems='center'
                                >
                                  <DateRangeOutlinedIcon
                                    fontSize={'small'}
                                    color={'disabled'}
                                  />
                                  <TypoGraphy
                                    text={order.created_at.slice(0, 10)}
                                    variant={'overline'}
                                    align={'right'}
                                    color={'textSecondary'}
                                  />
                                </Grid>
                                <Grid
                                  container
                                  justifyContent='center'
                                  alignItems='center'
                                >
                                  <QueryBuilderOutlinedIcon
                                    fontSize={'small'}
                                    color={'disabled'}
                                  />
                                  <TypoGraphy
                                    text={order.created_at.slice(11, 16)}
                                    variant={'overline'}
                                    align={'right'}
                                    color={'textSecondary'}
                                  />
                                </Grid>
                              </div>
                              <div style={{ width: '100%' }}>
                                <Grid
                                  container
                                  justifyContent='center'
                                  alignItems='center'
                                >
                                  <LocationOnOutlinedIcon
                                    fontSize={'small'}
                                    color={'disabled'}
                                  />
                                  <TypoGraphy
                                    text={stringLengthRegex(
                                      order.delivery.deliver_address.address
                                    )}
                                    variant={'overline'}
                                    align={'right'}
                                    color={'textSecondary'}
                                  />
                                </Grid>
                              </div>
                            </div>
                          </Grid>
                          <br />

                          <Grid
                            container
                            justifyContent='space-between'
                            alignItems='center'
                          >
                            <TypoGraphy
                              text={'وضعیت سفارش'}
                              variant={'subtitle2'}
                              align={'right'}
                              color={'textPrimary'}
                            />
                            <TypoGraphy
                              text={order.status_title}
                              variant={'subtitle2'}
                              align={'right'}
                              color={'primary'}
                            />
                          </Grid>

                          <Grid
                            container
                            justifyContent='space-between'
                            alignItems='center'
                          >
                            <TypoGraphy
                              text={'پرداختی'}
                              variant={'subtitle1'}
                              align={'right'}
                              color={'textPrimary'}
                            />
                            <TypoGraphy
                              text={
                                order.invoice.price <= 0
                                  ? 'توافقی'
                                  : priceRegex(
                                      order.invoice.price,
                                      'withCurrency'
                                    )
                              }
                              variant={'subtitle1'}
                              align={'right'}
                              color={'secondary'}
                            />
                          </Grid>
                        </CardContent>
                        <Grid
                          style={{ padding: '0 5px 5px 5px' }}
                          container
                          justifyContent='space-between'
                          alignItems='center'
                        >
                          <Button
                            variant={'contained'}
                            size={'large'}
                            color='secondary'
                            onClick={() => {
                              history.push(`/order/${order.order_id}`);
                            }}
                          >
                            جزئیات سفارش
                          </Button>
                          <Button
                            variant={'contained'}
                            size={'large'}
                            color='primary'
                            onClick={() => {
                              userTypingAction(order.order_id, 'singleOrderId');
                              getSingleOrderAction();
                              drawerHandler(true, 'orderDetails');
                            }}
                          >
                            مشاهده فاکتور
                          </Button>
                        </Grid>
                      </Card>
                    </Container>
                  ))}
                  {userTypings.orderTabsStatus ===
                  '["created","in_progress","ready","send"]' ? (
                    <a href={`tel:${config?.config?.support?.follow_up}`}>
                          <Button
                            size="large"
                            variant="contained"
                            color="inherit"
                            className={classes.contactBtn}
                          >
                            <HeadsetIcon style={{ color: "#1B1A1A", fontWeight: "bold"}} />
                            <TypoGraphy
                              text={'پیگیری سفارش'}
                              variant={'subtitle2'}
                              align={'center'}
                              style={{ color: "#1B1A1A"}}
                            />
                          </Button>
                    </a>
                  ) : null}
                </div>
              )}
              <ScrollToTop />
            </>
          ) : (
            <div style={{ marginTop: 30 }}>
              <LoadingSkeleton />
            </div>
          )}
        </>
      ) : (
        <div className='guestLoginPage'>
          <GuestLoginPage />
        </div>
      )}
      {shouldRedirect === true && <RedirectPages redirect={redirectValue} />}
    </div>
  );
};

const statusTabs = [
  { name: 'سفارشات فعلی', value: '["created","in_progress","ready","send"]' },
  { name: 'سفارشات قبلی', value: '["completed","canceled"]' },
];

export default UserOrders;
