import React, { useState } from "react";
import { createStyles, makeStyles, Theme, Grid } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { useAppSelector } from "../../../redux/setup/hooks";
import { useSelector } from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory, withRouter, useLocation } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import RedirectPages from "../../helpers/RedirectPages";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginBottom: theme.spacing(8),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

const AppBarContainer = (props) => {
  const appConfigs = useSelector((state) => state.appConfigs);
  const { historyValue } = appConfigs;
  const classes = useStyles();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [valueToRedirect, setValueToRedirect] = useState("");
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;

  const handleBackIconClick = () => {
    // setValueToRedirect(historyValue);
    // setShouldRedirect(true);
    // setTimeout(() => {
    //   setShouldRedirect(false);
    //   setValueToRedirect('');
    // }, 100);
    // history.goBack();
    history.go(-1);
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" color={"inherit"} elevation={1}>
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography align="center" variant="h6" color="textSecondary">
              {pathname.match("/search") ? (
                <>جستجوی محصول</>
              ) : pathname.match("/profile") ? (
                <>پروفایل</>
              ) : pathname.match("/confirm-basket") ? (
                <>تایید سبد خرید</>
              ) : pathname.match("/checkout") ? (
                <>تایید نهایی سفارش</>
              ) : pathname.match("/charge-account") ? (
                <>شارژ حساب</>
              ) : pathname.match("/addresses") ? (
                <>لیست آدرس ها</>
              ) : pathname.match("/transactions") ? (
                <>لیست تراکنش ها</>
              ) : pathname.match("/blog") ? (
                <>نمونه کارها</>
              ) : pathname.match("/gift-code") ? (
                <>اعمال کوپن تخفیف</>
              ) : null}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleBackIconClick}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </Grid>
        </Toolbar>
      </AppBar>
      {shouldRedirect === true && <RedirectPages redirect={valueToRedirect} />}
    </div>
  );
};

export default withRouter(AppBarContainer);
