import { ACTION_CONSTANTS } from '../constants/actionConstants';

export default function cartReducer(state = {}, action) {
  switch (action.type) {
    case ACTION_CONSTANTS.CART_ADD_ITEM:
      const item = action.payload;
      const existItem = state.cartItems.find((x) => x.id === item.id);
      if (existItem) {
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.id === existItem.id ? { ...item, qty: (x.qty += 1) } : x
          ),
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item],
        };
      }

    case ACTION_CONSTANTS.CART_INCREASE_ITEMS:
      return {
        ...state,
        cartItems: state.cartItems.map((x) =>
          x.id === action.payload
            ? {
                id: x.id,
                name: x.name,
                price: x.price,
                qty: (x.qty += 1),
                image: x.image,
              }
            : x
        ),
      };

    case ACTION_CONSTANTS.CART_DECREASE_ITEMS:
      const isZero = state.cartItems.find((x) => x.id === action.payload);
      if (isZero.qty <= 1) {
        return {
          ...state,
          cartItems: state.cartItems.filter((x) => x.id !== action.payload),
        };
      } else {
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.id === action.payload
              ? {
                  id: x.id,
                  name: x.name,
                  price: x.price,
                  qty: (x.qty -= 1),
                  image: x.image,
                }
              : x
          ),
        };
      }

    case ACTION_CONSTANTS.CART_REMOVE_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter((x) => x.id !== action.payload),
      };

    case ACTION_CONSTANTS.CART_CLEAR_ITEMS:
      return {
        ...state,
        cartItems: [],
      };

    default:
      return state;
  }
}
