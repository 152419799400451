import { Button, IconButton } from "@material-ui/core";

export default function MaterialButton(props) {
  const variant = props.variant;
  const color = props.color;
  const disabled = props.disabled;
  const href = props.href;
  const onClick = props.onClick;
  const text = props.text;
  const icon = props.icon;
  const ClassName = props.className;
  const style = props.style;
  const size = props.size;
  const btnType = props.btnType;
  const edge = props.edge;
  const fullWidth = props.fullWidth;
  return (
    <>
      {btnType === "button" ? (
        <Button
          variant={variant}
          color={color}
          disabled={disabled}
          href={href}
          onClick={onClick}
          className={ClassName}
          style={style}
          size={size}
          fullWidth={fullWidth}
        >
          {icon}&nbsp;{text}
        </Button>
      ) : (
        <IconButton
          color={color}
          disabled={disabled}
          href={href}
          onClick={onClick}
          className={ClassName}
          style={style}
          size={size}
          edge={edge}
        >
          {icon}
        </IconButton>
      )}
    </>
  );
}
