import { AppBar, Avatar, Box, Container, Grid, Toolbar, Typography, IconButton } from '@material-ui/core'
import React from 'react'
import CallIcon from '@material-ui/icons/Call';
import LocationOnIcon from '@material-ui/icons/LocationOn';

export default function Header({ data, classes }) {

    const openShopLocation = () => {
        const url = "https://maps.google.com/?q=" + data?.shop?.full_address?.lat + "," + data?.shop?.full_address?.lng;
        window.open(url);

    }


    return (
        <AppBar position='static' color="inherit" elevation={1}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box className={classes.appBarBox}>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <div
                                onClick={
                                    () => {
                                        const social_link = data?.shop?.social_link
                                        if (social_link) {
                                            window.open(social_link)
                                        }
                                    }
                                }
                                className={classes.iconCallBox}>
                                <Avatar
                                    src={data?.shop?.logo}
                                    variant='rounded'
                                />
                                <Typography
                                    className={classes.shopTitle}
                                    variant="subtitle2"
                                    align="center"
                                    color="textPrimary"
                                >
                                    {data?.shop?.name}
                                </Typography>

                            </div>
                            <div className={classes.iconCallBox}>
                                <IconButton
                                    href={`tel:${data?.shop?.number[0]?.number}`}
                                    className={classes.callIcon}
                                    size='medium'
                                    edge="end"
                                >
                                    <CallIcon />
                                </IconButton>
                                <IconButton
                                    onClick={openShopLocation}
                                    size='medium'
                                    edge="end"
                                    className={classes.locIcon}
                                >
                                    <LocationOnIcon />
                                </IconButton>
                            </div>

                        </Grid>

                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}
