import { useEffect, useState, forwardRef } from "react";
import LoadingSkeleton from "../../helpers/LoadingSkeleton";
import HomeChild from "./HomeChild";
import {
  Container,
  Theme,
  makeStyles,
  Grid,
  TextField,
  Button,
  Slide,
  Grow,
  Dialog,
  DialogContent,
  CircularProgress,
  Fade,
  IconButton,
  Typography,
} from "@material-ui/core";
import HomeAppBar from "./HomeAppBar";
import withAuth from "../../../utils/withAuth";
import { useLocation } from "react-router";
import TypoGraphy from "../../helpers/TypoGraphy";
import emptyProducts from "../../../static/image/emptyProducts.svg";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import loginSelectionSplash from "../../../static/image/loginSelectionSplash.jpg";
// import Lottie from 'react-lottie';
import guestLottie from "../../../static/lottie/guestLogin.json";
import RedirectPages from "../../helpers/RedirectPages";
// import Carousel from "react-material-ui-carousel";
import flowerApartment from "../../../static/image/guestLoginImages/flowerApartment.jpg";
import flowerBag from "../../../static/image/guestLoginImages/flowerBag.jpg";
import flowerBox from "../../../static/image/guestLoginImages/flowerBox.jpg";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CheckIcon from "@material-ui/icons/Check";
import { BurstModeTwoTone } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { getCartAction } from "../../../redux/actions/reduxActions";

const defaultOptions = {
  loop: true,
  autoplay: true,

  animationData: guestLottie,
  // rendererSettings: {
  //   preserveAspectRatio: 'xMidYMid slice'
  // }
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(10),
    margin: "0px 5px 0px 5px",
  },
  emptyCartImage: {
    width: 100,
    height: 100,
  },
  loginSelectionSplash: {
    width: "100%",
    height: 450,
  },
  guestBtnContainer: {
    position: "absolute",
    bottom: "5%",
  },
  imgContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translate(-50%, 0%)",
    marginTop: theme.spacing(5),
  },
  guestLoading: {
    height: "100vh",
    zIndex: 99999,
    backgroundColor: theme.palette.secondary.dark,
    color: "white",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  searchButton: {
    borderRadius: "8px !important",
    border: "none !important",
    outline: "0 !important",
  },
}));

const HomeParent = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  // const userNavigationHistorySaver = props.userNavigationHistorySaver;
  const loading = props.products.loadingProducts;
  const products = props.products.homeProducts;
  const getHomeItemsAction = props.getHomeItemsAction;
  const getAppConfigsAction = props.getAppConfigsAction;
  const skeletonType = props.skeletonLoadingStatusAction;
  const getGuestToken = props.getGuestToken;
  const locationHistory = props.locationHistory;
  const auth = props.auth;
  const history = useHistory();
  const [indexOfCarousel, setIndexOfCarousel] = useState(0);
  const [showSignUpBtn, setShowSignUpBtn] = useState(false);
  const [carouselDirection, setCarouselDirection] = useState("left");
  const [showLoginSelection, setShowLoginSelection] = useState(false);
  const [isGettingGuestToken, setIsGettingGuestToken] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectValue, setRedirectValue] = useState("");
  const guestLoginImageArray = [
    { name: "flowerApartment", img: flowerApartment, key: 0 },
    { name: "flowerBag", img: flowerBag, key: 1 },
    { name: "flowerBox", img: flowerBox, key: 2 },
  ];
  const token = localStorage.getItem("AUTH_JWT");

  const dots = [{ key: 0 }, { key: 1 }, { key: 2 }];

  useEffect(() => {
    if (auth?.authData?.user?.role === "customer") {
      dispatch(getCartAction());
    }
    if (localStorage.getItem("AUTH_JWT")) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      // userNavigationHistorySaver(location.pathname + location.search);
      setShowLoginSelection(false);
      skeletonType("homePage");
      getHomeItemsAction();
      getAppConfigsAction();
    } else {
      // setShowLoginSelection(true);
      // dispatch(getGuestToken());
      getGuestToken();
    }
  }, [location.pathname, location.search]);

  return (
    <>
      {token ? (
        <>
          {loading === false ? (
            <>
              <HomeAppBar
                auth={auth}
                locationHistory={locationHistory}
                // userNavigationHistorySaver={userNavigationHistorySaver}
              />

              {products.length === 0 ? (
                <div style={{ marginTop: 200 }}>
                  <Grid container justifyContent="center" alignItems="center">
                    <img
                      className={classes.emptyCartImage}
                      src={emptyProducts}
                      alt="emptyProducts"
                    />
                  </Grid>
                  <TypoGraphy
                    text={"محصولی یافت نشد"}
                    align={"center"}
                    style={{ margin: "10px 10px 5px 0" }}
                    variant={"h6"}
                    color={"primary"}
                  />
                </div>
              ) : (
                <div className={classes.container}>
                  <Grow timeout={2000} in mountOnEnter unmountOnExit>
                    <Button
                      fullWidth
                      size="medium"
                      variant="outlined"
                      style={{
                        marginBottom: ".5rem",
                        backgroundColor: "#E5E5E5",
                        // backgroundColor: "#E5E5E5",
                      }}
                      onClick={() => {
                        history.push("/search");
                      }}
                      className={classes.searchButton}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <TypoGraphy
                          text={"جستجو در رزگل"}
                          variant={"body2"}
                          style={{ color: "#979797" }}
                        />
                        <SearchIcon
                          style={{ color: "#979797" }}
                          fontSize="small"
                        />
                      </Grid>
                    </Button>
                  </Grow>
                  {products.map((product, index) => (
                    <HomeChild
                      key={index}
                      products={product}
                      locationHistory={locationHistory}
                      // userNavigationHistorySaver={userNavigationHistorySaver}
                    />
                  ))}
                </div>
              )}
              {shouldRedirect === true && (
                <RedirectPages redirect={redirectValue} />
              )}
            </>
          ) : (
            <LoadingSkeleton />
          )}
        </>
      ) : (
        <div className={classes.guestLoading}>
          <Grid container justifyContent="center" alignItems="center">
            <CircularProgress
              style={{ color: "#F47497", textAlign: "center" }}
            />
          </Grid>
          <br />
          <Typography variant="h6" align="center">
            لطفا منتظر بمانید
          </Typography>
        </div>
      )}
    </>
  );
};

export default HomeParent;
