import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import rootReducers from './rootReducers';
import initialState from './initialState';
// import { loadState, saveState } from './localStorage';

//const persistedState = loadState()
const middleware = [thunk, logger];

const store = createStore(
    rootReducers,
    initialState,
    // { ...initialState, ...persistedState },
    composeWithDevTools(applyMiddleware(...middleware)),
);

// store.subscribe(() => {
//     saveState(store.getState())
// })

// export type RootState = ReturnType<typeof store.getState>
// export type AppDispatch = typeof store.dispatch

export default store;