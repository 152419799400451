import { useEffect, useState } from "react";
import { Button } from "@material-ui/core";

export default function OtpCounter(props) {
  const [showBtn, setShowBtn] = useState(false);

  const userTypingAction = props.userTypingAction;

  const countdownForResendCode = (timeLeft) => {
    const element = document.getElementById("countdown");
    if (element) {
      var downTime = setInterval(function () {
        if (timeLeft <= 0) {
          clearInterval(downTime);
          userTypingAction(false, "setResendDis");
          element.innerHTML = "";
        } else {
          element.innerHTML = `<p class="countdownText">${timeLeft} ثانیه</p>`;
        }
        timeLeft -= 1;
      }, 1000);
    }
    return timeLeft;
  };

  useEffect(() => {
    countdownForResendCode(120);
  }, []);

  return <div id="countdown" />;
}
