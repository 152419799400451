import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(1),
    width: "100%",
    height: "fit-content",
  },
  card: {
    overflow: "hidden",
    borderRadius: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      height: theme.spacing(40),
    },
    height: theme.spacing(25),
    boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.2)",
    margin: "0px !important",
  },
  cardAction: {
    height: "100%",
    borderRadius: theme.spacing(1),
  },
  cardMedia: {
    width: "100%",
    height: "90%",
    objectFit: "cover",
    backgroundRepeat: "no-repeat",
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  },
  CardContent: {
    position: "absolute",
    bottom: "0",
    right: "0",
    left: "0",
    padding: ".5rem",
    backgroundColor: "#ffffff",
    borderRadius: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minHeight: "20%",
    // [theme.breakpoints.up("md")]: {
    //   height: "30%",
    // },
    // [theme.breakpoints.down("md")]: {
    //   height: "25%",
    // },
    paddingBottom: ".5rem !important",
    boxShadow: "-2px -3px 13px -6px rgba(0,0,0,0.1)",
  },
  name: {
    fontSize: 14,
    fontWeight: "bold",
    display: "block",
    textAlign: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "90%",
  },
  container: {
    position: "relative",
    borderRadius: theme.spacing(1),
    width: "100%",
    height: "100%",
  },
  priceContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "right",
    fontSize: "14px !important",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "90%",
    alignSelf: "center",
    justifySelf: "center",
    fontWeight: "bold",
  },
  priceInfoInnerGrid: {
    marginLeft: theme.spacing(2),
  },
  priceInfoOuterGrid: {
    position: "relative",
  },
  price: {
    position: "absolute",
    bottom: 20,
    left: -5,
    fontSize: 14,
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
  currency: {
    position: "absolute",
    top: -25,
    left: 35,
    fontSize: 12,
    fontWeight: "bold",
  },
  priceInfoBackground: {
    backgroundColor: "white",
    borderRadius: 5,
    position: "absolute",
    bottom: 5,
    left: 5,
    width: 80,
    height: 40,
    boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.2)",
  },
  soldOutIcon: {
    position: "absolute",
    left: 15,
    top: 8,
    zIndex: 30000,
  },
  badgeContainer: {
    width: "100%",
    backgroundColor: "transparent",
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    display: "flex",
    alignItems: "center",
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  },
  DiscountBadge: {
    backgroundColor: "#EC1651",
    padding: "2px 8px",
    width: "max-content",
    maxWidth: "90%",
    color: "#fff",
    borderRadius: theme.spacing(1),
    margin: "8px",
    fontSize: "1em",
  },
  RateBadge: {
    backgroundColor: "#E5AA17",
    color: "#ffffff !important",
    padding: "2px 8px",
    width: "max-content",
    maxWidth: "90%",
    borderRadius: theme.spacing(1),
    margin: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  productPriceText: {
    color: "#888888",
    fontSize: "12px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "90%",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
  },
}));
export default useStyles;
