import { makeStyles, Theme, Grid } from '@material-ui/core';
import MaterialButton from '../../helpers/MaterialButton';
import TypoGraphy from '../../helpers/TypoGraphy';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0, 1, 0),
    borderTop: '1px solid #ccc',
    width: '100%',
  },
  discountBtn: {
    marginTop: theme.spacing(1),
  },
}));

export default function SelectCoupon(props) {
  const classes = useStyles();
  const drawerHandler = props.drawerHandler;

  const addCoupon = () => {
    drawerHandler(true, 'addCoupon');
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent={'space-around'} alignItems={'baseline'}>
        <TypoGraphy
          text={'کد تخفیف دارید؟'}
          color={'textPrimary'}
          variant={'subtitle1'}
        />
        <MaterialButton
          btnType={'button'}
          text={'وارد کردن'}
          size={'small'}
          variant={'outlined'}
          color={'secondary'}
          onClick={addCoupon}
          className={classes.discountBtn}
        />
      </Grid>
    </div>
  );
}
