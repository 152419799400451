import Swal from 'sweetalert2';
import * as storageHandlers from './storageHandler';

export const handleAxiosErrors = (error) => {
    if (error?.toJSON()?.message === 'Network Error') {
        return httpAlerts(titles.noInternet, titles.ok, 'Network Error');
    }
    else if (error.response === undefined) {
        return httpAlerts(titles.unexpected, titles.ok, 'unexpected');
    } else {
        if (error.code === 'ECONNABORTED') {
            return httpAlerts(titles.connErr, titles.refreshPage, error.code);
        }
        if (error.response.status === 500) {
            return httpAlerts(
                titles.fiveHundred,
                titles.refreshPage,
                error.response.status
            );
        }
        if (error.response.status === 401 || error.response.status === 403) {
            return httpAlerts(
                titles.fourHundred,
                titles.goToLogin,
                error.response.status
            );
        }
    }
};

export const httpAlerts = (title, text, code) => {
    Swal.fire({
        text: `${title}`,
        icon: 'error',
        confirmButtonText: `${text}`,
        allowOutsideClick: false,
    }).then((result) => {
        if (result.isConfirmed) {
            if (code === 'ECONNABORTED') {
                window.location.reload();
            }
            else if (code === 'Network Error') {
                window.location.reload();
            }
            else if (code === 'unexpected') {
                window.location.reload();
            } else if (code === 500) {
                window.location.reload();
            } else {
                storageHandlers.removeLocalStorage('USER_INFORMATION');
                storageHandlers.removeLocalStorage('CART_ITEMS');
                storageHandlers.removeLocalStorage('APP_CONFIGS');
                storageHandlers.removeLocalStorage('AUTH_JWT');
                storageHandlers.removeLocalStorage('POPULAR_PRODUCTS');
                storageHandlers.removeLocalStorage('APP_CONFIGS');
                window.location.replace('/login')

            }
        }
    });
};

const titles = {
    fiveHundred: 'خطای سرور',
    fourHundred: 'توکن شما معتبر نیست',
    connErr: 'خطای اتصال به اینترنت',
    goToLogin: 'برو به صفحه لاگین',
    refreshPage: 'صفحه را رفرش کن',
    unexpected: 'برنامه با خطای غیرمنتظره مواجه شده است',
    ok: 'متوجه شدم',
    noInternet:
        'اینترنت شما متصل نیست',
};
