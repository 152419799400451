import TypoGraphy from "../../../helpers/TypoGraphy";
import StarIcon from "@material-ui/icons/Star";

export const DiscountBadge = ({ content, styles }) => {
  return <TypoGraphy text={content} variant={"body2"} className={styles} />;
};

export const RateBadge = ({ rate, styles }) => {
  return (
    <span className={styles}>
      <StarIcon
        style={{
          color: "#ffffff",
          width: 13,
          height: 13,
          marginLeft: "2px",
        }}
      />
      <TypoGraphy text={rate} variant={"body2"} />
    </span>
  );
};
