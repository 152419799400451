import { useState } from "react";
import {
  makeStyles,
  Typography,
  Card,
  CardActionArea,
  Theme,
  Grow,
  Badge,
  withStyles,
  createStyles,
  Grid,
  Link,
} from "@material-ui/core";
import stringLengthRegex from "../../../utils/stringLengthRegex";
import RedirectPages from "../../helpers/RedirectPages";
import priceRegex from "../../../utils/priceRegex";
import { useDispatch } from "react-redux";
import {
  userTypingAction,
  getProductDetailsAction,
} from "../../../redux/actions/reduxActions";
import { useHistory } from "react-router-dom";
import emptyImage from "../../../static/image/emptyImage.svg";
import StarIcon from "@material-ui/icons/Star";
import TypoGraphy from "../../helpers/TypoGraphy";
import soldOutIcon from "../../../static/image/soldOut.png";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(1),
  },
  card: {
    overflow: "hidden",
    borderRadius: theme.spacing(2),
    width: theme.spacing(25),
    height: theme.spacing(20),
    boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.2)",
  },
  cardMedia: {
    width: "100%",
    objectFit: "cover",
    backgroundRepeat: "no-repeat",
    height: theme.spacing(15),
    borderRadius: theme.spacing(2),
  },
  name: {
    fontSize: 14,
    fontWeight: "bold",
  },
  container: {
    position: "relative",
  },
  priceInfoInnerGrid: {
    marginLeft: theme.spacing(2),
  },
  priceInfoOuterGrid: {
    position: "relative",
  },
  price: {
    position: "absolute",
    bottom: 20,
    left: -5,
    fontSize: 14,
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
  currency: {
    position: "absolute",
    top: -25,
    left: 35,
    fontSize: 12,
    fontWeight: "bold",
  },
  priceInfoBackground: {
    backgroundColor: "white",
    borderRadius: 5,
    position: "absolute",
    bottom: 5,
    left: 5,
    width: 80,
    height: 40,
    // border: '1px solid orange',
    boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.2)",
  },
  nameGrid: {
    padding: theme.spacing(0, 1, 1, 1),
  },
  soldOutIcon: {
    position: "absolute",
    left: 15,
    top: 8,
    zIndex: 30000,
  },
}));

const StyledBadge = withStyles((theme) =>
  createStyles({
    badge: {
      right: 30,
      top: 20,
      padding: "0 4px",
      backgroundColor: "#800021",
    },
  })
)(Badge);

export default function PopularProductsList(props) {
  const classes = useStyles();
  const item = props.product;
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectValue, setRedirectValue] = useState("");

  return (
    <Link
      href={`/product/${item.id}`}
      underline="none"
      onClick={(event) => {
        event.preventDefault();
      }}
    >
      <Grow in={true} timeout={2000}>
        <div
          className={classes.root}
          onClick={() => {
            history.push(`/product/${item.id}`);
          }}
        >
          <Card className={classes.card}>
            <CardActionArea>
              {item.limit === 0 && (
                <img
                  src={soldOutIcon}
                  alt="soldOutIcon"
                  width="30"
                  className={classes.soldOutIcon}
                  height="30"
                />
              )}
              <div className={classes.container}>
                <StyledBadge
                  badgeContent={
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <StarIcon
                        style={{
                          color: "#facc02",
                          width: 13,
                          height: 13,
                        }}
                      />
                      <TypoGraphy
                        text={item.rate}
                        color={"textPrimary"}
                        variant={"body2"}
                        style={{ color: "#ffffff", marginLeft: 1 }}
                      />
                    </Grid>
                  }
                  id="discount-badge"
                >
                  <img
                    alt="product"
                    className={classes.cardMedia}
                    src={item.image}
                    onError={(event) => {
                      event.target.src = emptyImage;
                    }}
                  />
                </StyledBadge>
                <div className={classes.priceInfoInnerGrid}>
                  <div className={classes.priceInfoBackground} />
                  <div className={classes.priceInfoOuterGrid}>
                    {item.price <= 0 ? (
                      <Typography color="textPrimary" className={classes.price}>
                        توافقی
                      </Typography>
                    ) : (
                      <>
                        <Typography
                          color="textPrimary"
                          className={classes.price}
                        >
                          {priceRegex(item.price, "withoutCurrency")}
                        </Typography>
                        <Typography
                          color="textPrimary"
                          className={classes.currency}
                        >
                          تومان
                        </Typography>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className={classes.nameGrid}>
                <Typography className={classes.name}>
                  {stringLengthRegex(item.name)}
                </Typography>
              </div>
            </CardActionArea>
          </Card>
        </div>
      </Grow>
      {shouldRedirect === true && <RedirectPages redirect={redirectValue} />}
    </Link>
  );
}
