import { makeStyles, Theme, Grid } from '@material-ui/core';
import MaterialButton from '../../helpers/MaterialButton';

import FileUploadComponent from './FileUploadComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    // marginBottom: theme.spacing(5),
  },
  discountBtn: {
    marginTop: theme.spacing(1),
  },
}));

export default function OrderAddAddressMsg(props) {
  const classes = useStyles();
  const drawerHandler = props.drawerHandler;

  const addMsgAction = () => {
    drawerHandler(true, 'addMsg');
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent={'center'} alignItems={'center'}>
        <MaterialButton
          btnType={'button'}
          text={'درج توضیحات'}
          size={'small'}
          variant={'outlined'}
          color={'secondary'}
          onClick={addMsgAction}
          fullWidth={true}
          style={{ margin: 10 }}
        />
      </Grid>
    </div>
  );
}
