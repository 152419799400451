export const ACTION_CONSTANTS = {
  TYPE_USERNAME: "TYPE_USERNAME",
  TYPE_PASSWORD: "TYPE_PASSWORD",
  RECEIVE_OTP_SMS: "RECEIVE_OTP_SMS",
  RECEIVE_OTP_SMS_REQUEST: "RECEIVE_OTP_SMS_REQUEST",
  VALIDATE_OTP_SMS: "VALIDATE_OTP_SMS",
  VALIDATE_OTP_SMS_REQUEST: "VALIDATE_OTP_SMS_REQUEST",
  RESEND_OTP_SMS: "RESEND_OTP_SMS",
  USER_LOGOUT: "USER_LOGOUT",
  GET_APP_CONFIGS: "GET_APP_CONFIGS",
  GET_HOME_ITEMS: "GET_HOME_ITEMS",
  GET_HOME_ITEMS_REQUEST: "GET_HOME_ITEMS_REQUEST",
  GET_CATEGORIES: "GET_CATEGORIES",
  GET_PRODUCT_DETAILS: "GET_PRODUCT_DETAILS",
  GET_PRODUCTS_BY_CATEGORY: "GET_PRODUCTS_BY_CATEGORY",
  GET_ALL_PRODUCTS_BY_TYPE: "GET_ALL_PRODUCTS_BY_TYPE",
  SHOW_ALERT_DIALOG: "SHOW_ALERT_DIALOG",
  ALERT_DIALOG_CONTENT: "ALERT_DIALOG_CONTENT",
  SKELETON_LOADING_STATUS: "SKELETON_LOADING_STATUS",
  CART_ADD_ITEM: "CART_ADD_ITEM",
  CART_CLEAR_ITEMS: "CART_CLEAR_ITEMS",
  CART_REMOVE_ITEM: "CART_REMOVE_ITEM",
  CART_INCREASE_ITEMS: "CART_INCREASE_ITEMS",
  CART_DECREASE_ITEMS: "CART_DECREASE_ITEMS",
  GET_USER_DETAILS: "GET_USER_DETAILS",
  EDIT_USER: "EDIT_USER",
  GET_USER_ADDRESSES: "GET_USER_ADDRESSES",
  GET_USER_ADDRESS_REQUEST: "GET_USER_ADDRESS_REQUEST",
  SINGLE_PRODUCT_REQUEST: "SINGLE_PRODUCT_REQUEST",
  ADD_ADDRESS: "ADD_ADDRESS",
  GET_COUPONS: "GET_COUPONS",
  GET_COUPONS_REQUEST: "GET_COUPONS_REQUEST",
  GET_USER_ORDERS: "GET_USER_ORDERS",
  GET_FAVORITES: "GET_FAVORITES",
  PROFILE_MODE: "PROFILE_MODE",
  USER_FIRST_NAME: "USER_FIRST_NAME",
  USER_LAST_NAME: "USER_LAST_NAME",
  USER_REFERRER: "USER_REFERRER",
  USER_GENDER: "USER_GENDER",
  USER_BIRTHDAY: "USER_BIRTHDAY",
  EDIT_PROFILE_LOADING: "EDIT_PROFILE_LOADING",
  SET_MAP_CENTER: "SET_MAP_CENTER",
  SET_MARKER_POSITION: "SET_MARKER_POSITION",
  SET_ADDRESS_ID: "SET_ADDRESS_ID",
  SET_ADDRESS_TITLE: "SET_ADDRESS_TITLE",
  SET_FULL_ADDRESS: "SET_FULL_ADDRESS",
  SET_ADDRESS_NUMBER: "SET_ADDRESS_NUMBER",
  ORDER_TABS_STATUS: "ORDER_TABS_STATUS",
  SET_SINGLE_ORDER_ID: "SET_SINGLE_ORDER_ID",
  ACTION_DIALOG_HANDLER: "ACTION_DIALOG_HANDLER",
  GET_SINGLE_ORDER: "GET_SINGLE_ORDER",
  DRAWER_HANDLER: "DRAWER_HANDLER",
  COPY_COUPON_CODE: "COPY_COUPON_CODE",
  GET_PRODUCT_DETAILS_REQUEST: "GET_PRODUCT_DETAILS_REQUEST",
  GET_PRODUCT_DETAILS_SUCCESS: "GET_PRODUCT_DETAILS_SUCCESS",
  SET_SINGLE_PRODUCT_ID: "SET_SINGLE_PRODUCT_ID",
  GET_COMMENTS_REQUEST: "GET_COMMENTS_REQUEST",
  GET_COMMENTS_SUCCESS: "GET_COMMENTS_SUCCESS",
  ADD_COMMENTS_REQUEST: "ADD_COMMENTS_REQUEST",
  ADD_COMMENTS_SUCCESS: "ADD_COMMENTS_SUCCESS",
  COMMENT_STAR: "COMMENT_STAR",
  COMMENT_TEXT: "COMMENT_TEXT",
  GET_PRICE_REQUEST: "GET_PRICE_REQUEST",
  GET_PRICE_SUCCESS: "GET_PRICE_SUCCESS",
  SET_SHIPPING_PRICE: "SET_SHIPPING_PRICE",
  TYPE_ORDER_MSG: "TYPE_ORDER_MSG",
  USE_COUPON: "USE_COUPON",
  VALIDATE_COUPON_REQUEST: "VALIDATE_COUPON_REQUEST",
  VALIDATE_COUPON_SUCCESS: "VALIDATE_COUPON_SUCCESS",
  SET_ORDER_TIME: "SET_ORDER_TIME",
  SET_ORDER_DATE: "SET_ORDER_DATE",
  SELECT_PAYMENT_METHOD: "SELECT_PAYMENT_METHOD",
  SET_PRICE_TO_PAY: "SET_PRICE_TO_PAY",
  REQUEST_PAYMENT_REQUEST: "REQUEST_PAYMENT_REQUEST",
  REQUEST_PAYMENT_SUCCESS: "REQUEST_PAYMENT_SUCCESS",
  GET_USER_DETAILS_REQUEST: "GET_USER_DETAILS_REQUEST",
  SUBMIT_ORDER_REQUEST: "SUBMIT_ORDER_REQUEST",
  SUBMIT_ORDER_SUCCESS: "SUBMIT_ORDER_SUCCESS",
  ADD_TO_FAVORITE_REQUEST: "ADD_TO_FAVORITE_REQUEST",
  ADD_TO_FAVORITE_SUCCESS: "ADD_TO_FAVORITE_SUCCESS",
  REMOVE_FROM_FAVORITE_REQUEST: "REMOVE_FROM_FAVORITE_REQUEST",
  REMOVE_FROM_FAVORITE_SUCCESS: "REMOVE_FROM_FAVORITE_SUCCESS",
  GET_FAVORITES_REQUEST: "GET_FAVORITES_REQUEST",
  HANDLE_MSG_CHIPS: "HANDLE_MSG_CHIPS",
  ERROR_PAGE: "ERROR_PAGE",
  SET_CATEGORY_TO_SEARCH: "SET_CATEGORY_TO_SEARCH",
  SET_FILTER_TO_SEARCH: "SET_FILTER_TO_SEARCH",
  SEARCH_PRODUCTS_REQUEST: "SEARCH_PRODUCTS_REQUEST",
  SEARCH_PRODUCTS: "SEARCH_PRODUCTS",
  PRODUCT_NAME_SEARCH: "PRODUCT_NAME_SEARCH",
  GET_USER_ORDERS_REQUEST: "GET_USER_ORDERS_REQUEST",
  GET_ORDER_DETAILS_REQUEST: "GET_ORDER_DETAILS_REQUEST",
  // ADD_TO_CART_REQUEST : 'ADD_TO_CART_REQUEST',
  // ADD_TO_CART_SUCCESS : 'ADD_TO_CART_SUCCESS',
  // REMOVE_FROM_CART_REQUEST : 'REMOVE_FROM_CART_REQUEST',
  // REMOVE_FROM_CART_SUCCESS : 'REMOVE_FROM_CART_SUCCESS',
  UPDATE_CART_REQUEST: "UPDATE_CART_REQUEST",
  UPDATE_CART_SUCCESS: "UPDATE_CART_SUCCESS",
  GET_CART_REQUEST: "GET_CART_REQUEST",
  GET_CART_SUCCESS: "GET_CART_SUCCESS",
  IS_WEB_VIEWED: "IS_WEB_VIEWED",
  OTP_RESEND_DISABLE: "OTP_RESEND_DISABLE",
  REQUEST_GUEST_TOKEN: "REQUEST_GUEST_TOKEN",
  USER_NAVIGATION_HISTORY: "USER_NAVIGATION_HISTORY",
  GET_TRANSACTIONS_REQUEST: "GET_TRANSACTIONS_REQUEST",
  GET_TRANSACTIONS_SUCCESS: "GET_TRANSACTIONS_SUCCESS",
  SEND_FCM_TOKEN: "SEND_FCM_TOKEN",
  CHECK_LOCATION_ZONE: "CHECK_LOCATION_ZONE",
  CHECK_HOW_MUCH_IN_CART: "CHECK_HOW_MUCH_IN_CART",
  CHECK_IF_IN_FAVORITES: "CHECK_IF_IN_FAVORITES",
  IMAGE_COMPRESSION_LOADING: "IMAGE_COMPRESSION_LOADING",
  IMAGE_COMPRESSION: "IMAGE_COMPRESSION",
  UPLOAD_IMAGE_REQUEST: "UPLOAD_IMAGE_REQUEST",
  UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
  DETECT_GOOGLE_BOT: "DETECT_GOOGLE_BOT",
  CANCEL_IMAGE_UPLOAD: "CANCEL_IMAGE_UPLOAD",
  SHOULD_UPLOAD_PHOTO: "SHOULD_UPLOAD_PHOTO",
  DETECT_BROWSER_SCROLL: "DETECT_BROWSER_SCROLL",
  SHOULD_NOT_UPLOAD_PHOTO: "SHOULD_NOT_UPLOAD_PHOTO",
  REQUEST_ONLINE_PAYMENT_REQUEST: "REQUEST_ONLINE_PAYMENT_REQUEST",
  REQUEST_ONLINE_PAYMENT_SUCCESS: "REQUEST_ONLINE_PAYMENT_SUCCESS",
  GET_QR_CODE_TOKEN: "GET_QR_CODE_TOKEN",
  HAS_DELIVERY_TYPE: "HAS_DELIVERY_TYPE",
  ORDER_COMMENT_REQUEST: "ORDER_COMMENT_REQUEST",
  ORDER_COMMENT_SUCCESS: "ORDER_COMMENT_SUCCESS",
  SET_USER_BIRTHDAY: "SET_USER_BIRTHDAY",
  SET_COUPON_GIFT: "SET_COUPON_GIFT",
  REPORT_COMMENT_REASON: "REPORT_COMMENT_REASON",
  REPORT_COMMENT_REQUEST: "REPORT_COMMENT_REQUEST",
  SET_REPORT_COMMENT_ID: "SET_REPORT_COMMENT_ID",
};
