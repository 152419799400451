import React from "react";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { Fab } from "@material-ui/core";

export default function ActionBtns({
  data,
  classes,
  openLittleMenu,
  littleMenuOpener,
  speedDialArr,
}) {
  return (
    <div className={classes.actionBtn}>
      {speedDialArr
        .filter((speed) => speed.show === true)
        .map((sp, index) => (
          <Fab
            size="medium"
            key={index}
            variant="extended"
            onClick={() => {
              sp.click();
            }}
            color={sp.color}
            disabled={sp.disabled}
            style={sp.style}
          >
            {sp.icon}
            {sp.title}
          </Fab>
        ))}
    </div>
  );
}
