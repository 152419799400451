import { ACTION_CONSTANTS } from "../constants/actionConstants";

export default function userInfosReducer(state = {}, action) {
  const payload = action.payload;
  switch (action.type) {
    case ACTION_CONSTANTS.GET_COUPONS_REQUEST:
      return {
        ...state,
        couponsLoading: true,
      };
    case ACTION_CONSTANTS.GET_COUPONS:
      return {
        ...state,
        userLoading: false,
        userCoupons: payload.data,
        couponsLoading: false,
      };
    case ACTION_CONSTANTS.GET_USER_DETAILS_REQUEST:
      return {
        ...state,
      };
    case ACTION_CONSTANTS.GET_USER_DETAILS:
      return {
        ...state,
        userProfile: payload.data,
        userLoading: false,
        getProfileLoading: false,
      };
    case ACTION_CONSTANTS.GET_USER_ADDRESS_REQUEST:
      return {
        ...state,
        addressLoading: true,
      };
    case ACTION_CONSTANTS.GET_USER_ADDRESSES:
      return {
        ...state,
        userAddresses: payload.data,
        getAddressesLoading: false,
        addressLoading: false,
      };
    case ACTION_CONSTANTS.GET_USER_ORDERS_REQUEST:
      return {
        ...state,
        ordersAreLoading: true,
      };
    case ACTION_CONSTANTS.GET_USER_ORDERS:
      return {
        ...state,
        userOrders: payload.data,
        userLoading: false,
        ordersAreLoading: false,
      };
    case ACTION_CONSTANTS.ADD_TO_FAVORITE_SUCCESS:
    case ACTION_CONSTANTS.REMOVE_FROM_FAVORITE_SUCCESS:
      return {
        ...state,
        favoritesLoading: false,
      };
    case ACTION_CONSTANTS.ADD_TO_FAVORITE_REQUEST:
    case ACTION_CONSTANTS.REMOVE_FROM_FAVORITE_REQUEST:
    case ACTION_CONSTANTS.GET_FAVORITES_REQUEST:
      return {
        ...state,
        favoritesLoading: true,
      };
    case ACTION_CONSTANTS.GET_FAVORITES:
      return {
        ...state,
        userFavorites: payload.data,
        userLoading: false,
        favoritesLoading: false,
      };
    case ACTION_CONSTANTS.GET_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        singleOrderLoading: true,
      };
    case ACTION_CONSTANTS.GET_SINGLE_ORDER:
      return {
        ...state,
        singleOrder: payload.data,
        singleOrderLoading: false,
      };
    case ACTION_CONSTANTS.GET_PRODUCT_DETAILS_REQUEST:
      return {
        ...state,
        singleProductLoading: true,
      };
    case ACTION_CONSTANTS.GET_COMMENTS_REQUEST:
      return {
        ...state,
        // singleProductLoading: true,
      };
    case ACTION_CONSTANTS.GET_COMMENTS_SUCCESS:
      return {
        ...state,
        commentsList: payload.data,
        commentsLoading: false,
      };
    case ACTION_CONSTANTS.GET_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        singleProductLoading: false,
        singleProduct: payload.data,
      };
    case ACTION_CONSTANTS.ADD_COMMENTS_REQUEST:
      return {
        ...state,
        addingCommentLoading: true,
      };
    case ACTION_CONSTANTS.ADD_COMMENTS_SUCCESS:
      return {
        ...state,
        addingCommentLoading: false,
      };
    case ACTION_CONSTANTS.VALIDATE_COUPON_REQUEST:
      return {
        ...state,
        isCheckingCoupon: true,
      };
    case ACTION_CONSTANTS.VALIDATE_COUPON_SUCCESS:
      return {
        ...state,
        isCheckingCoupon: false,
      };
    case ACTION_CONSTANTS.GET_TRANSACTIONS_REQUEST:
      return {
        ...state,
        gettingTransactions: true,
      };
    case ACTION_CONSTANTS.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        gettingTransactions: false,
        userTransactions: payload.data,
      };
    default:
      return state;
  }
}
