import { createTheme } from "@material-ui/core/styles";
import { faIR } from "@material-ui/core/locale";
import { useSelector } from 'react-redux';

// const appPallet = () => {
//   // const appConfigs = useSelector((state) => state.appConfigs.configs.config.palette);
//   return  {
//       primary: {
//         main: "#ed1651",
//         dark: "#C10F40",
//         light: "#F47497",
//       },
//       secondary: {
//         main: "#B5063C",
//         dark: "#680322",
//         light: "#E6084C",
//       },
//       text: {
//         primary: "#30354B",
//         secondary: "#58628A",
//       },
//     };
// };


// console.log("AppPallet", appPallet())

const theme = createTheme(
  {
    overrides: {
      MuiButton: {
        root: {
          borderRadius: "8px !important",
        },
      },
      MuiFab: {
        root: {
          borderRadius: "8px !important",
        }
      }
    }, 
    direction: "rtl",
    // palette: appPallet(),
    
    palette: {
      primary: {
        main: "#ed1651",
        dark: "#C10F40",
        light: "#F47497",
      },
      secondary: {
        main: "#B5063C",
        dark: "#680322",
        light: "#E6084C",
      },
      text: {
        primary: "#30354B",
        secondary: "#58628A",
      },
    },
    typography: {
      fontFamily: "Vazir",
    },
    breakpoints: {
      values: {
        xxs: 0, // small phone
        xs: 300, // phone
        sm: 600, // tablets
        md: 900, // small laptop
        lg: 1200, // desktop
        xl: 1536, // large screens
      },
    },
  },
  faIR
);

export default theme;
