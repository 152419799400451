import { ThemeProvider, CssBaseline } from '@material-ui/core';
import Routes from './router/Routes';
import theme from './style/theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  userTypingAction,
  sendFCMToken,
  detectGoogleBotAction,
} from './redux/actions/reduxActions';
import {
  initializeFirebase,
  requestPermission,
  receiveMessage,
  registerServiceWorker,
} from './push-notification';
// import { useAppSelector, useAppDispatch } from './redux/setup/hooks';

function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { authData }= auth;
  let role = 'guest';
  if (authData.user) {
    role = authData.user.role;
  }

  useEffect(() => {
    dispatch(detectGoogleBotAction());
    // registerServiceWorker();
    // initializeFirebase();
    // requestPermission();
    // receiveMessage();
    // if (localStorage.getItem('AUTH_JWT')) {
    //   if (role !== 'guest') {
    //     dispatch(sendFCMToken());
    //   }
    // }
    if (navigator.userAgent.includes('wv')) {
      dispatch(userTypingAction(true, 'isWebViewed'));
    } else {
      dispatch(userTypingAction(false, 'isWebViewed'));
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer />
      <Routes />
    </ThemeProvider>
  );
}

export default App;
