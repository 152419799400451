import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Grow,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  Theme,
  createStyles,
  makeStyles,
  useScrollTrigger,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  alertDialogContentAction,
  getHomeItemsAction,
  logoutAction,
  showAlertDialogAction,
} from "../../../redux/actions/reduxActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AccountCircle from "@material-ui/icons/AccountCircle";
import AppBar from "@material-ui/core/AppBar";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FilterIcon from '@material-ui/icons/Filter';
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MenuIcon from "@material-ui/icons/Menu";
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import ReceiptIcon from "@material-ui/icons/Receipt";
import RedirectPages from "../../helpers/RedirectPages";
import ScrollToTop from "../../helpers/ScrollToTop";
import SearchIcon from "@material-ui/icons/Search";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import appbarIcon from "../../../static/image/rosegol logo final.svg";

// import { TransitionProps } from '@material-ui/core/transitions';


// import { useAppSelector } from '../../../redux/setup/hooks';


const drawerWidth = 240;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} timeout={300} />;
});

const menuItems = [
  {
    name: "اطلاعات کاربری",
    value: "/profile",
    icon: <AccountCircle color="secondary" />,
  },
  {
    name: "شارژ حساب",
    value: "/charge-account",
    icon: <AccountBalanceWalletIcon color="secondary" />,
  },
  {
    name: "تاریخچه تراکنش ها",
    value: "/transactions",
    icon: <ReceiptIcon color="secondary" />,
  },
  {
    name: "لیست آدرس ها",
    value: "/addresses",
    icon: <LocationOnIcon color="secondary" />,
  },
  {
    name: "درباره ما",
    value: "aboutUs",
    icon: <InfoIcon color="secondary" />,
  },
  {
    name: "تماس با ما",
    value: "contactUs",
    icon: <PermContactCalendarIcon color="secondary" />,
  },
  {
    name: "نمونه کارها",
    value: "portfolio",
    icon: <FilterIcon color="secondary" />,
  },
  {
    name: "ثبت شکایت ",
    value: "SubmittingAComplaint",
    icon: <QuestionAnswer color="secondary" />,
  },
  // {
  //   name: "بلاگ",
  //   value: "blog",
  //   icon: <QuestionAnswer color="secondary" />,
  // },
  {
    name: "خروج",
    value: "logout",
    icon: <ExitToAppIcon color="secondary" />,
  },
];

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      paddingBottom: theme.spacing(10),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),

      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
    appbarIcon: {
      width: 40,
      height: 40,
    },
    dialogContent: {
      padding: "0px !important",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: "100%",
    },
    button: {
      border: "1px solid #ff005c",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "4px 16px",
      borderRadius: "8px",
      
      "&:hover": {
        color: "#fff",
        backgroundColor: "#ff005c",
      },
    },
  })
);

function ShowOnScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  return (
    <>
      {trigger ? (
        <Slide timeout={500} direction="right" in={trigger}>
          {children}
        </Slide>
      ) : null}
    </>
  );
}

export default function HomeAppBar(props) {
  const { window } = props;
  const location = useLocation();
  const classes = useStyles();
  const auth = props.auth;
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(false);
  const appConfigs = useSelector((state) => state.appConfigs);
  const { configs } = appConfigs;

  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const goToSearch = () => {
    history.push(`/search`);
  };

  const goToBlog = () => {
    history.push(`/blog`);
  };

  const goToLogin = () => {
    history.push(`/login`);
  };

  useEffect(() => {}, [location.search]);

  const listenToScroll = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

 

  const getItem =(item)=>{
    switch (item.value) {
      case "logout":
        return (
          <ListItem button onClick={() => { dispatch(logoutAction()) }}>
            <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography align="right">
                     {item.name}
                    </Typography>
                  }
                  />
            <ArrowBackIosIcon style={{ color: "#9d9d9d" }} />
          </ListItem>
        )

       case "blog":
        return (
          <ListItem>
             <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography align="right">
                    <Link
                            target="_blank"
                            underline="none"
                            href={"http://blog.rosegol.app"}
                            color={"inherit"}
                          >
                            {item.name}
                          </Link>
                    </Typography>
                  }
                  />
            <ArrowBackIosIcon style={{ color: "#9d9d9d" }} />
          </ListItem>
        )

      case "aboutUs":
        return (
          <ListItem>
             <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography align="right">
                    <Link
                            target="_blank"
                            underline="none"
                            href={"https://blog.rosegol.app/about/"}
                            color={"inherit"}
                          >
                            {item.name}
                          </Link>
                    </Typography>
                  }
                  />
            <ArrowBackIosIcon style={{ color: "#9d9d9d" }} />
          </ListItem>
        )

      case "contactUs":
        return (
          <ListItem >
             <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography align="right">
                    <Link
                            target="_blank"
                            underline="none"
                            href={"https://blog.rosegol.app/contanct/"}
                            color={"inherit"}
                          >
                            {item.name}
                            </Link>
                    </Typography>
                  }
                  />
            <ArrowBackIosIcon style={{ color: "#9d9d9d" }} />
          </ListItem>
        )

      case "portfolio":
        return (
           <ListItem>
             <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography align="right">
                    <Link
                            target="_blank"
                            underline="none"
                            href={"https://blog.rosegol.app/gallary/"}
                            color={"inherit"}
                          >
                            {item.name}
                            </Link>
                    </Typography>
                  }
                  />
            <ArrowBackIosIcon style={{ color: "#9d9d9d" }} />
          </ListItem>
        )

      case "SubmittingAComplaint":
        return (
          <ListItem>
             <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography align="right">
                    <Link
                      target="_blank"
                      underline="none"
                      href={"https://blog.rosegol.app/complaint/"}
                      color={"inherit"}
                      >
                        {item.name}
                      </Link>
                    </Typography>
                  }
                  />
            <ArrowBackIosIcon style={{ color: "#9d9d9d" }} />
          </ListItem>
        )
    
      default:
        return(
          <ListItem button onClick={() => { history.push(item.value) }}>
             <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography align="right">
                    {item.name}
                    </Typography>
                  }
                  />
            <ArrowBackIosIcon style={{ color: "#9d9d9d" }} />
          </ListItem>
        )
    }

  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" color={"inherit"} elevation={1}>
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <IconButton
              edge="end"
              size="medium"
              style={{ margin: 0, padding: 0 }}
              onClick={openDialog}
              color="secondary"
            >
              <MenuIcon />
            </IconButton>
            <div
              style={{
                flexGrow: "1",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              {auth?.user?.role !== "customer" && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{
                    padding: 1,
                  }}
                  onClick={goToLogin}
                  className={classes.button}
                >
                  ورود
                </Button>
              )}
              <Link
                target="_blank"
                underline="none"
                href={"http://blog.rosegol.app"}
                color="primary"
                className={classes.button}
              >
                بلاگ
              </Link>
              {/* <Button
                variant="outlined"
                size="small"
                color="primary"
                style={{
                  padding: 1,
                }}
                onClick={goToBlog}
                className={classes.button}
              >
                 بلاگ
              </Button> */}
              {auth?.shop?.slogan && (
                <Typography
                  variant="overline"
                  color="primary"
                  align="center"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {auth?.shop?.slogan}
                </Typography>
              )}
            </div>
            {!listenToScroll ? (
              <Grow in timeout={500}>
                <img
                  alt="appbarIcon"
                  className={classes.appbarIcon}
                  src={appbarIcon}
                />
              </Grow>
            ) : null}

            <ShowOnScroll {...props}>
              <IconButton
                onClick={goToSearch}
                size="small"
                edge="end"
                color="primary"
              >
                <SearchIcon />
              </IconButton>
            </ShowOnScroll>
          </Grid>
        </Toolbar>
      </AppBar>

      <ScrollToTop />
      <Dialog
        style={{
          maxWidth: 300,
        }}
        onClose={closeDialog}
        open={showDialog}
        fullScreen
        TransitionComponent={Transition}
      >
        <div>
          <Grid container justifyContent="flex-start" alignItems="center">
            <IconButton color="primary" onClick={closeDialog} size="medium">
              <CloseIcon fontSize="large" />
            </IconButton>
          </Grid>
        </div>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          <List>
            {menuItems.map((item, index) => (
              <div key={index}>
                {getItem(item)}
                <Divider />
              </div>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
}
