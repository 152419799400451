import { Fade, Grid, Typography } from "@material-ui/core";
import React from "react";
import emptyDescription from "../../../../static/image/emptyDescription.svg";

export default function DescriptionsParent({ data, classes }) {
  return (
    <Fade in timeout={1000}>
      <div className={classes.descriptionParent}>
        {data?.description == null || data?.description?.length === 0 ? (
          <div className={classes.emptyContainer}>
            <Grid container justifyContent="center">
              <img
                loading="lazy"
                alt="emptyComment"
                src={emptyDescription}
                className={classes.emptyImage}
              />
            </Grid>

            <Typography
              color="textSecondary"
              variant="subtitle2"
              align="center"
            >
              هیچ توضیحاتی ثبت نشده است
            </Typography>
          </div>
        ) : (
          <Typography
            color="textSecondary"
            variant="subtitle2"
            align="justify"
            className={classes.descriptionMsg}
          >
            {data?.description}
          </Typography>
        )}
      </div>
    </Fade>
  );
}
