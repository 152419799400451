import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector } from 'react-redux';
import { makeStyles, Grid, Container } from '@material-ui/core';

const useStyles = makeStyles({
  radius: {
    borderRadius: 5
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(5,1fr)",
    marginRight: 20,
    marginTop: 10,
  },
  secondGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    marginRight: 20,
    marginTop: 10,

  },
  profileGrid: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    alignItems: "baseline",
    margin: 10
  },
  profileContainer: {
    marginTop: 30,

  },
  productContainer: {
    marginTop: 30,

  },
  orderGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 10
  }
});

export default function LoadingSkeleton() {
  const classes = useStyles()
  const appConfigs = useSelector((state) => state.appConfigs);
  const { skeletonType } = appConfigs;
  return (
    <div>
      {skeletonType === 'homePage' ? (
        <>
          <Skeleton variant="rect" width={"100%"} height={80} animation={"wave"} />
          <div className={classes.grid}>
            <div>
              <Skeleton variant="circle" width={50} height={50} animation={"wave"} />
              <Skeleton variant="text" animation={"wave"} width={50} />
            </div>
            <div>
              <Skeleton variant="circle" width={50} height={50} animation={"wave"} />
              <Skeleton variant="text" animation={"wave"} width={50} />
            </div>
            <div>
              <Skeleton variant="circle" width={50} height={50} animation={"wave"} />
              <Skeleton variant="text" animation={"wave"} width={50} />
            </div>
            <div>
              <Skeleton variant="circle" width={50} height={50} animation={"wave"} />
              <Skeleton variant="text" animation={"wave"} width={50} />
            </div>
            <div>
              <Skeleton variant="circle" width={50} height={50} animation={"wave"} />
              <Skeleton variant="text" animation={"wave"} width={50} />
            </div>

          </div>
          <br />
          <Skeleton variant="text" animation={"wave"} height={30} />
          <div className={classes.secondGrid}>
            <div>
              <Skeleton variant="rect" width={150} height={118} animation={"wave"} className={classes.radius} />
              <Skeleton variant="text" animation={"wave"} width={150} />
              <Skeleton variant="text" animation={"wave"} width={150} />
            </div>
            <div>
              <Skeleton variant="rect" width={150} height={118} animation={"wave"} className={classes.radius} />
              <Skeleton variant="text" animation={"wave"} width={150} />
              <Skeleton variant="text" animation={"wave"} width={150} />
            </div>

          </div>

          <Skeleton variant="text" animation={"wave"} height={30} />
          <div className={classes.secondGrid}>
            <div>
              <Skeleton variant="rect" width={150} height={118} animation={"wave"} className={classes.radius} />
              <Skeleton variant="text" animation={"wave"} width={150} />
              <Skeleton variant="text" animation={"wave"} width={150} />
            </div>
            <div>
              <Skeleton variant="rect" width={150} height={118} animation={"wave"} className={classes.radius} />
              <Skeleton variant="text" animation={"wave"} width={150} />
              <Skeleton variant="text" animation={"wave"} width={150} />
            </div>

          </div>

          <Skeleton variant="text" animation={"wave"} height={30} />
          <div className={classes.secondGrid}>
            <div>
              <Skeleton variant="rect" width={150} height={118} animation={"wave"} className={classes.radius} />
              <Skeleton variant="text" animation={"wave"} width={150} />
              <Skeleton variant="text" animation={"wave"} width={150} />
            </div>
            <div>
              <Skeleton variant="rect" width={150} height={118} animation={"wave"} className={classes.radius} />
              <Skeleton variant="text" animation={"wave"} width={150} />
              <Skeleton variant="text" animation={"wave"} width={150} />
            </div>

          </div>
          <br />
          <Skeleton variant="text" animation={"wave"} height={30} />
          <div className={classes.secondGrid}>
            <div>
              <Skeleton variant="rect" width={150} height={118} animation={"wave"} className={classes.radius} />
              <Skeleton variant="text" animation={"wave"} width={150} />
              <Skeleton variant="text" animation={"wave"} width={150} />
            </div>
            <div>
              <Skeleton variant="rect" width={150} height={118} animation={"wave"} className={classes.radius} />
              <Skeleton variant="text" animation={"wave"} width={150} />
              <Skeleton variant="text" animation={"wave"} width={150} />
            </div>
            <br />

          </div>

        </>
      ) : skeletonType === 'profile' ? (
        <div className={classes.profileContainer}>

          <div className={classes.profileGrid}>
            <Skeleton variant="circle" width={40} height={40} animation={"wave"} />
            <Skeleton variant="text" animation={"wave"} width={200} />
            <Skeleton variant="rect" width={30} height={30} animation={"wave"} className={classes.radius} />

          </div>
          <div className={classes.profileGrid}>
            <Skeleton variant="circle" width={40} height={40} animation={"wave"} />
            <Skeleton variant="text" animation={"wave"} width={200} />
            <Skeleton variant="rect" width={30} height={30} animation={"wave"} className={classes.radius} />

          </div>
          <div className={classes.profileGrid}>
            <Skeleton variant="circle" width={40} height={40} animation={"wave"} />
            <Skeleton variant="text" animation={"wave"} width={200} />
            <Skeleton variant="rect" width={30} height={30} animation={"wave"} className={classes.radius} />

          </div> <div className={classes.profileGrid}>
            <Skeleton variant="circle" width={40} height={40} animation={"wave"} />
            <Skeleton variant="text" animation={"wave"} width={200} />
            <Skeleton variant="rect" width={30} height={30} animation={"wave"} className={classes.radius} />

          </div> <div className={classes.profileGrid}>
            <Skeleton variant="circle" width={40} height={40} animation={"wave"} />
            <Skeleton variant="text" animation={"wave"} width={200} />
            <Skeleton variant="rect" width={30} height={30} animation={"wave"} className={classes.radius} />

          </div>
          <div className={classes.profileGrid}>
            <Skeleton variant="circle" width={40} height={40} animation={"wave"} />
            <Skeleton variant="text" animation={"wave"} width={200} />
            <Skeleton variant="rect" width={30} height={30} animation={"wave"} className={classes.radius} />

          </div>
          <div className={classes.profileGrid}>
            <Skeleton variant="circle" width={40} height={40} animation={"wave"} />
            <Skeleton variant="text" animation={"wave"} width={200} />
            <Skeleton variant="rect" width={30} height={30} animation={"wave"} className={classes.radius} />

          </div>
          <div className={classes.profileGrid}>
            <Skeleton variant="circle" width={40} height={40} animation={"wave"} />
            <Skeleton variant="text" animation={"wave"} width={200} />
            <Skeleton variant="rect" width={30} height={30} animation={"wave"} className={classes.radius} />

          </div>

        </div>
      ) : skeletonType === 'productDetails' ? (
        <div className={classes.productContainer}>
          <Skeleton variant="rect" width={"100%"} height={200} animation={"wave"} />
          <Skeleton variant="text" animation={"wave"} width={"100%"} height={50} />
          <Skeleton variant="text" animation={"wave"} width={"100%"} height={30} />

          <br />
          <Grid container justifyContent={"space-around"} alignItems={"center"}>
            <Skeleton variant="rect" width={50} height={50} animation={"wave"} className={classes.radius} />
            <Skeleton variant="rect" width={100} height={50} animation={"wave"} className={classes.radius} />

          </Grid>
          <Skeleton variant="text" animation={"wave"} width={"100%"} height={30} />
          <div className={classes.profileGrid}>
            <Skeleton variant="circle" width={40} height={40} animation={"wave"} />
            <Skeleton variant="text" animation={"wave"} width={200} />
            <Skeleton variant="rect" width={30} height={30} animation={"wave"} className={classes.radius} />

          </div>
          <div className={classes.profileGrid}>
            <Skeleton variant="circle" width={40} height={40} animation={"wave"} />
            <Skeleton variant="text" animation={"wave"} width={200} />
            <Skeleton variant="rect" width={30} height={30} animation={"wave"} className={classes.radius} />

          </div>
          <div className={classes.profileGrid}>
            <Skeleton variant="circle" width={40} height={40} animation={"wave"} />
            <Skeleton variant="text" animation={"wave"} width={200} />
            <Skeleton variant="rect" width={30} height={30} animation={"wave"} className={classes.radius} />

          </div>
          <Skeleton variant="rect" width={"100%"} height={50} animation={"wave"} className={classes.radius} />

          <div
            style={{
              position: 'fixed',
              bottom: 20,
              right: 20,
            }}
          >
            <Skeleton variant="circle" width={80} height={80} animation={"wave"} />
          </div>
        </div>
      ) : skeletonType === 'orderComplete' ? (
        <div className={classes.orderGrid}>

          <Skeleton variant="rect" width={"100%"} height={200} animation={"wave"} className={classes.radius} />
          <br />
          <Skeleton variant="rect" width={"100%"} height={200} animation={"wave"} className={classes.radius} />

          <Skeleton variant="text" width={"100%"} animation={"wave"} />
          <br />
          <Skeleton variant="rect" width={"100%"} height={100} animation={"wave"} className={classes.radius} />
        </div>
      ) : skeletonType === 'listSkeleton' ? (
        <>
          <br />
          <Container>
            <Skeleton variant="rect" width={"100%"} height={100} animation={"wave"} className={classes.radius} />
            <br />

            <Skeleton variant="rect" width={"100%"} height={100} animation={"wave"} className={classes.radius} />
            <br />

            <Skeleton variant="rect" width={"100%"} height={100} animation={"wave"} className={classes.radius} />
            <br />
            <Skeleton variant="rect" width={"100%"} height={100} animation={"wave"} className={classes.radius} />
            <br />  <Skeleton variant="rect" width={"100%"} height={100} animation={"wave"} className={classes.radius} />
            <br />
            <Skeleton variant="rect" width={"100%"} height={100} animation={"wave"} className={classes.radius} />
            <br />

            <Skeleton variant="rect" width={"100%"} height={100} animation={"wave"} className={classes.radius} />

          </Container>
        </>
      ) : null}

    </div>
  );
}
