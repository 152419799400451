import { Typography } from "@material-ui/core";

export default function TypoGraphy(props) {
  const text = props.text;
  const variant = props.variant;
  const color = props.color;
  const display = props.display;
  const gutterBottom = props.gutterBottom;
  const paragraph = props.paragraph;
  const align = props.align;
  const style = props.style;
  const ClassName = props.className;

  return (
    <Typography
      style={style}
      variant={variant}
      color={color}
      display={display}
      gutterBottom={gutterBottom}
      paragraph={paragraph}
      align={align}
      className={ClassName}
    >
      {text}
    </Typography>
  );
}
