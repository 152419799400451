import { connect } from 'react-redux';
import AlertDialogHandler from '../../components/helpers/AlertDialogHandler';
import { showAlertDialogAction } from '../actions/reduxActions';

const mapStateToProps = (state, ownProps) => {
  return {
    open: state.appConfigs.openDialog,
    content: state.appConfigs.alertDialogContent,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showAlertDialogAction: (open) => {
      dispatch(showAlertDialogAction(open));
    },
  };
};

const AlertContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertDialogHandler);

export default AlertContainer;
