import { Button, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export default function DonePayment() {
  const location = useLocation()
  const [redirectTo, setRedirectTo] = useState(false);
  const userTypings = useSelector((state) => state.userTypings);
  const { isWebViewed } = userTypings;
  const params = new URLSearchParams(location.search);
  const isCharging = params.get('isCharging');
  const isFromOrder = params.get('fromOrder');


  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >

      <>
        <Typography
          variant='h5'
          style={{ color: 'green', textAlign: 'center' }}
        >
          پرداخت با موفقیت انجام شد
        </Typography>
        <br />
        <Button
          fullWidth
          variant='contained'
          style={{ backgroundColor: 'green', color: 'white' }}
          onClick={() => {
            if (isWebViewed === true) {
              const loc = window.open(
                'rosegol://payment-ok',
                '_blank'
              );
              loc.focus();

            } else {
              if (isCharging != null) {
                window.location.replace('/charge-account');
              }
              else if (isFromOrder != null) {
                window.location.replace(`/order/${isFromOrder}`);
              }
              else {
                window.location.replace('/checkout');

              }

            }

          }}
        >
          <SentimentVerySatisfiedIcon />
          &nbsp;بازگشت به اپلیکیشن
        </Button>
      </>

    </div>
  );
}
