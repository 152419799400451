import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import React from "react";
import priceRegex from "../../../../utils/priceRegex";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import RoomServiceIcon from "@material-ui/icons/RoomService";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import ReceiptIcon from "@material-ui/icons/Receipt";
import StorefrontIcon from "@material-ui/icons/Storefront";
import PersonIcon from "@material-ui/icons/Person";
import longStringRegex from "../../../../utils/longStringsRegex";

export default function Details({ data, classes }) {
  const detailArr = [
    {
      title: "مشتری",
      diff: false,
      show: true,
      showVal: `${longStringRegex(data?.customer?.name)}، ${
        data?.customer?.mobile
      }`,
      icon: <PersonIcon color="secondary" />,
    },
    {
      title: "وضعیت سفارش",
      diff: true,
      show: true,
      showVal: data?.status_title,
      icon: <StorefrontIcon color="secondary" />,
    },
    {
      title: "شناسه سفارش",
      diff: false,
      show: true,
      showVal: data?.id,
      icon: <FingerprintIcon color="secondary" />,
    },
    {
      title: "روش پرداخت",
      diff: false,
      show: true,
      showVal: data?.payment?.payment_type_str,
      icon: <ReceiptIcon color="secondary" />,
    },
    {
      title: "پرداختی",
      diff: true,
      show: data?.invoice?.price > 0,
      showVal:
        data?.invoice?.price <= 0
          ? "توافقی"
          : priceRegex(data?.invoice?.price, "withCurrency"),
      icon: <CreditCardIcon color="secondary" />,
    },

    {
      title: "تخفیف",
      diff: true,
      show: data?.invoice?.discount > 0,
      showVal:
        data?.invoice?.discount <= 0
          ? "توافقی"
          : priceRegex(data?.invoice?.discount, "withCurrency"),
      icon: <LocalOfferIcon color="secondary" />,
    },
    {
      title: "بدون تخفیف",
      diff: false,
      show: data?.invoice?.real_price > 0,
      showVal: priceRegex(data?.invoice?.real_price, "withCurrency"),
      icon: <AccountBalanceIcon color="secondary" />,
    },
    {
      title: "هزینه بسته بندی",
      diff: false,
      show: data?.invoice?.container_price > 0,
      showVal: priceRegex(data?.invoice?.container_price, "withCurrency"),
      icon: <RestaurantIcon color="secondary" />,
    },
    {
      title: "هزینه سرویس",
      diff: false,
      show: data?.invoice?.service_price > 0,
      showVal: priceRegex(data?.invoice?.service_price, "withCurrency"),
      icon: <RoomServiceIcon color="secondary" />,
    },
    {
      title: "هزینه ارسال",
      diff: false,
      show: data?.invoice?.shipping_price > 0,
      showVal: priceRegex(data?.invoice?.shipping_price, "withCurrency"),
      icon: <MotorcycleIcon color="secondary" />,
    },
  ];

  return (
    <div>
      <List>
        {detailArr
          .filter((det) => det.show === true)
          .map((dt, index) => (
            <ListItem divider key={index}>
              <ListItemIcon>{dt.icon}</ListItemIcon>
              <ListItemText
                className={classes.litItmTxtWithoutMargin}
                primary={
                  <Typography variant="subtitle2" color="textPrimary">
                    {dt.title}
                  </Typography>
                }
                secondary={
                  <Typography
                    variant="subtitle2"
                    color={dt.diff ? "secondary" : "textSecondary"}
                  >
                    {dt.showVal}
                  </Typography>
                }
              />
            </ListItem>
          ))}
      </List>
    </div>
  );
}
