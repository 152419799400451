import { Button, Grid, TextField, IconButton, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import emptyCoupons from "../../../static/image/emptyCoupons.svg"
import CloseIcon from "@material-ui/icons/Close"
import { useDispatch } from 'react-redux'
import { setGiftCode } from '../../../redux/actions/reduxActions'
import AppBarContainer from '../navigation/AppBarContainer'

export default function GiftCode() {
    const [code, setCode] = useState('')
    const dispatch = useDispatch()

    return (
        <div
            style={{
                marginBottom: 80,
            }}
        >
            <br/>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center"
            }}
            >
                <img
                    alt="coupon"
                    src={emptyCoupons}
                    style={{
                        width: "70%",
                        height: 250,
                    }}
                />
            </div>   
            <br />
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: 20,
                }}
            >
                <Typography
                    align="right"
                    gutterBottom
                    variant="subtitle1"
                    color="textPrimary"
                >
                    کوپن تخفیف را وارد کنید
                </Typography>
                <TextField
                    onChange={(e) => setCode(e.target.value)}
                    autoFocus
                    value={code}
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                disabled={code?.length === 0}
                                color="primary"
                                size="small"
                                onClick={() => {
                                    setCode('')
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        )
                    }}
                    required
                />
                <br />
                <br />
                <Button
                    disabled={code?.length === 0}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        dispatch(setGiftCode(code))
                    }}
                >
                    استفاده از کوپن
                </Button>
                <br />
                <br />

                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        window.location.replace('/')
                    }}
                >
                    بازگشت
                </Button>

            </div>
        </div>
    )
}
