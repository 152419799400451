import { connect } from 'react-redux';
import HomeParent from '../../components/layout/home/HomeParent';
import {
  getHomeItemsAction,
  getAppConfigsAction,
  getCategoriesAction,
  getProductDetailsAction,
  getProductsByCategoryAction,
  getAllProductsByTypeAction,
  skeletonLoadingStatusAction,
  userTypingAction,
  getGuestToken,
  userNavigationHistorySaver,
} from '../actions/reduxActions';

const mapStateToProps = (state, ownProps) => {
  return {
    products: state.products,
    locationHistory: state.appConfigs.historyValue,
    auth: state?.auth?.authData
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getHomeItemsAction: () => {
      dispatch(getHomeItemsAction());
    },
    getAppConfigsAction: () => {
      dispatch(getAppConfigsAction());
    },
    getCategoriesAction: () => {
      dispatch(getCategoriesAction());
    },
    getProductDetailsAction: () => {
      dispatch(getProductDetailsAction());
    },
    getProductsByCategoryAction: () => {
      dispatch(getProductsByCategoryAction());
    },
    getAllProductsByTypeAction: () => {
      dispatch(getAllProductsByTypeAction());
    },
    skeletonLoadingStatusAction: (type) => {
      dispatch(skeletonLoadingStatusAction(type));
    },
    userTypingAction: (value, type) => {
      dispatch(userTypingAction(value, type));
    },
    getGuestToken: () => {
      dispatch(getGuestToken());
    },
    userNavigationHistorySaver: (history) => {
      dispatch(userNavigationHistorySaver(history));
    },
  };
};

const HomeContainer = connect(mapStateToProps, mapDispatchToProps)(HomeParent);

export default HomeContainer;
