import { connect } from 'react-redux';
import LoginParent from '../../components/layout/login/LoginParent';
import {
  resendOtpSmsAction,
  receiveOtpSmsAction,
  validateOtpSmsAction,
  userTypingAction,
  showAlertDialogAction,
  alertDialogContentAction,
  getGuestToken
} from '../actions/reduxActions';

const mapStateToProps = (state, ownProps) => {
  return {
    userTypings: state.userTypings,
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    userTypingAction: (value, type) => {
      dispatch(userTypingAction(value, type));
    },
    receiveOtpSmsAction: () => {
      dispatch(receiveOtpSmsAction());
    },
    resendOtpSmsAction: () => {
      dispatch(resendOtpSmsAction());
    },
    validateOtpSmsAction: () => {
      dispatch(validateOtpSmsAction());
    },
    showAlertDialogAction: (open) => {
      dispatch(showAlertDialogAction(open));
    },
    alertDialogContentAction: (title, content, textButton) => {
      dispatch(alertDialogContentAction(title, content, textButton));
    },
    getGuestToken: () => {
      dispatch(getGuestToken());
    },
  };
};

const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginParent);

export default LoginContainer;
