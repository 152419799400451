import { connect } from 'react-redux';
import {
  clearCartAction,
  addToCartAction,
  removeFromCartAction,
  increaseUnitsAction,
  decreaseUnitsAction,
  skeletonLoadingStatusAction,
  getUserFavoritesAction,
  deleteFromFavoritesAction,
  userTypingAction,
  getCartAction,
  addCartAction,
  removeCartAction,
  deleteCartAction,
  userNavigationHistorySaver,
} from '../actions/reduxActions';
import * as storageHandler from '../../utils/storageHandler';
import CartParent from '../../components/layout/cart/CartParent';

const mapStateToProps = (state, ownProps) => {
  return {
    products: storageHandler.getLocalStorage('POPULAR_PRODUCTS'),
    cartItems: state.cart.cartItems,
    favorites: state.userInfos.userFavorites,
    loading: state.userInfos.userLoading,
    favoritesLoading: state.userInfos.favoritesLoading,
    cartFromServer: state.products.cartFromServer,
    isCartReceived: state.products.isCartReceived,
    auth: state.auth,
    priceInvoice: state.orderStates.priceInvoice,
    requestingPrice: state.orderStates.requestingPrice,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addToCartAction: (id, name, price, qty, image) => {
      dispatch(addToCartAction(id, name, price, qty, image));
    },
    removeFromCartAction: (id) => {
      dispatch(removeFromCartAction(id));
    },
    increaseUnitsAction: (id) => {
      dispatch(increaseUnitsAction(id));
    },
    decreaseUnitsAction: (id) => {
      dispatch(decreaseUnitsAction(id));
    },
    clearCartAction: () => {
      dispatch(clearCartAction());
    },
    skeletonLoadingStatusAction: (type) => {
      dispatch(skeletonLoadingStatusAction(type));
    },
    getUserFavoritesAction: () => {
      dispatch(getUserFavoritesAction());
    },
    deleteFromFavoritesAction: () => {
      dispatch(deleteFromFavoritesAction());
    },
    userTypingAction: (value, type) => {
      dispatch(userTypingAction(value, type));
    },
    getCartAction: () => {
      dispatch(getCartAction());
    },
    addCartAction: (id) => {
      dispatch(addCartAction(id));
    },
    removeCartAction: (id) => {
      dispatch(removeCartAction(id));
    },
    deleteCartAction: (id, count) => {
      dispatch(deleteCartAction(id, count));
    },
    userNavigationHistorySaver: (history) => {
      dispatch(userNavigationHistorySaver(history));
    },
  };
};

const CartContainer = connect(mapStateToProps, mapDispatchToProps)(CartParent);

export default CartContainer;
