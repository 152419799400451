import InputTextField from "../../helpers/InputTextField";
import {
  makeStyles,
  createStyles,
  Theme,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  Grid,
  TextField,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MaterialButton from "../../helpers/MaterialButton";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import BirthdayInput from "./BirthdayInput";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: theme.spacing(2),
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: "100%",
    },
    margin: {
      marginTop: theme.spacing(3),
    },
  })
);

export default function UserInformation(props) {
  const userTypingAction = props.userTypingAction;
  const editProfileAction = props.editProfileAction;
  const userTypings = props.userTypings;
  const userInfos = props.userInfos.userProfile;
  const logoutAction = props.logoutAction;
  const classes = useStyles();
  const history = useHistory();

  console.log("userTypings==>", userTypings);

  const handleInputChange = (value, inputType) => {
    userTypingAction(value, inputType);
  };

  const handleEditProfile = () => {
    userTypingAction(true, "editProfileLoading");
    editProfileAction();
  };
  const logout = () => {
    logoutAction();
  };

  return (
    <div className={classes.root}>
      <InputTextField
        label={"نام"}
        value={userTypings.firstName}
        onChange={handleInputChange}
        inputType={"firstName"}
      />
      <InputTextField
        label={"نام خانوادگی"}
        value={userTypings.lastName}
        onChange={handleInputChange}
        inputType={"lastName"}
        className={classes.margin}
      />
      <InputTextField
        className={classes.margin}
        label="شماره موبایل"
        value={userTypings.username}
        autoFocus={true}
        disabled={true}
      />
      <InputTextField
        label={"معرف"}
        value={userTypings.referrer}
        onChange={handleInputChange}
        inputType={"referrer"}
        // disabled={}
        type="text"
        className={classes.margin}
      />

      <FormControl component="fieldset" className={classes.margin}>
        <FormLabel>جنسیت</FormLabel>
        <RadioGroup
          row
          value={userTypings.gender}
          onChange={(event) => {
            userTypingAction(event.target.value, "gender");
          }}
        >
          <FormControlLabel value="woman" control={<Radio />} label="خانم" />
          <FormControlLabel value="man" control={<Radio />} label="آقا" />
        </RadioGroup>
      </FormControl>
      <BirthdayInput />
      <br />
      <br />
      <div style={{ display: "flex" }}>
        <Grid item xs={6} style={{ margin: ".5rem" }}>
          <MaterialButton
            btnType={"button"}
            icon={
              userTypings.editProfileLoading ? (
                <CircularProgress color="#fff" size={20} />
              ) : (
                <CheckBoxOutlinedIcon />
              )
            }
            color={"primary"}
            text={"ثبت تغییرات"}
            fullWidth={true}
            variant={"contained"}
            onClick={handleEditProfile}
          />
        </Grid>
        <Grid item xs={6} style={{ margin: ".5rem" }}>
          <MaterialButton
            btnType={"button"}
            icon={<DoubleArrowIcon style={{ transform: "rotate(180deg)" }} />}
            color={"secondary"}
            text={"انصراف"}
            fullWidth={true}
            variant={"text"}
            onClick={() => {
              history.push("/");
            }}
          />
        </Grid>
      </div>
    </div>
  );
}
