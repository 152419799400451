import { Breadcrumbs, Typography } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import shortStringRegex from '../../../utils/shortStringRegex';

export default function CategorySteps(props) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const categoryId = params.get('category');
  const [steps, setSteps] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const stepsArray = [];
    let stepsObject = props.product;
    if (stepsObject.parent_category) {
      stepsArray.push({ name: stepsObject.name, id: stepsObject.id });
      setSteps(stepsArray);

      while (stepsObject.parent_category) {
        stepsArray.push({
          name: stepsObject.parent_category.name,
          id: stepsObject.parent_category.id,
        });
        stepsObject = stepsObject.parent_category;
        setSteps(stepsArray);
      }
    }
  }, []);

  return (
    <>
      <Breadcrumbs
        style={{
          marginTop: 10,
        }}
      >
        {steps
          .map((step, index) => (
            <Typography
              style={{
                padding: 0,
                margin: 0,
                width: 'max-content',
                cursor: 'pointer',
              }}
              variant='subtitle1'
              color={categoryId == step.id ? 'primary' : 'textPrimary'}
              onClick={() => {
                if (categoryId != step.id) {
                  if (step.id === 0) {
                    history.push('/');
                  } else {
                    history.push(`/?category=${step.id}`);
                  }
                }
              }}
              key={index}
            >
              {shortStringRegex(step.name)}
            </Typography>
          ))
          .reverse()}
      </Breadcrumbs>
    </>
  );
}
