import { Badge, Button, IconButton, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import { useHistory } from "react-router-dom";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import ConfettiExplosion from "react-confetti-explosion";

export default function CartContainer({
  isCartReceived,
  qty,
  increaseItem,
  decreaseItem,
  data,
  classes,
  showConfetti,
}) {
  const history = useHistory();

  useEffect(() => {}, [qty, isCartReceived]);

  const goToCart = () => {
    history.push("/confirm-basket");
  };

  return (
    <div className={classes.ButtonWrapper}>
      {showConfetti && (
        <div className={classes.confettiContainer}>
          <ConfettiExplosion
            floorWidth={window.outerWidth}
            floorHeight={window.outerHeight}
            duration={2000}
          />
        </div>
      )}
      {qty === 0 ? (
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={isCartReceived || data?.limit === 0}
          onClick={increaseItem}
        >
          افزودن به سبد خرید
        </Button>
      ) : (
        <div className={classes.cartItemsContainer}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={goToCart}
            className={classes.goToCartBottom}
          >
            تکمیل خرید
          </Button>
          <div className={classes.cartIconBox}>
            <IconButton
              color="default"
              size="small"
              edge="end"
              onClick={increaseItem}
              disabled={
                isCartReceived || data?.limit === 0 || data?.limit === qty
              }
            >
              <AddIcon />
            </IconButton>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              align="center"
              className={classes.qty}
            >
              {qty}
            </Typography>
            <IconButton
              color="default"
              size="small"
              edge="end"
              onClick={decreaseItem}
              disabled={isCartReceived || data?.limit === 0}
            >
              {qty === 1 ? <DeleteOutlineIcon /> : <RemoveIcon />}
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
}
