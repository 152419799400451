import { useState } from "react";
import {
  Typography,
  Card,
  CardActionArea,
  Grow,
  Grid,
  Link,
  CardContent,
} from "@material-ui/core";
import stringLengthRegex from "../../../../utils/stringLengthRegex";
import RedirectPages from "../../../helpers/RedirectPages";
import priceRegex from "../../../../utils/priceRegex";
import { useDispatch } from "react-redux";
// import {
//   userTypingAction,
//   getProductDetailsAction,
// } from "../../../../redux/actions/reduxActions";
import { useHistory } from "react-router-dom";
import emptyImage from "../../../../static/image/emptyImage.svg";
import soldOutIcon from "../../../../static/image/soldOut.png";
import { DiscountBadge, RateBadge } from "./ProductBadge";
import useStyles from "./Product.style";

export default function ProductCard(props) {
  const classes = useStyles();
  const item = props.product;
  const { produtType } = props;
  // const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectValue, setRedirectValue] = useState("");
  let BadgeContent = null;
  let productPrice = null;

  switch (produtType) {
    case "discount":
      BadgeContent = (
        <Grid
          item
          xs={6}
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          {item.discount_percent !== 0 && (
            <DiscountBadge
              content={`${item.discount_percent}%`}
              styles={classes.DiscountBadge}
            />
          )}
        </Grid>
      );
      productPrice = (
        <Typography color="textPrimary" className={classes.priceContainer}>
          <Typography
            color="#888888"
            style={{
              color: "#888888",
              fontSize: "1em",
            }}
          >
            {item.real_price <= 0 ? (
              "توافقی"
            ) : (
              <span
                style={{
                  margin: "4px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{ textDecoration: "#EB6F85 line-through 2px solid" }}
                >
                  {priceRegex(item.real_price, "withoutCurrency")}
                </span>

                <span
                  style={{
                    fontSize: "10px",
                    alignSelf: "flex-end",
                    marginTop: "-8px",
                  }}
                >
                  تومان
                </span>
              </span>
            )}
          </Typography>
          <span style={{ margin: "0 4px" }}>/</span>
          <Typography color="primary" style={{ fontSize: "1em" }}>
            {item.price <= 0 ? (
              "توافقی"
            ) : (
              <span
                style={{
                  margin: "4px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>{priceRegex(item.price, "withoutCurrency")}</span>

                <span
                  style={{
                    fontSize: "10px",
                    alignSelf: "flex-end",
                    marginTop: "-8px",
                  }}
                >
                  تومان
                </span>
              </span>
            )}
          </Typography>
        </Typography>
      );
      break;
    case "favorite":
      BadgeContent = (
        <>
          <Grid
            item
            xs={6}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <RateBadge rate={item.rate} styles={classes.RateBadge} />
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {item.discount_percent !== 0 && (
              <DiscountBadge
                content={`${item.discount_percent}%`}
                styles={classes.DiscountBadge}
              />
            )}
          </Grid>
        </>
      );
      productPrice = (
        <Typography color="#888888" className={classes.productPriceText}>
          {item.real_price <= 0
            ? "توافقی"
            : priceRegex(item.price, "withCurrency")}
        </Typography>
      );
      break;
    case "new":
      BadgeContent = null;
      // BadgeContent = (
      //   <Grid
      //     item
      //     xs={6}
      //     style={{ display: "flex", justifyContent: "flex-start" }}
      //   >
      //     <DiscountBadge content="new" styles={classes.DiscountBadge} />
      //   </Grid>
      // );
      productPrice = (
        <Typography color="#888888" className={classes.productPriceText}>
          {item.real_price <= 0
            ? "توافقی"
            : priceRegex(item.price, "withCurrency")}
        </Typography>
      );
      break;

    default:
      BadgeContent = null;
      productPrice = item.real_price ? (
        <Typography color="#888888" className={classes.productPriceText}>
          {item.real_price <= 0
            ? "توافقی"
            : priceRegex(item.price, "withCurrency")}
        </Typography>
      ) : null;
      break;
  }

  return (
    <Link
      href={`/product/${item.id}`}
      underline="none"
      onClick={(event) => {
        event.preventDefault();
      }}
    >
      <Grow in={true} timeout={2000}>
        <div
          className={classes.root}
          onClick={() => {
            history.push(`/product/${item.id}`);
          }}
        >
          <Card className={classes.card}>
            <CardActionArea className={classes.cardAction}>
              {item.limit === 0 && (
                <img
                  src={soldOutIcon}
                  alt="soldOutIcon"
                  width="30"
                  className={classes.soldOutIcon}
                  height="30"
                />
              )}
              <div className={classes.container}>
                <Grid container className={classes.badgeContainer}>
                  {BadgeContent}
                </Grid>
                <img
                  alt="product"
                  className={classes.cardMedia}
                  src={item.image}
                  onError={(event) => {
                    event.target.src = emptyImage;
                  }}
                />
                <CardContent className={classes.CardContent}>
                  <Typography className={classes.name}>
                    <span>{stringLengthRegex(item.name)}</span>
                  </Typography>
                  {productPrice}
                </CardContent>
              </div>
            </CardActionArea>
          </Card>
        </div>
      </Grow>
      {shouldRedirect === true && <RedirectPages redirect={redirectValue} />}
    </Link>
  );
}
