import { connect } from 'react-redux';
import {
  drawerHandler,
  skeletonLoadingStatusAction,
  userTypingAction,
  checkCouponAction,
  showAlertDialogAction,
  alertDialogContentAction,
  requestPaymentAction,
  getUserDetailsAction,
  handleMsgChipsBtn
} from '../actions/reduxActions';
import InfoDrawerHandler from '../../components/helpers/InfoDrawerHandler';

const mapStateToProps = (state, ownProps) => {
  return {
    infoDrawer: state.appConfigs.infoDrawer,
    userInfos: state.userInfos,
    userTypings: state.userTypings,
    configs: state.appConfigs.configs,
    addressId: state.mapStates.addressId,
    priceInvoice: state.orderStates.priceInvoice,
    isCheckingCoupon: state.userInfos.isCheckingCoupon
   
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    drawerHandler: (open, type) => {
      dispatch(drawerHandler(open, type));
    },
    skeletonLoadingStatusAction: (type) => {
      dispatch(skeletonLoadingStatusAction(type));
    },
    userTypingAction: (value, type) => {
      dispatch(userTypingAction(value, type));
    },
    checkCouponAction: () => {
      dispatch(checkCouponAction());
    },
    showAlertDialogAction: (open) => {
      dispatch(showAlertDialogAction(open));
    },
    alertDialogContentAction: (title, content, textButton) => {
      dispatch(alertDialogContentAction(title, content, textButton));
    },
    requestPaymentAction: () => {
      dispatch(requestPaymentAction())
    },
    getUserDetailsAction: () => {
      dispatch(getUserDetailsAction())
    },
    handleMsgChipsBtn: (key) => {
      dispatch(handleMsgChipsBtn(key))
    }
  };
};

const DrawerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoDrawerHandler);

export default DrawerContainer;
