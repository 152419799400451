export const setSessionStorage = (name, value) => {
    return sessionStorage.setItem(name, JSON.stringify(value));
  };
  
export const getSessionStorage = (name = '') => {
  return JSON?.parse(sessionStorage.getItem(name ?? '') ?? "{}") ?? '{}';
  };
  
export const setLocalStorage = (name, value) => {
    return localStorage.setItem(name, JSON.stringify(value));
  };
  
export const getLocalStorage = (name = '') => {
    return JSON?.parse(localStorage.getItem(name ?? '') ?? "{}") ?? '{}';
  };
  
export const removeSessionStorage = (name) => {
    return sessionStorage.removeItem(name);
  };
  
export const removeLocalStorage = (name) => {
    return localStorage.removeItem(name);
  };
  