import { connect } from 'react-redux';
import {
  drawerHandler,
  skeletonLoadingStatusAction,
  getOrderPriceAction,
  removeFromCartAction,
  increaseUnitsAction,
  decreaseUnitsAction,
  showAlertDialogAction,
  alertDialogContentAction,
  userTypingAction,
  getUserDetailsAction,
  requestPaymentAction,
} from '../actions/reduxActions';
import ChargeAccount from '../../components/layout/orders/ChargeAccount';

const mapStateToProps = (state, ownProps) => {
  return {
    infoDrawer: state.appConfigs.infoDrawer,
    userInfos: state.userInfos,
    priceInvoice: state.orderStates.priceInvoice,
    cartItems: state.cart.cartItems,
    addressId: state.mapStates.addressId,
    requestingPrice: state.orderStates.requestingPrice,
    userTypings: state.userTypings,
    userProfile: state.userInfos.userProfile,
    paymentStatus: state.orderStates,
    loading: state.userInfos.userLoading,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    drawerHandler: (open, type) => {
      dispatch(drawerHandler(open, type));
    },
    skeletonLoadingStatusAction: (type) => {
      dispatch(skeletonLoadingStatusAction(type));
    },
    getOrderPriceAction: () => {
      dispatch(getOrderPriceAction());
    },
    removeFromCartAction: (id) => {
      dispatch(removeFromCartAction(id));
    },
    increaseUnitsAction: (id) => {
      dispatch(increaseUnitsAction(id));
    },
    decreaseUnitsAction: (id) => {
      dispatch(decreaseUnitsAction(id));
    },
    showAlertDialogAction: (open) => {
      dispatch(showAlertDialogAction(open));
    },
    alertDialogContentAction: (title, content, textButton) => {
      dispatch(alertDialogContentAction(title, content, textButton));
    },
    userTypingAction: (value, type) => {
      dispatch(userTypingAction(value, type));
    },
    getUserDetailsAction: () => {
      dispatch(getUserDetailsAction());
    },
    requestPaymentAction: (isFromChargeAccount) => {
      dispatch(requestPaymentAction(isFromChargeAccount));
    },
  };
};

const ChargeAccountContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChargeAccount);

export default ChargeAccountContainer;
