import React from "react";
import { Divider, Typography, Grid } from "@material-ui/core";
import StarsIcon from "@material-ui/icons/Stars";

export default function PrdAvailability({ data, classes, comments }) {
  return (
    <div className={classes.prdAvail}>
      <Typography
        variant="subtitle2"
        align="center"
        style={{
          color: data.limit === 0 ? "red" : "green",
        }}
      >
        {data.limit === 0 ? "ناموجود" : "موجود"}
      </Typography>
      <Divider orientation="vertical" flexItem className={classes.divider} />
      <Typography variant="subtitle2" className={classes.breadCrumbTypo}>
        {comments?.length} دیدگاه کاربران
      </Typography>
      <Divider orientation="vertical" flexItem className={classes.divider} />
      <div className={classes.rateContainer}>
        <StarsIcon className={classes.rate} />

        <Typography variant="subtitle2" color="textSecondary">
          {data.rate ?? 5}
        </Typography>
      </div>
    </div>
  );
}
