import React from "react";
import { Grid, IconButton } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";

export default function CarouselActions({ rightBtns, classes }) {
  const history = useHistory();
  const backIcon = () => {
    history.goBack();
  };

  return (
    <div>
      <div className={classes.actionContainer}>
        {rightBtns?.map((itm, index) => (
          <IconButton
            key={index}
            // color="primary"
            size="small"
            edge="end"
            onClick={itm.action}
            className={classes.actionBtnIcon}
          >
            {itm.icon}
          </IconButton>
        ))}
      </div>
      <div className={classes.actionBack}>
        <IconButton
          className={classes.actionBtnIcon}
          onClick={backIcon}
          // color="primary"
          size="small"
          edge="end"
        >
          <ArrowBackIosIcon />
        </IconButton>
      </div>
    </div>
  );
}
