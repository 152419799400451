import React from "react";
import CarouselActions from "./CarouselActions";
import CarouselContainer from "./CarouselContainer";
import ShareIcon from "@material-ui/icons/Share";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { Badge } from "@material-ui/core";
import {
  addToFavoritesAction,
  alertDialogContentAction,
  deleteFromFavoritesAction,
  showAlertDialogAction,
} from "../../../../redux/actions/reduxActions";
import { useHistory } from "react-router-dom";

export default function SlideContainer({
  images,
  classes,
  data,
  role,
  dispatch,
  isInFavorites,
  qty,
  cartFromServer,
}) {
  const history = useHistory();
  const rightButtonsAction = [
    {
      icon: <ShareIcon />,
      action: () => {
        if (navigator.share) {
          navigator.share({
            title: `از محصول "${data.name}" در رزگل دیدن کنید`,
            url: window.location.href,
          });
        }
      },
    },
    {
      icon: !isInFavorites ? <FavoriteBorderIcon /> : <FavoriteIcon />,
      action: () => {
        if (role === "guest") {
          dispatch(
            alertDialogContentAction(
              "دسترسی ندارید",
              "برای دسترسی به این بخش باید ثبت نام کنید",
              "برو به صفحه ثبت نام"
            )
          );
          dispatch(showAlertDialogAction(true));
        } else {
          if (isInFavorites) {
            dispatch(deleteFromFavoritesAction());
          } else {
            dispatch(addToFavoritesAction());
          }
        }
      },
    },
    {
      icon: (
        <Badge
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          badgeContent={cartFromServer?.length}
          color="secondary"
        >
          <ShoppingCartIcon />
        </Badge>
      ),
      action: () => {
        history.push("/cart");
      },
    },
  ];

  return (
    <div className={classes.carouselContainer}>
      <CarouselContainer classes={classes} images={images} />

      <CarouselActions classes={classes} rightBtns={rightButtonsAction} />
    </div>
  );
}
