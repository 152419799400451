import { AppBar, IconButton } from "@material-ui/core";
import React from "react";
import CartContainer from "./CartContainer";
import PriceContainer from "./PriceContainer";

export default function BottomBar({
  classes,
  data,
  role,
  isCartReceived,
  qty,
  increaseItem,
  decreaseItem,
  showConfetti
}) {
  return (
    <AppBar position="fixed" className={classes.bottomBar} elevation={10}>
      <div className={classes.bottomBarContainer}>
        <PriceContainer classes={classes} data={data} />

        <CartContainer
          isCartReceived={isCartReceived}
          qty={qty}
          increaseItem={increaseItem}
          decreaseItem={decreaseItem}
          data={data}
          classes={classes}
          showConfetti={showConfetti}
        />
      </div>
    </AppBar>
  );
}
