import React from 'react'
import AppBarContainer from '../navigation/AppBarContainer'

export default function BlogParent() {


  return (
      <div
      
      >
          <AppBarContainer/>
          <iframe
              src="https://blog.rosegol.app/app_gallery/"
              title="wat"
              style={{
                  width: "100%",
                  height: "100vh",
                  marginBottom: 50,
                  border: "none"
              }}
          />
    </div>
  )
}
