import { ACTION_CONSTANTS } from "../constants/actionConstants";

export default function authenticationReducer(state = {}, action) {
  const payload = action.payload;
  switch (action.type) {
    case ACTION_CONSTANTS.RECEIVE_OTP_SMS_REQUEST:
    case ACTION_CONSTANTS.VALIDATE_OTP_SMS_REQUEST:
      return {
        ...state,
        loadingLoginButton: true,
      };
    case ACTION_CONSTANTS.RECEIVE_OTP_SMS:
      return {
        ...state,
        isSmsReceived: payload.success,
        loadingLoginButton: false,
        tempId: payload.data.user.id,
        isCustomerNew: payload.data.is_new,
      };
    case ACTION_CONSTANTS.VALIDATE_OTP_SMS:
      return {
        ...state,
        verifyMsg: payload.msg,
        verifySuccess: payload.success,
        loadingLoginButton: false,
        authData: payload.data,
      };
    default:
      return state;
  }
}
