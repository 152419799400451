import { Button, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import { useLocation } from 'react-router-dom';

export default function FailedPayment() {
    const location = useLocation()

    const [redirectTo, setRedirectTo] = useState(false);
    const userTypings = useSelector((state) => state.userTypings);
    const { isWebViewed } = userTypings;
    const params = new URLSearchParams(location.search);
    const isCharging = params.get('isCharging');
    const isFromOrder = params.get('fromOrder');

    useEffect(() => {
        console.log(isCharging);
        console.log(isFromOrder);

    }, [])

    return (
        <div
            style={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            <div>
                <Typography
                    variant='h5'
                    style={{ color: '#eb1111', textAlign: 'center' }}
                >
                    پرداخت انجام نشد
                </Typography>
                <br />
                <Button
                    fullWidth
                    variant='contained'
                    style={{ backgroundColor: '#eb1111', color: 'white' }}
                    onClick={() => {

                        if (isWebViewed === true) {
                            const loc = window.open(
                                'rosegol://payment-fail',
                                '_blank'
                            );
                            loc.focus();

                        } else {
                            if (isCharging) {
                                window.location.replace('/charge-account');
                            }
                            else if (isFromOrder) {
                                window.location.replace(`/order/${isFromOrder}`);
                            }
                            else {
                                window.location.replace('/checkout');
                            }
                        }
                    }
                    }
                >
                    <SentimentVeryDissatisfiedIcon />
                    &nbsp;بازگشت به اپلیکیشن
                </Button>
            </div>
        </div>
    );
}
