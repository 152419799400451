import { connect } from 'react-redux';
import {
  getProductDetailsAction,
  skeletonLoadingStatusAction,
  addToCartAction,
  increaseUnitsAction,
  decreaseUnitsAction,
  userTypingAction,
  getCommentsAction,
  actionDialogHandler,
  addToFavoritesAction,
  getUserFavoritesAction,
  getCartAction,
  addCartAction,
  removeCartAction,
  deleteCartAction,
  showAlertDialogAction,
  alertDialogContentAction,
  userNavigationHistorySaver,
  deleteFromFavoritesAction,
  checkQtyInCart
} from '../actions/reduxActions';
import SingleProductParent from '../../components/layout/home/SingleProductParent';

const mapStateToProps = (state, ownProps) => {
  return {
    singleProduct: state.userInfos.singleProduct,
    singleProductLoading: state.userInfos.singleProductLoading,
    cartItems: state.cart.cartItems,
    commentsList: state.userInfos.commentsList,
    commentsLoading: state.userInfos.commentsLoading,
    favoritesLoading: state.userInfos.favoritesLoading,
    favorites: state.userInfos.userFavorites,
    userTypings: state.userTypings,
    cartFromServer: state.products.cartFromServer,
    isCartReceived: state.products.isCartReceived,
    auth: state.auth,
    isInFavorites: state.products.isInFavorites,
    itemQuantity: state.products.itemQuantity,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getProductDetailsAction: () => {
      dispatch(getProductDetailsAction());
    },
    skeletonLoadingStatusAction: (type) => {
      dispatch(skeletonLoadingStatusAction(type));
    },
    addToCartAction: (id, name, price, qty, image) => {
      dispatch(addToCartAction(id, name, price, qty, image));
    },
    increaseUnitsAction: (id) => {
      dispatch(increaseUnitsAction(id));
    },
    decreaseUnitsAction: (id) => {
      dispatch(decreaseUnitsAction(id));
    },
    userTypingAction: (value, type) => {
      dispatch(userTypingAction(value, type));
    },
    getCommentsAction: () => {
      dispatch(getCommentsAction());
    },
    actionDialogHandler: (open, type) => {
      dispatch(actionDialogHandler(open, type));
    },
    addToFavoritesAction: () => {
      dispatch(addToFavoritesAction());
    },
    getUserFavoritesAction: () => {
      dispatch(getUserFavoritesAction());
    },

    getCartAction: () => {
      dispatch(getCartAction());
    },
    addCartAction: (id) => {
      dispatch(addCartAction(id));
    },
    removeCartAction: (id) => {
      dispatch(removeCartAction(id));
    },
    deleteCartAction: (id, count) => {
      dispatch(deleteCartAction(id, count));
    },
    alertDialogContentAction: (title, content, textButton) => {
      dispatch(alertDialogContentAction(title, content, textButton));
    },
    showAlertDialogAction: (open) => {
      dispatch(showAlertDialogAction(open));
    },
    userNavigationHistorySaver: (history) => {
      dispatch(userNavigationHistorySaver(history));
    },
    deleteFromFavoritesAction: () => {
      dispatch(deleteFromFavoritesAction());
    },
    checkQtyInCart: () => {
      dispatch(checkQtyInCart());
    }
  };
};

const SingleProductContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleProductParent);

export default SingleProductContainer;
