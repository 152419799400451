export default function priceRegex(number, type) {
  if (number && type) {
    if (type === 'withCurrency') {
      const num = number?.toLocaleString('fa-IR');
      const result = `${num} تومان`;
      return result;
    } else {
      const num = number?.toLocaleString('fa-IR');
      return num;
    }
  }
}
