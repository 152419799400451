import PopularProductsList from "./PopularProductsList";
import CategoryProductsList from "./CategoryProductsList";
import DiscountProductsList from "./DiscountProductsList";
import SpecialOffersList from "./SpecialOffersList";
import NewProductsList from "./NewProductsList";
import {
  makeStyles,
  Theme,
  Grid,
  ImageList,
  Button,
  Divider,
} from "@material-ui/core";
import TypoGraphy from "../../helpers/TypoGraphy";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userTypingAction } from "../../../redux/actions/reduxActions";
import RedirectPages from "../../helpers/RedirectPages";
import CategorySteps from "./CategorySteps";
import emptyProducts from "../../../static/image/emptyProducts.svg";
import CarouselModule from "./products/carousel/Carousel";
import ProductCard from "./products/ProductCard";
import SortIcon from "@material-ui/icons/Sort";
import { useMediaQuery } from "react-responsive";

const useStyles = makeStyles((theme) => ({
  ImageList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
    width: "100%",
    borderRadius: theme.spacing(1),
  },
  dialogContent: {
    backgroundColor: theme.palette.secondary.main,
    border: "1px solid #C37206",
  },
  title: {
    flex: 1,
  },
  typeGrid: {
    padding: theme.spacing(1, 0, 0, 0),
  },
  gridRoot: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    overflow: "hidden",
    width: "100%",
    padding: "0 1rem ",
    marginBottom: ".5rem",
  },
  icon: {
    width: 12,
  },
  backgroundDiv: {
    backgroundColor: "#680322",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  emptyCartImage: {
    width: 100,
    height: 100,
  },
  divider: {
    margin: theme.spacing(1),
    height: theme.spacing(1),
  },
}));

export default function HomeChild(props) {
  const classes = useStyles();
  const history = useHistory();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectValue, setRedirectValue] = useState("");
  const productsTypeName = props.products.type_name;
  const dispatch = useDispatch();
  const has_products = props.products.has_products;

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  // const homeItems = props.products.items.map((product, index) => {
  //   if (productsTypeName === "category_steps") {
  //     return (
  //       <CategorySteps
  //         key={index}
  //         product={product}
  //         has_products={has_products}
  //       />
  //     );
  //   } else if (productsTypeName === "category") {
  //     return (
  //       <CategoryProductsList
  //         key={index}
  //         product={product}
  //         isParent={props.products.base_category}
  //       />
  //     );
  //   } else if (productsTypeName === "poster") {
  //     // return <SpecialOffersList key={index} product={product} />;
  //   } else if (productsTypeName === "favorite") {
  //     return (
  //       <>
  //         <ProductCard
  //           key={index}
  //           produtType={productsTypeName}
  //           product={product}
  //         />
  //         {/* <Carousel show={2}>
  //           <ProductCard />
  //           <PopularProductsList key={index} product={product} />
  //         </Carousel> */}
  //       </>
  //     );
  //   } else if (productsTypeName === "discount") {
  //     // return <DiscountProductsList key={index} product={product} />;
  //   } else if (productsTypeName === "new") {
  //     // return <NewProductsList key={index} product={product} />;
  //   }
  // });
  const homeItems = props.products.items.map((product, index) => {
    if (productsTypeName === "category_steps") {
      return (
        <CategorySteps
          key={index}
          product={product}
          has_products={has_products}
        />
      );
    } else if (productsTypeName === "category") {
      return (
        <CategoryProductsList
          key={index}
          product={product}
          isParent={props.products.base_category}
        />
      );
    } else if (productsTypeName === "poster") {
      return <SpecialOffersList key={index} product={product} />;
    } else {
      return (
        <ProductCard
          key={index}
          produtType={productsTypeName}
          product={product}
        />
      );
    }
  });

  console.log("product", props);

  const viewAll = () => {
    if (props.products.show_category) {
      history.push(`/?category=${props.products?.type_name}`);
    } else {
      dispatch(
        userTypingAction(
          productsTypeName === "favorite"
            ? "-rate"
            : productsTypeName === "new"
            ? "-created_at"
            : productsTypeName === "discount"
            ? "discount"
            : null,
          "filterToSearch"
        )
      );
      history.push("/search");
    }
  };

  return (
    <div
      style={{
        borderBottom: "2px solid #E5E5E5",
        marginBottom: ".5rem",
      }}
    >
      {productsTypeName === "category_steps" ||
      productsTypeName === "category" ||
      productsTypeName === "poster" ? null : (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.typeGrid}
          style={{
            padding: productsTypeName !== "category" ? "5px 10px 5px 5px" : 0,
            backgroundColor: "#ffffff",
            color: "#888888",
            width: "100%",
            borderRadius: "0",
          }}
        >
          {productsTypeName === "category" ||
          productsTypeName === "poster" ? null : (
            <>
              <span style={{ display: "flex" }}>
                <SortIcon color="#888888" />
                <TypoGraphy
                  text={props.products.title}
                  style={{
                    color: "#888888",
                    fontWeight: "bold",
                    marginRight: "4px",
                  }}
                  variant={"subtitle2"}
                  gutterBottom={true}
                  align={"right"}
                />
              </span>

              {productsTypeName === "poster" ||
              productsTypeName === "category" ? null : (
                <Button
                  size="small"
                  variant="text"
                  style={{
                    color: "#888888",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    viewAll();
                  }}
                >
                  دیدن همه{" "}
                  <ArrowBackIosIcon style={{ width: 15, height: 15 }} />
                </Button>
              )}
            </>
          )}
        </Grid>
      )}

      <div className={classes.gridRoot}>
        {productsTypeName === "category_steps" ||
        productsTypeName === "category" ||
        productsTypeName === "poster" ? (
          <ImageList className={classes.ImageList}>{homeItems}</ImageList>
        ) : (
          <CarouselModule
            show={isTabletOrMobile ? 2 : 4}
            // BackgroundColor={
            //   productsTypeName === "favorite"
            //     ? "#EC1651"
            //     : productsTypeName === "new"
            //     ? "#EC1651"
            //     : productsTypeName === "discount"
            //     ? "#EC1651"
            //     : "#ffffff"
            // }
            BackgroundColor="#EC1651"
          >
            {homeItems}
          </CarouselModule>
        )}
        {/* <ImageList className={classes.ImageList}>{homeItems}</ImageList> */}
        {has_products === false ? (
          <div style={{ marginTop: 100 }}>
            <Grid container justifyContent="center" alignItems="center">
              <img
                className={classes.emptyCartImage}
                src={emptyProducts}
                alt="emptyProducts"
              />
            </Grid>
            <TypoGraphy
              text={"محصولی یافت نشد"}
              align={"center"}
              style={{ margin: "10px 10px 5px 0" }}
              variant={"h6"}
              color={"primary"}
            />
            <Divider className={classes.divider} />
          </div>
        ) : null}
      </div>

      {shouldRedirect === true && <RedirectPages redirect={redirectValue} />}
    </div>
  );
}
