import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './style/global.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/setup/store';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

if (
  !window.location.pathname.match('/order/') ||
  !window.location.pathname.match('/paid-order') ||
  !window.location.pathname.match('/failed-payment')
) {
  serviceWorkerRegistration.register();
}
reportWebVitals();
