import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, useMapEvents, useMap, Marker } from 'react-leaflet';
import marker from "../../../static/image/marker.svg"
import { mapCenterAction, markerLatLngAction, checkLocationZoneAction } from '../../../redux/actions/reduxActions';
import { useDispatch } from "react-redux"
import { useGeolocation } from 'react-use';
import { useEffect, useState } from 'react';
import { IconButton } from "@material-ui/core"
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import icon from './MapConstants';



export default function MapComponent(props) {
  let zoom = props.mapStates.zoom;
  const dispatch = useDispatch()
  const center = props.mapStates.center;
  const mapCenter = center;
  const [isMoving, setIsMoving] = useState(false);
  const [changeView, setChangeView] = useState(false);


  function GetMapCenterOnDrag() {
    const dispatch = useDispatch()
    const map = useMapEvents({
      dragend: (event) => {
        setIsMoving(false);
        dispatch(markerLatLngAction(event.target.getCenter().lat, event.target.getCenter().lng))
        dispatch(mapCenterAction([event.target.getCenter().lat, event.target.getCenter().lng]))
        dispatch(checkLocationZoneAction())
      },
      drag: (event) => {
        // mapCenterAction([
        //   event.target.getCenter().lat,
        //   event.target.getCenter().lng,
        // ]);
        // markerLatLngAction(
        //   event.target.getCenter().lat,
        //   event.target.getCenter().lng
        // );
        setChangeView(false);

        setIsMoving(true);
      },
    });
    return null;
  }

  function ChangeView({ center }) {
    const map = useMap();
    map.setView(center, map.getZoom());
    return null;
  }

  return (
    <div >
      <MapContainer
        center={mapCenter} zoom={zoom}>
        {changeView === true &&
          <ChangeView center={mapCenter} />
        }

        <TileLayer
          attribution='&copy; <a href="https://sepanodp.com/fa" target="_blank">Rose Gol</a> &copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a>'
          url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
        />
        <GetMapCenterOnDrag />
        <div
          id={isMoving === true ? 'markerBlink' : null}
          style={{
            zIndex: '30000',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '49px',
            height: '98px',
          }}
        >
          <img src={marker} alt='marker' />
        </div>

        <div
          style={{
            zIndex: "30000",
            position: "absolute",
            top: "80%",
            right: 0,
            transform: "translate(-50%, -50%)",
            border: "1px solid #FF0042",
            backgroundColor: "#ffffff",
            borderRadius: "50%",
            padding: 2
          }}
        >
          <IconButton
            onClick={() => {
              if (navigator.geolocation) {
                setChangeView(true);
                navigator.geolocation.getCurrentPosition(
                  (position) => {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
                    dispatch(markerLatLngAction(lat, lng))
                    dispatch(mapCenterAction([lat, lng]))
                    dispatch(checkLocationZoneAction())
                  })
              } else {
                alert("دسترسی به لوکیشن را فعال کنید")
              }
            }}
            color="primary" size="small">
            <GpsFixedIcon />
          </IconButton>
        </div>
      </MapContainer>

    </div>
  );
}
