import React, { useState, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  makeStyles,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Badge,
  IconButton,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  DialogContent,
  Chip,
  Link,
  Theme,
  Grow,
} from "@material-ui/core";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import emptyImage from "../../../static/image/emptyImage.svg";
import { useHistory } from "react-router-dom"
import { alertDialogContentAction, showAlertDialogAction } from "../../../redux/actions/reduxActions";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(1),
  },
  badge: {
    color: theme.palette.primary.light,
  },
  card: {
    overflow: "hidden",
    width: theme.spacing(35),
    borderRadius: theme.spacing(1),
    boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.2)",
    // border: '1px solid rgba(254,114,76,0.5)',
  },
  cardMedia: {
    width: "100%",
    objectFit: "cover",
    backgroundRepeat: "no-repeat",
    height: theme.spacing(18),
    borderRadius: theme.spacing(1),
  },
  realPrice: {
    fontSize: 15,
    textDecoration: "line-through",
    opacity: "0.7",
  },
  name: {
    fontSize: 15,
  },
  price: {
    fontSize: 15,
  },
  star: {
    width: 15,
    height: 15,
    color: theme.palette.primary.light,
  },
  icon: {
    width: 15,
    height: 15,
  },
  firstGrid: {
    margin: theme.spacing(1),
  },
  dialogContent: {
    backgroundColor: theme.palette.secondary.main,
    border: "1px solid #C37206",
  },
  title: {
    flex: 1,
  },
  container: {
    position: "relative",
  },
  discountChip: {
    position: "absolute",
    right: 15,
    top: 8,
  },
  catRpGrid: {
    padding: theme.spacing(0, 2, 1, 1),
  },
  nmPGrid: {
    padding: theme.spacing(1),
  },
}));

export default function SpecialOffersList(props) {
  const classes = useStyles();
  const item = props.product;
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const { authData } = auth;
  const dispatch = useDispatch();

  return (
    <Grow in={true} timeout={2000}>
      {
        (item.flags)?.includes("auth") ? (
          <div className={classes.root}
            onClick={() => {
              if (authData?.user?.role === 'customer') {
                window.location.replace(item.url)             
              } else {
                dispatch(alertDialogContentAction(
                  'دسترسی ندارید',
                  'برای دسترسی به این بخش باید ثبت نام کنید',
                  'برو به صفحه ثبت نام'
                ));
                dispatch(showAlertDialogAction(true));
              }
            }}
          >
            <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                  className={classes.cardMedia}
                  src={item.image}
                  component="img"
                  onError={(event) => {
                    event.target.src = emptyImage;
                  }}
                />
              </CardActionArea>
            </Card>
          </div>
        ) : (
          <Link href={item.url} underline="none">
            <div className={classes.root}>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardMedia
                    className={classes.cardMedia}
                    src={item.image}
                    component="img"
                    onError={(event) => {
                      event.target.src = emptyImage;
                    }}
                  />
                </CardActionArea>
              </Card>
            </div>
          </Link>
        )
      }

    </Grow>
  );
}
