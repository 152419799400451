import React from 'react';
import { Redirect } from 'react-router-dom';

export default function RedirectPages(props) {
  return (
    <Redirect
      to={{ pathname: props.redirect, state: { from: props.location } }}
    />
  );
}
